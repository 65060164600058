.section-nav {
    border-top: 2px solid #FFF;
    position: relative;
    overflow: visible;
    height: 0px;
    width: 100%;
    display: block;
    background-color: #f18523;
    padding-top: 0px;
    padding-bottom: 0px;
    z-index: 600;
    ul.nav {
        display: block;
        position: relative;
        &:after {
            content: '';
            display: block;
            clear: both;
        }
        li {
            display: inline-block;
            float: left;
            @include bp-xs {
                width: 100%;
                margin-right: 0%;
            }
            @include bp-sm {
                width: 100%;
                margin-right: 0%;
            }
            @include bp-md {
                width: 20%;
                margin-right: 0%;
            }
            @include bp-lg {
                width: 20%;
                margin-right: 0%;
            }
            a {
                display: block;
                position: relative;
                text-align: center;
                font-size: 18px;
                font-weight: 200;
                color: #004b8d;
                text-transform: uppercase;
                padding: 20px 15px 20px 15px;
                @include bp-xs {
                    font-size: 18px;
                }
                @include bp-sm {
                    font-size: 18px;
                }
                @include bp-md {
                    font-size: 18px;
                }
                @include bp-lg {
                    font-size: 18px;
                }
            }
        }
    }
    #s-logo {
        position: absolute;
        z-index: 601;
        right: 0px!important;
        margin-right: 0!important;
        top: -35px;
        display: block;
        width: 230px;
        height: auto;
        background-color: #013678;
        a {
            display: block;
        }
        @include bp-xs {
            width: 150px;
            top: -20px;
        }
        @include bp-sm {
            width: 200px;
            top: -30px;
        }
        @include bp-md {
            width: 230px;
            top: -35px;
        }
        @include bp-lg {
            width: 230px;
        }
    }
}

#app.stickynav #s-nav {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f18523;
    padding-top: 0px;
    padding-bottom: 0px;
    @include bp-xs {
        position: relative;
    }
    @include bp-sm {
        position: relative;
    }
    @include bp-md {
        position: fixed;
    }
    @include bp-lg {
        position: fixed;
    }
}