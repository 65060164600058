/*********************************************
	Contact Styles
*********************************************/

.contact_form {
    display: block;
    margin-top: 10px;
    margin-bottom: 0px;
}

.contact_form p {}

.contact_form p span {}

.contact_form p em {
    font-style: italic;
    text-transform: none;
    font-weight: normal;
}

.contact_form label {
    color: #000;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    width: 100%;
    max-width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 15px;
    margin-bottom: 0px;
}

.contact_form input,
.contact_form textarea {
    font-size: 16px;
    width: 100%;
    max-width: 100%;
}

.contact_form input[type="submit"] {
    font-size: 30px;
    width: auto;
}

.contact_info {
    display: block;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.contact_info strong {
    text-transform: uppercase;
}

.contact_info a {
    color: #971b1e;
    font-weight: 600;
}