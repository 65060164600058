/** fold Slider Slide Styles**/

.owl-carousel.owl-fold {
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
    @include bp-xs {
        height: 100%;
        max-height: 3000px;
    }
    @include bp-sm {
        height: 100%;
        max-height: 3000px;
    }
    @include bp-md {
        height: 100%;
        max-height: 3000px;
    }
    @include bp-lg {
        height: 100%;
        max-height: 3000px;
    }
    @include bp-xl {
        height: 100%;
        max-height: 3000px;
    }
    @include bp-xxl {
        height: 100%;
        max-height: 3000px;
    }
    .owl-stage-outer {
        display: block;
        height: 100%;
        min-height: 100%;
        @include bp-xs {
            height: 100%;
        }
        @include bp-sm {
            height: 100%;
        }
        @include bp-md {
            height: 100%;
        }
        @include bp-lg {
            height: 100%;
        }
    }
    .owl-stage {
        height: 100%;
    }
    .owl-item {
        height: 100%;
    }
    .owl-item .owl-slide {
        position: relative;
        min-height: 100%;
        height: 100%;
        overflow: hidden;
    }
    .owl-item img {
        display: block;
        max-width: 1000%;
        min-width: 100%;
        min-height: 100%;
        height: auto;
        width: auto;
        top: 0%;
        z-index: 300;
        //object-fit: scale-down;
        @include bp-xs {
            height: 100%;
            width: auto;
            position: relative;
            left: 50%;
            transform: translate(-50%, -0%);
        }
        @include bp-sm {
            height: 100%;
            width: auto;
            position: relative;
            left: 50%;
            transform: translate(-50%, -0%);
        }
        @include bp-md {
            height: 100%;
            width: auto;
            position: relative;
            left: 50%;
            transform: translate(-50%, -0%);
        }
        @include bp-lg {
            height: auto;
            width: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        @include bp-xl {
            height: auto;
            width: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        @include bp-xxl {}
        @include bp-xl-max-land {
            height: auto;
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .owl-item .owl-slide-video {
        img {
            @include bp-xs {
                display: block;
            }
            @include bp-sm {
                display: block;
            }
            @include bp-md {
                display: none;
            }
            @include bp-lg {
                display: none;
            }
        }
        .bg-video-wrap {
            display: block;
            position: relative;
            z-index: 350;
            video {
                @include bp-xs {
                    display: none;
                }
                @include bp-sm {
                    display: none;
                }
                @include bp-md {
                    display: block;
                }
                @include bp-lg {
                    display: block;
                }
            }
            @include bp-xs {
                display: none;
            }
            @include bp-sm {
                display: none;
            }
            @include bp-md {
                display: block;
            }
            @include bp-lg {
                display: block;
            }
        }
    }
    @media only screen and (min-width: 769px) {
        .owl-stage {
            height: 100%;
        }
        .owl-item {
            height: 100%;
        }
        .seq-slide {
            height: 100%;
        }
    }
    @media only screen and (min-width: 1024px) {
        .owl-stage {
            height: 100%;
        }
        .owl-item {
            height: 100%;
        }
        .seq-slide {
            height: 100%;
        }
    }
    @media only screen and (min-width: 1232px) {
        .owl-item img {}
        .owl-stage-outer {}
        .owl-stage {
            height: 100%;
        }
        .owl-item {
            height: 100%;
        }
        .seq-slide {
            height: 100%;
        }
    }
    .owl-nav {}
    .owl-nav [class*=owl-] {}
    .owl-nav [class*=owl-]:hover {}
    .owl-dots {}
    .owl-dots .owl-dot {}
    .owl-dots .owl-dot span {}
    .owl-dots .owl-dot.active span {}
    .owl-nav .owl-prev,
    .owl-nav .owl-next {}
    .owl-nav .owl-prev {}
    .owl-nav .owl-next {}
    &:hover .owl-nav .owl-prev {}
    &:hover .owl-nav .owl-next {}
    .owl-prev:after {}
    .owl-next:after {}
    //Set Text Animations
    .owl-title {
        opacity: 0;
        @include transition(opacity 1000ms ease-in-out 0s);
    }
    .owl-item.active .owl-title {
        opacity: 1;
    }
    .owl-text {
        opacity: 0;
        @include transition(opacity 1000ms ease-in-out 0s);
    }
    .owl-item.active .owl-text {
        opacity: 1;
    }
    .owl-link {
        opacity: 0;
        @include transition(opacity 1000ms ease-in-out 0s);
    }
    .owl-item.active .owl-link {
        opacity: 1;
    }
    //Default Nav
    .owl-nav {
        position: absolute;
        background-color: transparent;
        background-image: none;
        top: 50%;
        left: 0px;
        right: 0px;
    }
    .owl-nav [class*=owl-] {
        position: absolute;
        background-color: transparent;
        background-image: none;
        padding-top: 0px;
        padding-right: 3px;
        padding-bottom: 0px;
        padding-left: 3px;
    }
    .owl-nav [class*=owl-]:hover {}
    .owl-dots {
        position: absolute;
        bottom: 0px;
        margin-left: auto;
        left: 0%;
        margin-right: auto;
        right: 0px;
        display: block;
        height: 20px;
        width: auto;
        line-height: normal;
        text-align: center;
        @include bp-xs {
            position: absolute;
            bottom: 40px;
        }
        @include bp-sm {
            position: absolute;
            bottom: 40px;
        }
        @include bp-md {
            position: absolute;
            bottom: 40px;
        }
        @include bp-lg {
            position: absolute;
            bottom: 40px;
        }
    }
    .owl-dots button.owl-dot {
        background-color: transparent;
        border: 0px solid transparent;
        display: inline-block;
        margin: 0px;
        padding: 0px;
        height: auto;
        width: auto;
        height: 20px;
        margin-right: 15px;
    }
    .owl-dots .owl-dot:first-child {}
    .owl-dots .owl-dot:last-child {
        margin-right: 0px;
    }
    .owl-dots .owl-dot span {
        display: block;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-color: #FFFFFF;
        background-color: rgba(255, 255, 255, 0.5);
        height: 10px;
        width: 10px;
        border: 0px solid #7f7f7f;
    }
    .owl-dots .owl-dot.active span {
        background-color: #2f94bf;
    }
    .owl-nav button.owl-prev,
    .owl-nav button.owl-next {
        height: 4em;
        width: 4em;
        opacity: 0;
        transition: all 200ms ease-in-out 0s;
        border: 3px solid #FFF;
        border-radius: 50%;
        margin: 0;
        @include bp-xs {
            height: 1.5em;
            width: 1.5em;
            border: 2px solid #FFF;
        }
        @include bp-sm {
            height: 1.5em;
            width: 1.5em;
            border: 2px solid #FFF;
        }
        @include bp-md {
            height: 2em;
            width: 2em;
            border: 3px solid #FFF;
        }
        @include bp-lg {
            height: 2.5em;
            width: 2.5em;
        }
        &:hover {
            background-color: rgba(255, 255, 255, 0.4);
        }
    }
    .owl-nav button.owl-prev {
        left: 3em;
        @include bp-xs {
            left: 1.5em;
        }
        @include bp-sm {
            left: 2em;
        }
        @include bp-md {
            left: 2.5em;
        }
        @include bp-lg {
            left: 3em;
        }
    }
    .owl-nav button.owl-next {
        right: 3em;
        @include bp-xs {
            right: 1.5em;
        }
        @include bp-sm {
            right: 2em;
        }
        @include bp-md {
            right: 2.5em;
        }
        @include bp-lg {
            right: 3em;
        }
    }
    &:hover .owl-nav button.owl-prev {
        opacity: 1;
    }
    &:hover .owl-nav button.owl-next {
        opacity: 1;
    }
    button.owl-prev:after,
    button.owl-next:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    button.owl-prev:after {
        content: "";
        background-image: url(#{$assets_path}img/svg/nav-left-arrow.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        display: inline-block;
        height: 100%;
        width: 100%;
        margin-left: -5%;
    }
    button.owl-next:after {
        content: "";
        background-image: url(#{$assets_path}img/svg/nav-right-arrow.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        display: inline-block;
        height: 100%;
        width: 100%;
        margin-left: 5%;
    }
    .owl-title {
        font-family: $FrutigerLT-cond;
        font-size: 110px;
        font-weight: 600;
        padding-bottom: 10px;
        text-transform: none;
        line-height: 1em;
        text-transform: uppercase;
        color: #0069a6;
        @include bp-xs {
            font-size: 72px;
        }
        @include bp-sm {
            font-size: 72px;
        }
        @include bp-md {
            font-size: 110px;
        }
        @include bp-lg {
            font-size: 110px;
        }
    }
    .owl-title-alt {
        color: #fff;
        //text-shadow: 0 0 18px rgba(0, 0, 0, 0.16);
        span {}
        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            left: -10px;
            top: -10px;
            right: -10px;
            bottom: -10px;
            z-index: -1;
            opacity: 0.4;
            background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.01) 70%, rgba(0, 0, 0, 0) 71%);
            background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.01) 70%, rgba(0, 0, 0, 0) 71%);
            background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.01) 70%, rgba(0, 0, 0, 0) 71%);
        }
    }
    .owl-subtitle {
        font-family: $FrutigerLT;
        font-size: 26px;
        font-weight: 600;
        padding-bottom: 10px;
        text-transform: none;
        line-height: 1em;
        text-transform: uppercase;
        color: #fff;
        @include bp-xs {
            font-size: 18px;
        }
        @include bp-sm {
            font-size: 18px;
        }
        @include bp-md {
            font-size: 26px;
        }
        @include bp-lg {
            font-size: 26px;
        }
    }
    .owl-subtitle-alt {
        color: #0069a6;
        text-shadow: 0 0 18px rgba(0, 0, 0, 0.16);
    }
    .owl-text {
        font-family: $FrutigerLT;
        position: absolute;
        top: -50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: center;
        left: 0px;
        right: 0px;
        display: inline-block;
        z-index: 200;
        transition: all 500ms ease-in-out 0s;
    }
    .owl-item.active .owl-text {
        top: 50%;
    }
    a.owl-link {
        font-size: 20px;
        padding: 13px 40px 9px 40px;
        @include bp-xs {
            font-size: 16px;
        }
        @include bp-sm {
            font-size: 18px;
        }
        @include bp-md {
            font-size: 20px;
        }
        @include bp-lg {
            font-size: 20px;
        }
    }
    //Video supports
    .owl-item-video,
    .owl-slide-video {
        display: block;
        @include bp-xs {
            display: block;
        }
        @include bp-sm {
            display: block;
        }
        @include bp-md {
            display: block;
        }
        @include bp-lg {
            display: block;
        }
        @include bp-xl {}
        @include bp-xxl {}
    }
    /*INLINE NAV*/
    .owl-inline-nav .owl-nav {
        position: absolute;
        background-color: transparent;
        background-image: none;
        top: auto;
        bottom: 10px;
        left: auto;
        right: auto;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    .owl-inline-nav .owl-prev:after {
        content: "";
        font-size: 12px;
        background-image: url(#{$assets_path}img/svg/arrow-prev.svg);
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        height: 20px;
        width: 10px;
    }
    .owl-inline-nav .owl-next:after {
        content: "";
        font-size: 12px;
        background-image: url(#{$assets_path}img/svg/arrow-next.svg);
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        height: 20px;
        width: 10px;
    }
    .owl-inline-nav .owl-nav [class*=owl-] {
        position: absolute;
        background-color: transparent;
        background-image: none;
        padding: 0px;
        margin-top: -20px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        @include bp-xs {
            margin-top: -20px;
        }
        @include bp-sm {
            margin-top: -20px;
        }
        @include bp-md {
            margin-top: -20px;
        }
        @include bp-lg {
            margin-top: -20px;
        }
    }
    .owl-inline-nav .owl-nav .owl-prev {
        left: -15px;
    }
    .owl-inline-nav .owl-nav .owl-next {
        right: -15px;
    }
    .owl-inline-nav:hover .owl-nav .owl-prev {
        left: -15px;
    }
    .owl-inline-nav:hover .owl-nav .owl-next {
        right: -15px;
    }
}