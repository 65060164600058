/* ScrollTop Animation */

#scrolltop {
    @include background-color-rgba( $primary-accent-color, 0.5);
    display: block;
    position: fixed;
    z-index: 99999;
    right: 30px;
    bottom: 30px;
    height: 40px;
    width: 40px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    cursor: pointer;
    transition: all 500ms ease-in-out 0s;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        width: 15px;
        height: 15px;
        border-right: 2px solid #FFF;
        border-top: 2px solid #FFF;
    }
}

#scrolltop:hover {
    @include background-color-rgba( $primary-accent-color, 0.9);
}


/** CSS3 Arrows **/

.scrollto i {
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 15px;
}

.scrollto .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.scrollto .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.scrollto .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.scrollto .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}


/** SCROLL TO ANIMATION BUTTON **/

.scrollto {
    display: block;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    margin-top: 0px;
    margin-bottom: 0px;
    top: 0px;
    cursor: pointer;
    z-index: 99999;
    -webkit-transition: all 500ms ease-in-out 0s;
    -moz-transition: all 500ms ease-in-out 0s;
    -ms-transition: all 500ms ease-in-out 0s;
    -o-transition: all 500ms ease-in-out 0s;
    transition: all 500ms ease-in-out 0s;
    left: 0px;
    right: 0px;
    width: 0;
    height: 0;
}

.scrollto:hover {}

.scrollto i {
    position: absolute;
    bottom: 0px;
}

.scrollto-circle {
    span {
        height: 3.5em;
        width: 3.5em;
        background-color: #7f7f7f;
        border: 0px solid #FFF;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: -2em;
        transform: translate(-50%, 0%);
        bottom: auto;
        padding: 0px;
        margin-left: 0;
        display: block;
        &:hover {
            background-color: #004c8b;
        }
        &:after {
            content: '';
            background-image: url(#{$assets_path}img/svg/nav-down-arrow.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% 100%;
            display: inline-block;
            height: 100%;
            width: 100%;
        }
    }
}

.scrollto-slider {
    bottom: 50px;
    @include bp-xs {
        bottom: 45px;
    }
    @include bp-sm {
        bottom: 45px;
    }
    @include bp-md {
        bottom: 60px;
    }
}