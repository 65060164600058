/*********************************************
	Header
*********************************************/

.header-container {}

#header-wrap {
    display: block;
    position: absolute;
    margin: 0px;
    padding: 0px;
    height: auto;
    width: 100%;
    z-index: 600;
    top: 0px;
    border-top-color: #43525a;
    border-top-width: 6px;
    border-top-style: solid;
}

#header-wrap:after {
    content: '';
    display: none;
    clear: both;
    z-index: -1;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    height: 150px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    opacity: 0.2;
}

#header-wrap.sticky-ghost {
    min-height: 90px;
}

.navbar-wrap {
    display: inline-block;
    min-height: 0px;
    position: relative;
    margin: 0px;
    padding: 0px;
    height: auto;
    width: auto;
    z-index: 599;
    float: right;
    @include bp-sm {
        width: auto;
        z-index: 599;
    }
    @include bp-md {
        width: auto;
        z-index: 598;
    }
    @include bp-lg {
        width: auto;
        z-index: 598;
    }
}

#header-wrap.sticky .navbar-wrap {
    z-index: 598;
}

#app.show #header-wrap .navbar-wrap {
    z-index: 599;
}

#app.show #header-wrap.sticky .navbar-wrap {
    @include bp-sm {
        z-index: 599;
    }
    @include bp-md {
        z-index: 598;
    }
    @include bp-lg {
        z-index: 598;
    }
}

.header-logo-wrap {
    display: inline-block;
    min-height: 0px;
    position: relative;
    margin: 0px;
    padding: 0px;
    height: auto;
    width: auto;
    z-index: 598;
    float: left;
    @include bp-sm {
        width: auto;
        z-index: 598;
    }
    @include bp-md {
        width: auto;
        z-index: 599;
    }
    @include bp-lg {
        width: auto;
        z-index: 599;
    }
}

#header-wrap.sticky .header-logo-wrap {
    z-index: 599;
}

#app.show #header-wrap .header-logo-wrap {
    z-index: 598;
}

#app.show #header-wrap.sticky .header-logo-wrap {
    @include bp-sm {
        z-index: 598;
    }
    @include bp-md {
        z-index: 599;
    }
    @include bp-lg {
        z-index: 599;
    }
}

#logo {
    display: block;
    position: relative;
    background-repeat: no-repeat;
    z-index: 400;
    background-position: center center;
    float: left;
    display: inline-block;
    margin-right: auto;
    background-color: #dbdbdb;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-transition: all 1000ms ease 0s;
    -moz-transition: all 1000ms ease 0s;
    -ms-transition: all 1000ms ease 0s;
    -o-transition: all 1000ms ease 0s;
    transition: all 1000ms ease 0s;
    @include bp-sm {
        float: none;
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-left: 0px;
        margin-right: auto;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    @include bp-md {
        float: none;
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    @include bp-lg {
        float: left;
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-left: 0px;
        margin-right: auto;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

#logo:hover {
    background-color: #dbdbdb;
}

#logo svg,
#gatewaypdx {
    max-height: 60px;
}

#header-wrap.sticky-ghost #logo {
    padding-top: 0px;
    padding-bottom: 0px;
    max-height: 49px;
    @include bp-lg {
        top: -100px;
    }
}

#header-wrap.sticky-ghost #logo svg,
#header-wrap.sticky-ghost #gatewaypdx {
    max-height: 49px;
}

#logoarrow {
    display: block;
    width: 125px;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: -125px;
    text-align: left;
}

#header-wrap.sticky #logoarrow {
    display: none;
}

#header-wrap.sticky #logo {
    float: left;
    display: inline-block;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
}

#logo:after {
    content: '';
    display: none;
    position: absolute;
    right: -20px;
    top: 0px;
    bottom: 0px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 0 30px 20px;
    border-color: transparent transparent transparent #dbdbdb;
}

#header-wrap.sticky #logo:after {
    display: none;
}

#logo img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    @include bp-sm {
        margin-top: 5px;
        max-height: 25px;
    }
    @include bp-md {
        margin-top: 0px;
        max-height: 35px;
    }
    @include bp-lg {
        max-height: 40px;
    }
}

@include bp-lg {
    #header-wrap.sticky-ghost #logo img {}
    #header-wrap.sticky #logo img {
        max-height: 30px;
    }
}

.header-wrap-right {
    text-align: right;
    display: inline-block;
    float: right;
    width: auto;
    margin-right: 200px;
    @include bp-sm {
        text-align: left;
        float: right;
        margin-right: 0px;
    }
    @include bp-md {
        text-align: left;
        float: right;
        margin-right: 0px;
    }
    @include bp-lg {
        text-align: right;
        float: right;
        margin-right: 175px;
    }
}

.header-wrap-right .tel {
    font-size: 1em;
    display: inline-block;
    float: right;
    margin-top: 10px;
    padding-top: 3px;
    padding-right: 10px;
    padding-left: 10px;
    font-weight: bold;
}