/*********************************************
	OWL Carousel Core
*********************************************/

//Base Theme

/**
 * Owl Carousel v2.3.0
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */


/*
 *  Owl Carousel - Core
 */

.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
    display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
}

.owl-carousel.owl-loaded {
    display: block;
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block;
}

.owl-carousel.owl-hidden {
    opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
    visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
    -ms-touch-action: none;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab;
}

.owl-carousel.owl-rtl {
    direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
    float: right;
}


/* No Js */

.no-js .owl-carousel {
    display: block;
}


/*
   *  Owl Carousel - Animate Plugin
   */

.owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
    z-index: 0;
}

.owl-carousel .owl-animated-out {
    z-index: 1;
}

.owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


/*
   * 	Owl Carousel - Auto Height Plugin
   */

.owl-height {
    transition: height 500ms ease-in-out;
}


/*
   * 	Owl Carousel - Lazy Load Plugin
   */

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}


/*
   * 	Owl Carousel - Video Plugin
   */

.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
}

.owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url("owl.video.play.png") no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: -webkit-transform 100ms ease;
    transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
    -webkit-transform: scale(1.3, 1.3);
    -ms-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none;
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
}


/** Base Styles **/

.owl-sub {
    display: block;
    height: auto;
    width: 100%;
    z-index: 500;
    -webkit-transition: all 500ms ease-in-out 300ms;
    -moz-transition: all 500ms ease-in-out 300ms;
    -ms-transition: all 500ms ease-in-out 300ms;
    -o-transition: all 500ms ease-in-out 300ms;
    transition: all 500ms ease-in-out 300ms;
    text-align: center;
    position: absolute;
    @include translatecenter(50%,
    50%);
    margin-right: auto;
    margin-left: auto;
    max-width: 1080px;
    padding-left: 7em;
    padding-right: 7em;
}

.owl-sub.left {
    text-align: left;
}

.owl-sub.center {
    text-align: center;
}

.owl-sub.right {
    text-align: right;
}

.owl-carousel .active .owl-sub {
    -webkit-transition: all 500ms ease-in-out 300ms;
    -moz-transition: all 500ms ease-in-out 300ms;
    -ms-transition: all 500ms ease-in-out 300ms;
    -o-transition: all 500ms ease-in-out 300ms;
    transition: all 500ms ease-in-out 300ms;
}

.owl-carousel .fadeout .owl-sub {
    -webkit-transition: all 500ms ease-in-out 300ms;
    -moz-transition: all 500ms ease-in-out 300ms;
    -ms-transition: all 500ms ease-in-out 300ms;
    -o-transition: all 500ms ease-in-out 300ms;
    transition: all 500ms ease-in-out 300ms;
}

.owl-carousel .owl-title {
    font-family: $FrutigerLT;
    font-size: 28px;
    font-weight: 200;
    color: #FFF;
    //text-shadow: 1px 1px 3px rgba(48, 74, 95, 0.5);
    padding-bottom: 5px;
    text-transform: none;
    line-height: 1em;
    text-transform: uppercase;
    @include bp-xs {
        font-size: 40px;
        line-height: 50px;
        padding-bottom: 10px;
    }
    @include bp-sm {
        font-size: 65px;
        line-height: 65px;
        padding-bottom: 15px;
    }
    @include bp-md {
        font-size: 85px;
        line-height: 85px;
        padding-bottom: 20px;
    }
    @include bp-lg {
        font-size: 85px;
        line-height: 85px;
        padding-bottom: 25px;
    }
}

.owl-carousel .owl-title-alt {
    font-family: $FrutigerLT;
    font-size: 28px;
    font-weight: 200;
    color: #FFF;
    //text-shadow: 1px 1px 3px rgba(48, 74, 95, 0.5);
    padding-bottom: 5px;
    text-transform: none;
    line-height: 1em;
    text-transform: uppercase;
    @include bp-xs {
        font-size: 30px;
        line-height: 1.5em;
        padding-bottom: 5px;
    }
    @include bp-sm {
        font-size: 40px;
        line-height: 1.5em;
        padding-bottom: 10px;
    }
    @include bp-md {
        font-size: 50px;
        line-height: 1.5em;
        padding-bottom: 15px;
    }
    @include bp-lg {
        font-size: 60px;
        line-height: 1.5em;
        padding-bottom: 15px;
    }
}

.owl-carousel .owl-subtitle {
    font-family: $FrutigerLT;
    font-size: 16px;
    font-weight: 200;
    color: #FFF;
    //text-shadow: 1px 1px 3px rgba(48, 74, 95, 0.5);
    padding-bottom: 5px;
    text-transform: none;
    line-height: 1em;
    text-transform: uppercase;
    @include bp-xs {
        font-size: 16px;
        padding-bottom: 5px;
    }
    @include bp-sm {
        font-size: 18px;
        padding-bottom: 10px;
    }
    @include bp-md {
        font-size: 20px;
        padding-bottom: 15px;
    }
    @include bp-lg {
        font-size: 20px;
        padding-bottom: 20px;
    }
}

.owl-carousel .owl-text {
    font-family: $FrutigerLT;
    color: #FFF;
    //text-shadow: 1px 1px 3px rgba(48, 74, 95, 0.5);
    font-size: 26px;
    line-height: 1.75em;
    font-weight: 300;
    padding-top: 10px;
    padding-bottom: 10px;
    @include bp-xs {
        font-size: 18px;
    }
    @include bp-sm {
        font-size: 22px;
    }
    @include bp-md {
        font-size: 24px;
    }
    @include bp-lg {
        font-size: 26px;
    }
}

.owl-carousel .owl-text p {
    font-size: 16px;
    font-weight: 300;
}

.owl-carousel a.owl-link {
    font-family: $FrutigerLT;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    border: 1px solid #FFF;
    background-color: #FFF;
    padding: 20px 40px 17px 40px;
    color: #004c8b;
    &:hover {
        color: #004c8b;
        background-color: rgba(255, 255, 255, 0.4);
    }
}

//Video supports
.owl-item-video,
.owl-slide-video {
    display: block;
    @include bp-xs {
        display: none;
    }
    @include bp-sm {
        display: none;
    }
    @include bp-md {
        display: none;
    }
    @include bp-lg {
        display: block;
    }
    @include bp-xl {}
    @include bp-xxl {}
}

.owl-slide-video .bg-video-wrap {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: -1;
}

.owl-slide-video .bg-video-wrap:before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
}

.owl-slide-video .bg-video-wrap .video-js {
    min-width: 100%;
    position: absolute;
    background-size: cover;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    min-width: 100%!important;
    min-height: 100%!important;
    max-width: 1000%!important;
    margin: 0px!important;
    padding: 0px!important;
}

.owl-slide-video .bg-video-wrap video {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
    background-color: transparent;
    min-width: 100%!important;
    min-height: 100%!important;
    max-width: 1000%!important;
}

@media (min-aspect-ratio: 16/9) {
    .owl-slide-video .bg-video-wrap video {
        height: 300%;
        top: -100%;
    }
}

@media (max-aspect-ratio: 16/9) {
    .owl-slide-video .bg-video-wrap video {
        width: 300%;
        left: -100%;
    }
}

.owl-simple .owl-slide-video .bg-video-wrap video {
    height: auto;
    width: 100%;
    left: 0%;
}

.owl-video-frame iframe {}

.owl-video-frame {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
}

.owl-video-frame iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}