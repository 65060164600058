/** Button Styling **/

.btn,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    font-family: $FrutigerLT;
    text-transform: uppercase;
    display: inline-block;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: #FFF;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    font-weight: 600;
    letter-spacing: 0.15em;
    background-color: $orange;
    margin-right: auto;
    margin-left: auto;
    padding-top: 9px;
    padding-right: 25px;
    padding-bottom: 6px;
    padding-left: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -moz-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    border: 1px solid $orange;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    width: auto;
    max-width: 100%;
    @include bp-sm {
        display: inline-block;
        margin-bottom: 15px;
    }
    @include bp-md {
        display: inline-block;
        margin-bottom: 0px;
    }
    @include bp-lg {
        display: inline-block;
    }
}

a:not([href]):not([tabindex]) {
    color: #FFF;
}

.btn-alt {
    color: #FFF;
    border: 2px solid #FFF;
}

a.btn,
.btn {
    color: #FFF;
}

a.btn:hover,
.btn:hover {
    background-color: transparent;
    color: $orange!important;
}

a.btn-alt,
.btn-alt {
    color: #FFF;
}

a.btn-alt:hover,
.btn-alt:hover {
    color: #FFF!important;
}

.btn:hover,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
    color: $primary-accent-color;
    //background-color: #971b1e;
}

.btn:active,
.btn.active {
    //background-color: #971b1e;
}

.btn:first-child {
    *margin-left: 0;
}

.btn:hover,
button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
    color: $primary-accent-color;
    text-decoration: none;
    //background-color: #971b1e;
    //*background-color: #971b1e;
    /* Buttons in IE7 don't get borders, so darken on hover */
    -webkit-transition: background-position 0.1s linear;
    -moz-transition: background-position 0.1s linear;
    -o-transition: background-position 0.1s linear;
    transition: background-position 0.1s linear;
}

.btn:focus,
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus {
    outline: 0px dotted $primary-accent-color;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
}

.btn.active,
.btn:active {
    //background-color: #222428;
    background-image: none;
    outline: 0;
    //-webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    //-moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    //box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.btn.disabled,
.btn[disabled] {
    cursor: default;
    //background-color: #222428;
    background-image: none;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

a.btn,
a.btn:active,
a.btn:hover,
a.btn:focus,
a.btn:-webkit-any-link,
.btn,
.btn:active,
.btn:hover,
.btn:focus,
.btn:-webkit-any-link {
    outline: none;
    outline: 0!important;
    outline: none!important;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-shadow: 0 0 0 transparent;
}

.btn-wrap {
    white-space: pre-wrap;
}

.btn-wrap {
    margin: 0 0 0 0;
    display: inline-block;
    img {
        cursor: pointer;
        display: inline-block;
        width: 33px;
        margin: 0 0 0 16px;
    }
}