/*********************************************
	Map Styles
*********************************************/

.map-wrap {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    @include bp-sm {
        position: relative;
        height: auto;
    }
    @include bp-md {
        position: relative;
        height: auto;
    }
    @include bp-lg {
        position: absolute;
        height: 100%;
    }
    @include bp-xl {}
    @include bp-xxl {}
    @include bp-xxxl {}
}

.map-overlay {
    background-color: #666666;
    background-color: rgba(48, 74, 98, 0.9);
    display: block;
    height: 100%;
    width: 250px;
    padding-top: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    position: absolute;
    z-index: 500;
    left: 0px;
    top: 0px;
    bottom: 0px;
    @include bp-sm {
        display: none;
    }
    @include bp-md {
        display: none;
    }
    @include bp-lg {
        display: none;
    }
    @include bp-xl {
        display: block;
    }
    @include bp-xxl {}
    @include bp-xxxl {}
}

.map-overlay .inner {
    display: block;
}

.map-overlay .inner a {
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

.map-overlay .inner a:hover {
    color: #971b1e;
}

.map_title {
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: 700;
    margin-bottom: 5px;
}

#map,
.gmap,
.acf-map {
    height: 200px;
    width: 100%;
    color: #000;
    text-align: center;
    float: none;
    position: relative;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 0px solid #3e3d37;
    background-color: #CCC;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

#map.single,
.gmap.single,
.acf-map.single {
    min-height: 200px;
    height: 200px;
}

.map-full-wrapper {
    min-height: 200px;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    @include bp-sm {
        position: relative;
        height: auto;
    }
    @include bp-md {}
    @include bp-lg {}
    @include bp-xl {
        position: absolute;
        height: 100%;
    }
    @include bp-xxl {}
    @include bp-xxxl {}
}

.map-full-wrapper:after {
    content: '';
    clear: both;
    display: block;
}

.map-full-wrapper iframe,
.map-full-wrapper object,
.map-full-wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.map-full-wrapper #map.single,
.map-full-wrapper .acf-map.single {
    position: absolute;
    min-height: 500px;
    height: 100%;
    width: 100%;
    @include bp-sm {
        position: relative;
        height: auto;
    }
    @include bp-md {}
    @include bp-lg {}
    @include bp-xl {
        position: absolute;
        height: 100%;
    }
    @include bp-xxl {}
    @include bp-xxxl {}
}

#map iframe,
#map object,
#map embed,
.gmap iframe,
.gmap object,
.gmap embed {
    position: relative;
    width: 100%;
    height: 100%;
}

#map_info {
    display: block;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0px;
    position: relative;
    z-index: 500;
}

//GOOGLE MAPS
.gm-style-iw div {
    display: block;
}

.gm-style-iw {
    display: block;
    text-align: left;
    min-width: 100px;
}

.gm-style-iw h4,
.gm-style-iw p {
    margin: 0;
    padding: 0;
    width: auto;
}

.gm-style-iw p {
    font-size: 14px;
    display: block;
    padding-top: 1px;
    padding-bottom: 2px;
}

.gm-style-iw h1,
.gm-style-iw h2,
.gm-style-iw h3,
.gm-style-iw h4 {
    color: #971b1e;
    font-weight: bold;
    margin-bottom: 5px;
}

.gm-style-iw h1 {
    font-size: 22px;
}

.gm-style-iw h2 {
    font-size: 20px;
}

.gm-style-iw h3 {
    font-size: 18px;
}

.gm-style-iw h4 {
    font-size: 16px;
}

.gm-style-iw a {
    color: #4272db;
}

.gm-style-iw .open {
    color: #00CC00;
}