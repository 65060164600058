/*
Listbox Format

<ul class="listbox listbox_2">
    <li><a href="" title="">Title</a></li>
</ul>

*/

.listbox {
    list-style-position: inside;
    list-style-image: none;
    list-style-type: none;
    display: block;
    clear: both;
    height: auto;
    width: 100%;
    margin: 0px;
    padding-top: 15px;
    padding-right: 0px;
    padding-bottom: 15px;
    padding-left: 0px;
    margin-top: 90px;
    margin-bottom: 90px;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    z-index:100;
}

.listbox li {
    font-family: 'Oswald', sans-serif;
    text-align: center;
    display: inline-block;
    float: none;
    width: 25%;
    margin-right: 0%;
    margin-bottom: 15px;
    color: #000;
    background-color: transparent;
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid #000000;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    min-height: 200px;
    @include bp-xs {
        display: block;
        float: none;
        width: 100%;
        margin-right: 0%;
        min-height: 200px;
    }
    @include bp-sm {
        display: inline-block;
        width: auto;
        margin-right: 2%;
        min-height: 250px;
    }
    @include bp-md {
        display: inline-block;
        width: auto;
        margin-right: 2%;
        min-height: 300px;
    }
    @include bp-lg {
        display: inline-block;
        width: auto;
        margin-right: 2%;
        min-height: 300px;
    }
}

.listbox_2 li {
    width: 48%;
    margin-right: 2%;
    font-size: 1.6em;
    @include bp-xs {
        width: 98%;
        margin-right: 2%;
    }
    @include bp-sm {
        width: 45%;
        margin-right: 2%;
    }
    @include bp-md {
        width: 45%;
        margin-right: 2%;
    }
    @include bp-lg {
        width: 45%;
        margin-right: 2%;
    }
}

.listbox_3 li {
    width: 31%;
    margin-right: 2%;
    font-size: 1.6em;
    @include bp-xs {
        width: 98%;
        margin-right: 2%;
    }
    @include bp-sm {
        width: 48%;
        margin-right: 2%;
    }
    @include bp-md {
        width: 31%;
        margin-right: 2%;
    }
    @include bp-lg {
        width: 31%;
        margin-right: 2%;
    }
}
.listbox_4 li {
    width: 23%;
    margin-right: 2%;
    font-size: 1.6em;
    @include bp-xs {
        width: 98%;
        margin-right: 2%;
    }
    @include bp-sm {
        float: left;
        width: 48%;
        margin-right: 2%;
    }
    @include bp-md {
        width: 23%;
        margin-right: 2%;
    }
    @include bp-lg {
        width: 23%;
        margin-right: 2%;
    }
}

.listbox li span {
    display: block;
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
}

.listbox li a {
    display: block;
    position: relative;
    height: auto;
    width: 100%;
    line-height: 1em;
    font-size: 1.3em;
    padding-right: 15px;
    padding-left: 15px;
    //min-height: 300px;
    position: absolute;
    text-align: center;
    @include transition(all 700ms ease-in-out 0s);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    /* IE 9 */
    -webkit-transform: translate(-50%, -50%);
    /* Safari and Chrome */
    -o-transform: translate(-50%, -50%);
    /* Opera */
    -moz-transform: translate(-50%, -50%);
    /* Firefox */
    @include bp-xs {
        font-size: 1.3em;
    }
    @include bp-sm {
        font-size: 1.4em;
    }
    @include bp-md {
        font-size: 1.5em;
    }
    @include bp-lg {
        font-size: 1.5em;
    }
}

.listbox li:hover {
    background-color: $primary-accent-color;
    color: #FFF;
}

.listbox li:hover a {
    color: #FFF;
}

.listbox:after {
    content: '';
    display: block;
    clear: both;
    width: 100%;
    position: relative;
}
