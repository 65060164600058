/*** Pre-Loading Animations  ***/

#idle-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: #FFF;
    opacity: 0;
    visibility: hidden;
}

#idle-wrapper.active {
    visibility: visible;
    opacity: 1;
    @include transition(all 250ms ease-in-out 50ms);
}


/* Loading Animation */

.idle-icon-spin {
    width: 120px;
    height: 120px;
    margin: 0;
    z-index: 1005;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    /* IE 9 */
    -webkit-transform: translate(-50%, -50%);
    /* Safari and Chrome */
    -o-transform: translate(-50%, -50%);
    /* Opera */
    -moz-transform: translate(-50%, -50%);
    /* Firefox */
}

.idle-icon-spin img {
    position: absolute;
    display: block;
    @include animation('spin 2000ms infinite linear');
}

@include keyframes(spin) {
    from {
        @include rotate(0);
    }
    to {
        @include rotate(360);
    }
}


/* AJAX Loading Animation */

#idle {
    background-color: rgba(255, 255, 255, 1);
    text-align: center;
    display: block;
    margin: 0px;
    height: 100%;
    width: 100%;
    padding-top: 200px;
    padding-bottom: 200px;
    position: absolute;
    opacity: 1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 750;
}

#idle span {
    font-size: 24px;
    font-weight: bold;
    color: #971b1e;
    display: none;
}

.load-more {
    display: block;
}

#idle.fadeout {
    opacity: 0;
    @include transition(all 750ms ease-in-out 0ms);
}