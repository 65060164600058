/*********************************************
	Lists
*********************************************/

ul,
ol {}

ul {
    margin: 1em 0;
    padding: 0 0 0 40px;
    list-style-position: inside;
    list-style-image: none;
    list-style-type: square;
}

ul ul {
    margin: 1em 0;
}

ol {
    list-style-position: inside;
    list-style-image: none;
}

.list-none {
    list-style-position: inside;
    list-style-image: none;
    list-style-type: none;
}