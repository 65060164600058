/*********************************************
	Inputs
*********************************************/

input,
textarea,
select {
    color: #000;
    background-color: #f2f2f2;
    line-height: 14px;
    font-size: 14px;
    padding: 5px;
    -webkit-transition: all 500ms ease-in-out 0s;
    -moz-transition: all 500ms ease-in-out 0s;
    -ms-transition: all 500ms ease-in-out 0s;
    -o-transition: all 500ms ease-in-out 0s;
    transition: all 500ms ease-in-out 0s;
    background-repeat: no-repeat;
    background-position: 5px center;
    border: 0px solid #FFF;
    height: auto;
    width: auto;
    max-width: 1005;
}

input[type="submit"],
textarea,
select {
    background-image: none;
}

input:focus,
textarea:focus,
select:focus {
    color: #000;
    background-color: #f2f2f2;
    background-image: none;
}

input:valid,
textarea:valid,
select:valid {}

input:invalid,
textarea:invalid,
select:invalid {
    background-color: #f2f2f2;
}

input[placeholder] {
    font-weight: 300;
}

::-webkit-input-placeholder {
    color: #971b1e;
}

:-moz-placeholder {
    color: #971b1e;
}

::-moz-placeholder {
    color: #971b1e;
}

:-ms-input-placeholder {
    color: #971b1e;
}

label {
    font-family: 'Hind', sans-serif;
    font-size: 14px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: normal;
    display: inline-block;
}

input#submit {
    width: auto;
    color: $primary-accent-color;
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    text-align: center;
}

input[type="checkbox"] {
    background-color: #CCC;
    display: inline-block;
}