//import external fonts
//@import url(https://fonts.googleapis.com/css?family=Nunito:300);
//CUSTOM FONTS HERE -- comment out any not used to save space
//Frutiger Family
//@import "../fonts/Frutiger/stylesheet.scss";
//HelveticaNeue Family
//@import "../fonts/HelveticaNeue/stylesheet.scss";
///Include Custom Fonts @font-face
//Frutiger
@include font-face('FrutigerLTStd', '../fonts/Frutiger/FrutigerLTStd-Light', 300, normal, eot woff woff2 woff svg ttf);
@include font-face('FrutigerLTStd', '../fonts/Frutiger/FrutigerLTStd-Roman', normal, normal, eot woff woff2 woff svg ttf);
@include font-face('FrutigerLTStd', '../fonts/Frutiger/FrutigerLTStd-Bold', bold, normal, eot woff woff2 woff svg ttf);
@include font-face('FrutigerLTStd', '../fonts/Frutiger/FrutigerLTStd-Black', 900, normal, eot woff woff2 woff svg ttf);
//Condensed
@include font-face('Frutiger Cond', '../fonts/Frutiger/Frutiger-Cn', normal, normal, eot woff woff2 woff svg ttf);
@include font-face('Frutiger Cond', '../fonts/Frutiger/Frutiger-BoldCn', bold, normal, eot woff woff2 woff svg ttf);
//LTCondensed
@include font-face('FrutigerLTStd Cond', '../fonts/Frutiger/FrutigerLTStd-LightCn', 300, normal, eot woff woff2 woff svg ttf);
@include font-face('FrutigerLTStd Cond', '../fonts/Frutiger/FrutigerLTStd-Cn', normal, normal, eot woff woff2 woff svg ttf);
@include font-face('FrutigerLTStd Cond', '../fonts/Frutiger/FrutigerLTStd-BoldCn', bold, normal, eot woff woff2 woff svg ttf);
@include font-face('FrutigerLTStd Cond', '../fonts/Frutiger/FrutigerLTStd-BlackCn', 900, normal, eot woff woff2 woff svg ttf);
//Set global font
body {
    font-family: $font-family-base;
    font-weight: normal;
    font-style: normal;
}


/*********************************************
	Link Styles
*********************************************/

a {
    outline: 0;
}

a:active {
    text-decoration: none;
    outline: 0;
}

a:link {
    color: $primary-link-color;
    text-decoration: none;
    outline: 0;
}

a:visited {
    color: $primary-link-color;
    text-decoration: none;
    outline: 0;
}

a:hover {
    color: $primary-link-color-hover;
    text-decoration: none;
    outline: 0;
}


/*********************************************
	Headings
*********************************************/

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-base;
    margin: 0px;
    padding: 0px;
    font-weight: inherit;
    font-size: inherit;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}


/*********************************************
	Fonts
*********************************************/

.font-s-8 {
    font-size: 8px;
}

.font-s-10 {
    font-size: 10px;
}

.font-s-12 {
    font-size: 12px;
}

.font-s-14 {
    font-size: 14px;
}

.font-s-16 {
    font-size: 16px;
}

.font-s-18 {
    font-size: 18px;
}

.font-s-20 {
    font-size: 20px;
}

.font-s-22 {
    font-size: 22px;
}

.font-s-24 {
    font-size: 24px;
}

.font-s-26 {
    font-size: 26px;
}

.font-s-28 {
    font-size: 28px;
}

.font-s-30 {
    font-size: 30px;
}

.font-s-32 {
    font-size: 32px;
}

.font-s-34 {
    font-size: 34px;
}

.font-s-36 {
    font-size: 36px;
}

.font-s-38 {
    font-size: 38px;
}

.font-s-40 {
    font-size: 40px;
}

.font-s-42 {
    font-size: 42px;
}

.font-s-44 {
    font-size: 44px;
}

.font-s-46 {
    font-size: 46px;
}

.font-s-48 {
    font-size: 48px;
}

.font-s-60 {
    font-size: 60px;
}

//font weights
.font-w-100 {
    font-weight: 100;
}

.font-w-200 {
    font-weight: 200;
}

.font-w-300 {
    font-weight: 300;
}

.font-w-400 {
    font-weight: 400;
}

.font-w-500 {
    font-weight: 500;
}

.font-w-600 {
    font-weight: 600;
}

.font-w-700 {
    font-weight: 700;
}

.font-w-800 {
    font-weight: 800;
}

//Font Alignment
.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.font-p {
    font-family: $FrutigerLT;
    line-height: 2em;
    padding-top: 0.5em;
    padding-bottom: 1em;
    font-weight: normal;
    font-style: normal;
}

.font-pad-small {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.font-pad-medium {
    padding-top: 0.5em;
    padding-bottom: 0.7em;
}

//Title
.font-title {
    font-family: $FrutigerLT-cond;
    color: #72767c;
    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    padding-top: 0em;
    padding-bottom: 0.7em;
    letter-spacing: 0.1em;
    @include bp-xs-max {
        font-size: 1em;
    }
    @include bp-sm-max {
        font-size: 1.5em;
    }
    @include bp-md-max {
        font-size: 2em;
    }
}


/*********************************************
	Set Font Colors
*********************************************/

.white {
    color: #FFFFFF!important;
}

.black {
    color: #000000!important;
}

.grey {
    color: #b7b7b7!important;
}

.red {
    color: #971b1e!important;
}

.red-dark {
    color: #580d08!important;
}

.brown {
    color: #3a2007!important;
}

.orange {
    color: #db8524!important;
}

.blue {
    color: #004b8d!important;
}


/*********************************************
	Text Classes
*********************************************/

p {
    margin-top: 4px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;
}

strong {
    font-weight: 700;
}

.bold {
    font-weight: bold;
}

.left {
    text-align: left!important;
}

.right {
    text-align: right!important;
}

.center {
    text-align: center!important;
}

.uppercase {
    text-transform: uppercase;
}

blockquote {
    background: transparent;
    border-left: 0px solid #000;
    quotes: "\201C""\201D""\2018""\2019";
    margin-top: 1em;
    margin-right: 10px;
    margin-bottom: 1.5em;
    margin-left: 10px;
    padding-top: 0.5em;
    padding-right: 10px;
    padding-bottom: 0.5em;
    padding-left: 10px;
    font-size: 2.5em;
    line-height: 1.1em;
}

blockquote:before {
    color: #000;
    content: ”\201C”;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

blockquote:after {
    display: block;
    color: #000;
    content: ”\201D”;
    content: close-quote;
    font-size: 0em;
    line-height: 0.1em;
    margin-left: 0.25em;
    vertical-align: -2em;
}

blockquote p {
    display: inline;
}

blockquote em {
    display: inline;
    font-size: 0.5em;
    color: #000000;
    clear: both;
}


/*********************************************
	Text Classes
*********************************************/

.title {
    font-family: $FrutigerLT;
    display: block;
    text-align: center;
    font-size: 3em;
    font-weight: 300;
    line-height: 1.5em;
    text-transform: uppercase;
    color: $blue-light;
    margin-bottom: 40px;
    letter-spacing: 0.25em;
    span {
        display: inline-block;
    }
    @include bp-xs {
        font-size: 2em;
        padding-right: 20px;
        padding-left: 20px;
    }
    @include bp-sm {
        font-size: 2.5em;
        padding-right: 20px;
        padding-left: 20px;
    }
    @include bp-md {
        font-size: 3em;
        padding-right: 20px;
        padding-left: 20px;
    }
}

.title_alt {
    span {
        display: inline-block;
        &:after {
            content: '';
            display: none;
            clear: both;
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto;
            width: 2.5em;
            height: 2px;
            background-color: #004b8d;
        }
    }
}

.font-quote {
    font-family: $FrutigerLT-cond;
    display: block;
    text-align: left;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.5em;
    text-transform: normal;
    color: $primary-base-color;
    margin-bottom: 1.5em;
}

.font-q-author {
    font-family: $FrutigerLT-cond;
    display: block;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: $blue-light;
    margin-bottom: 10px;
}