/*
gallery-grid Format

<ul class="gallery-grid gallery-grid_2">
    <li><a href="" title="">Title</a></li>
</ul>

*/

.gallery-grid {
    list-style-position: inside;
    list-style-image: none;
    list-style-type: none;
    display: block;
    clear: both;
    height: auto;
    width: 100%;
    margin: 0px;
    padding-top: 15px;
    padding-right: 0px;
    padding-bottom: 15px;
    padding-left: 0px;
    margin-top: 90px;
    margin-bottom: 90px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
}

.gallery-grid li {
    font-family: 'Oswald', sans-serif;
    text-align: center;
    display: inline-block;
    float: none;
    width: 25%;
    margin-right: 0%;
    margin-bottom: 15px;
    color: #000;
    background-color: #FFFFFF;
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: 400;
    text-transform: uppercase;
    border: 0px solid #FFFFFF;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    min-height: 100px;
    @include bp-xs {
        display: block;
        float: none;
        width: 100%;
        margin-right: 0%;
        min-height: 100px;
    }
    @include bp-sm {
        display: inline-block;
        width: auto;
        margin-right: 2%;
        min-height: 150px;
    }
    @include bp-md {
        display: inline-block;
        width: auto;
        margin-right: 2%;
        min-height: 200px;
    }
    @include bp-lg {
        display: inline-block;
        width: auto;
        margin-right: 2%;
        min-height: 200px;
    }
}

.gallery-grid_2 li {
    width: 48%;
    margin-right: 2%;
    font-size: 1.6em;
    @include bp-xs {
        width: 98%;
        margin-right: 2%;
    }
    @include bp-sm {
        width: 45%;
        margin-right: 2%;
    }
    @include bp-md {
        width: 45%;
        margin-right: 2%;
    }
    @include bp-lg {
        width: 45%;
        margin-right: 2%;
    }
}

.gallery-grid_3 li {
    width: 31%;
    margin-right: 2%;
    font-size: 1.6em;
    @include bp-xs {
        width: 98%;
        margin-right: 2%;
    }
    @include bp-sm {
        width: 48%;
        margin-right: 2%;
    }
    @include bp-md {
        width: 31%;
        margin-right: 2%;
    }
    @include bp-lg {
        width: 31%;
        margin-right: 2%;
    }
}

.gallery-grid_6 li {
    width: 16.66666666%;
    margin-right: 2%;
    @include bp-xs {
        width: 98%;
        margin-right: 0%;
    }
    @include bp-sm {
        width: 31%;
        margin-right: 0%;
    }
    @include bp-md {
        width: 16.666666%;
        margin-right: 0%;
    }
    @include bp-lg {
        width: 16.666666%;
        margin-right: 0%;
    }
}

.gallery-grid li span {
    display: block;
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
}

.gallery-grid li:hover {
    background-color: $primary-accent-color;
    color: #FFF;
}

.gallery-grid li:hover a {
    color: #FFF;
}

.gallery-grid:after {
    content: '';
    display: block;
    clear: both;
    width: 100%;
    position: relative;
}