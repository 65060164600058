.action_box {
    position: relative;
    text-align: center;
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
}

.action_title {
    display: inline-block;
    width: auto;
    margin-right: auto;
    margin-left: auto;
    padding-top: 15px;
    padding-right: 25px;
    padding-bottom: 15px;
    padding-left: 25px;
    position: relative;
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    color: $primary-accent-color;
    text-align: center;
}

a.action_btn {
    display: inline-block;
    width: auto;
    margin-right: auto;
    margin-left: auto;
    padding-top: 15px;
    padding-right: 25px;
    padding-bottom: 15px;
    padding-left: 25px;
    position: relative;
    font-size: 42px;
    font-weight: bold;
    text-transform: uppercase;
    color: $primary-accent-color;
    text-align: center;
}

.action_box:hover a.action_btn {
    color: $primary-accent-color;
}

.action_box:before {
    content: '';
    background-color: $primary-base-color;
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    @include transition(all 500ms ease-in-out 0s);
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
}

.action_box:hover:before {
    //-webkit-transform: scale3D(1, 1, 1);
    //transform: scale3D(1, 1, 1);
}

.action_slot {
    display: inline-block;
    margin: 20px;
    padding: 5px;
    font-size: 18px;
    color: #43525a;
    cursor: pointer;
    @include transition(all 700ms ease-in-out 0s);
}

.action_box.active .action_slot {
    font-size: 12px;
}

.action_slot.active {
    color: #008dd2;
}

.action_slot i {
    display: block;
    font-size: 50px;
    padding: 5px;
    color: #43525a;
    @include transition(all 700ms ease-in-out 0s);
}

.action_box.active .action_slot i {
    font-size: 30px;
}

.action_slot.active i {
    color: #008dd2;
}

.action_tab_wrap {
    display: block;
}

.action_tab {
    display: block;
    overflow: hidden;
    max-height: 0px;
    text-align: left;
    @include transition(all 700ms ease-in-out 0s);
}

.action_tab.active {
    display: block;
}