//Bootstrap fix for XS col
.col {
    width: 100%;
    @include bp-xs-max {
        width: 100%;
    }
    @include bp-sm-max {
        width: 100%;
    }
}

.container-max {
    width: 100%;
    display: block;
    position: relative;
    max-width: $max-container-width;
    margin-right: auto;
    margin-left: auto;
}

.container-pad {
    padding-right: 20px;
    padding-left: 20px;
}

//Padding
.pad {
    padding-right: 20px;
    padding-left: 20px;
}

.pad-10 {
    padding-right: 10px;
    padding-left: 10px;
}

.pad-15 {
    padding-right: 15px;
    padding-left: 15px;
}

.pad-20 {
    padding-right: 20px;
    padding-left: 20px;
}

.pad-25 {
    padding-right: 25px;
    padding-left: 25px;
}

.pad-30 {
    padding-right: 30px;
    padding-left: 30px;
}

.pad-35 {
    padding-right: 35px;
    padding-left: 35px;
}

.pad-top-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
}

.pad-top-bottom-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.pad-top-bottom-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.pad-top-bottom-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.pad-top-bottom-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.pad-bottom {
    padding-bottom: 20px;
}

.pad-left {
    padding-left: 20px;
}

.pad-right {
    padding-right: 20px;
}

.pad-right-alt {
    padding-right: 20px;
    @include bp-xs {
        padding-right: 20px;
    }
    @include bp-sm {
        padding-right: 20px;
    }
    @include bp-md {
        padding-right: 40px;
    }
    @include bp-lg {
        padding-right: 40px;
    }
}

//remove .pad
.pad-off {
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.pad-right-off {
    padding-right: 0;
}

.pad-left-off {
    padding-left: 0;
}

//equal padding 
.pad-equal {
    padding-top: 15px;
    padding-bottom: 15px;
}

.percent {
    line-height: 1.5em;
}

//No pad Mobile
.no-pad-mobile {
    @include bp-xs-max {
        padding-right: 0;
        padding-left: 0;
    }
    @include bp-sm-max {
        padding-right: 0;
        padding-left: 0;
    }
    @include bp-md-max {
        padding-right: 0;
        padding-left: 0;
    }
}

//Pad Mobile Only
.pad-mobile {
    @include bp-xs-max {
        padding-right: 20px;
        padding-left: 20px;
    }
    @include bp-sm-max {
        padding-right: 20px;
        padding-left: 20px;
    }
    @include bp-md-max {
        padding-right: 20px;
        padding-left: 20px;
    }
}

//Pad Tablet and below
.pad-tablet {
    @include bp-xs-max {
        padding-right: 20px;
        padding-left: 20px;
    }
    @include bp-sm-max {
        padding-right: 20px;
        padding-left: 20px;
    }
    @include bp-md-max {
        padding-right: 20px;
        padding-left: 20px;
    }
    @include bp-lg-max {
        padding-right: 20px;
        padding-left: 20px;
    }
}

//Pad XXL and below
.pad-max-below {
    @include bp-xs-max {
        padding-right: 20px;
        padding-left: 20px;
    }
    @include bp-sm-max {
        padding-right: 20px;
        padding-left: 20px;
    }
    @include bp-md-max {
        padding-right: 20px;
        padding-left: 20px;
    }
    @include bp-lg-max {
        padding-right: 20px;
        padding-left: 20px;
    }
    @include bp-xl-max {
        padding-right: 20px;
        padding-left: 20px;
    }
    @include bp-xxl-max {
        padding-right: 20px;
        padding-left: 20px;
    }
}

.col-border-left {
    border-left-color: #72767c;
    border-left-style: solid;
    border-left-width: 2px;
    padding-left: 40px;
    padding-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    @include bp-xs-max {
        border-left-width: 0px;
    }
    @include bp-sm-max {
        border-left-width: 0px;
    }
    @include bp-md-max {
        border-left-width: 2px;
    }
}

.col-border-left-inner {
    padding-left: 40px;
    padding-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 1em;
        bottom: 0em;
        left: 0px;
        border-left-color: #72767c;
        border-left-style: solid;
        border-left-width: 1px;
        @include bp-xs {
            border-left-width: 0px;
        }
        @include bp-sm {
            border-left-width: 0px;
        }
        @include bp-md {
            border-left-width: 1px;
        }
        @include bp-lg {
            border-left-width: 1px;
        }
    }
    &.alt {
        padding-left: 80px;
        padding-right: 0px;
        @include bp-xs {
            padding-left: 0px;
        }
        @include bp-sm {
            padding-left: 0px;
        }
        @include bp-md {
            padding-left: 60px;
        }
        @include bp-lg {
            padding-left: 60px;
        }
    }
}

.pad-mobile {
    .col-border-left-inner {
        @include bp-xs-max {
            padding-left: 0px;
            padding-right: 0px;
        }
        @include bp-sm-max {
            padding-left: 0px;
            padding-right: 0px;
        }
        @include bp-md-max {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}