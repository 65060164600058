/// Adds a browser prefix to the property
/// @param {*} $property Property
/// @param {*} $value Value
@mixin css3-prefix($property, $value) {
    -webkit-#{$property}: #{$value};
    -khtml-#{$property}: #{$value};
    -moz-#{$property}: #{$value};
    -ms-#{$property}: #{$value};
    -o-#{$property}: #{$value};
    #{$property}: #{$value};
}

// Colors - rgba opacity
//@include color-rgba( $primary-accent-color, 0.9);
@mixin color-rgba($color, $opacity: 1.0) {
    color: $color;
    color: rgba($color, $opacity);
}

// Colors Background - rgba opacity
//@include background-color-rgba( $primary-accent-color, 0.9);
@mixin background-color-rgba($color, $opacity: 1.0) {
    background-color: $color;
    background-color: rgba($color, $opacity);
}

////FONTS/////
// Rem output with px fallback
//@include font-size(14px);
@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

////STYLES/////
/// Text Shadow
//@include font-size(1px, 1px, 3px, rgba(0, 0, 0, .1));
@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, .4)) {
    text-shadow: $x $y $blur $color;
}

//Border Radius
//@include border-radius(5px)
@mixin border-radius ( $value: 3px) {
    @include css3-prefix('border-radius', $value);
    // keeps background from busting out of border 
    @include css3-prefix('background-clip', padding-box);
}

//Shadows
//@include box-shadow(0px 0px 2px 2px rgba(0,0,0,1))
@mixin box-shadow($box-shadow-value) {
    -webkit-box-shadow: $box-shadow-value;
    -moz-box-shadow: $box-shadow-value;
    box-shadow: $box-shadow-value;
}

//Box Sizing
@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model; // Safari <= 5
    -moz-box-sizing: $box-model; // Firefox <= 19
    box-sizing: $box-model;
}

////LAYOUT/////
//MIN WIDTH BREAKPOINTS - DEFAULT
//@include bp-small { add css here }

/*   
@include bp-xs {}
@include bp-sm {}
@include bp-md {}
@include bp-lg {}
@include bp-xl {}
@include bp-xxl {}
*/

@mixin bp-xs {
    @media only screen and (min-width: $grid-breakpoints-xs) {
        @content;
    }
}

@mixin bp-xs-land {
    @media only screen and (min-width: $grid-breakpoints-xs) and (orientation: landscape) {
        @content;
    }
}

@mixin bp-sm {
    @media only screen and (min-width: $grid-breakpoints-sm) {
        @content;
    }
}

@mixin bp-sm-land {
    @media only screen and (min-width: $grid-breakpoints-sm) and (orientation: landscape) {
        @content;
    }
}

@mixin bp-md {
    @media only screen and (min-width: $grid-breakpoints-md) {
        @content;
    }
}

@mixin bp-md-land {
    @media only screen and (min-width: $grid-breakpoints-md) and (orientation: landscape) {
        @content;
    }
}

@mixin bp-lg {
    @media only screen and (min-width: $grid-breakpoints-lg) {
        @content;
    }
}

@mixin bp-lg-land {
    @media only screen and (min-width: $grid-breakpoints-lg) and (orientation: landscape) {
        @content;
    }
}

@mixin bp-xl {
    @media only screen and (min-width: $grid-breakpoints-xl) {
        @content;
    }
}

@mixin bp-xxl {
    @media only screen and (min-width: $grid-breakpoints-xxl) {
        @content;
    }
}

@mixin bp-xxxl {
    @media only screen and (min-width: $grid-breakpoints-xxxl) {
        @content;
    }
}

// MAX WIDTH BREAKPOINTS

/*   
@include bp-xs-max {}
@include bp-sm-max {}
@include bp-md-max {}
@include bp-lg-max {}
@include bp-xl-max {}
@include bp-xxl-max {}
*/

@mixin bp-xs-max {
    @media only screen and (max-width: $grid-breakpoints-xs) {
        @content;
    }
}

@mixin bp-sm-max {
    @media only screen and (max-width: $grid-breakpoints-sm) {
        @content;
    }
}

@mixin bp-md-max {
    @media only screen and (max-width: $grid-breakpoints-md) {
        @content;
    }
}

@mixin bp-lg-max {
    @media only screen and (max-width: $grid-breakpoints-lg) {
        @content;
    }
}

@mixin bp-xl-max {
    @media only screen and (max-width: $grid-breakpoints-xl) {
        @content;
    }
}

@mixin bp-xxl-max {
    @media only screen and (max-width: $grid-breakpoints-xxl) {
        @content;
    }
}

@mixin bp-xxxl-max {
    @media only screen and (max-width: $grid-breakpoints-xxxl) {
        @content;
    }
}

//max landscape
@mixin bp-xs-max-land {
    @media only screen and (max-width: $grid-breakpoints-xs) and (orientation: landscape) {
        @content;
    }
}

@mixin bp-sm-max-land {
    @media only screen and (max-width: $grid-breakpoints-sm) and (orientation: landscape) {
        @content;
    }
}

@mixin bp-md-max-land {
    @media only screen and (max-width: $grid-breakpoints-md) and (orientation: landscape) {
        @content;
    }
}

@mixin bp-lg-max-land {
    @media only screen and (max-width: $grid-breakpoints-lg) and (orientation: landscape) {
        @content;
    }
}

@mixin bp-xl-max-land {
    @media only screen and (max-width: $grid-breakpoints-xl) and (orientation: landscape) {
        @content;
    }
}

@mixin bp-xxl-max-land {
    @media only screen and (max-width: $grid-breakpoints-xxl) and (orientation: landscape) {
        @content;
    }
}

@mixin bp-xxxl-max-land {
    @media only screen and (max-width: $grid-breakpoints-xxxl) and (orientation: landscape) {
        @content;
    }
}

// Clearfix
//@include clearfix;
@mixin clearfix() {
    content: "";
    display: table;
    table-layout: fixed;
}

// Clear after (not all clearfix need this also)
//@include clearfix-after;
@mixin clearfix-after {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

// Center block
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

//CSS 3 Transforms
// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

// rotate
//@include rotate(top left);
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}

// scale
//@include scale(1.5);
@mixin scale($scale) {
    @include transform(scale($scale));
}

// translate
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}

// skew
//@include skew(25,10);
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
//@include transform-origin(top left);
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}

//Translate
//@include translate(50%, 50%);
@mixin translate($top, $left) {
    transform: translate(-$left, -$top);
    -ms-transform: translate(-$left, -$top);
    /* IE 9 */
    -webkit-transform: translate(-$left, -$top);
    /* Safari and Chrome */
    -o-transform: translate(-$left, -$top);
    /* Opera */
    -moz-transform: translate(-$left, -$top);
    /* Firefox */
}

//center-block
//@include translatecenter(50%, 50%);
@mixin translatecenter($top, $left) {
    position: absolute;
    top: $top;
    left: $left;
    transform: translate(-$left, -$top);
    -ms-transform: translate(-$left, -$top);
    /* IE 9 */
    -webkit-transform: translate(-$left, -$top);
    /* Safari and Chrome */
    -o-transform: translate(-$left, -$top);
    /* Opera */
    -moz-transform: translate(-$left, -$top);
    /* Firefox */
}

///CSS Keyframes
//@include keyframes(name 5s linear 2s infinite alternate);
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

//CSS Animation
//@include animation(name 5s linear 2s infinite alternate);
@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

//CSS Transition
//@include transition(width 2s linear 1s);
@mixin transition($str) {
    -webkit-transition: #{$str};
    -moz-transition: #{$str};
    -ms-transition: #{$str};
    -o-transition: #{$str};
    transition: #{$str};
}

//COMPASS CSS3 MIXINS
///// Mixin printing a linear-gradient
// =============================================================================
// String Replace
// =============================================================================
@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1)+$replace+str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}

// =============================================================================
// Font Face
// =============================================================================
//@include font-face(Samplinal, fonts/Samplinal, 500, normal, eot woff2 woff);
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;
    $extmods: ( eot: "?", svg: "#" + str-replace($name, " ", "_"));
    $formats: ( otf: "opentype", ttf: "truetype");
    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }
    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}