/** fold Slider Slide Styles**/

.owl-carousel.owl-slider {
    display: block;
    height: auto;
    width: 100%;
    overflow: hidden;
    @include bp-xs {
        height: auto;
        max-height: 3000px;
    }
    @include bp-sm {
        height: auto;
        max-height: 3000px;
    }
    @include bp-md {
        height: auto;
        max-height: 3000px;
    }
    @include bp-lg {
        height: auto;
        max-height: 3000px;
    }
    @include bp-xl {
        height: auto;
        max-height: 3000px;
    }
    @include bp-xxl {
        height: auto;
        max-height: 3000px;
    }
    .owl-stage-outer {
        min-height: 0px;
        overflow: hidden;
    }
    .owl-item {}
    .owl-item .owl-slide {
        position: relative;
        min-height: 300px;
        height: auto;
        overflow: hidden;
        text-align: center;
        @include transition(all 500ms ease-in-out 0ms);
    }
    .owl-sub {
        display: block;
        height: auto;
        width: auto;
        z-index: 500;
        text-align: center;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        max-width: 1160px;
        padding-left: 7em;
        padding-right: 7em;
        top: 0%;
        left: 0%;
        padding-top: 2em;
        padding-bottom: 0em;
        overflow: hidden;
        @include translate(0%, 0%);
        @include transition(all 500ms ease-in-out 0ms);
        @include bp-xs {
            padding-left: 4em;
            padding-right: 4em;
            padding-top: 6%;
        }
        @include bp-sm {
            padding-left: 5em;
            padding-right: 5em;
            padding-top: 10%;
        }
        @include bp-md {
            padding-left: 5.5em;
            padding-right: 5.5em;
            padding-top: 12%;
        }
        @include bp-lg {
            padding-left: 6em;
            padding-right: 6em;
            padding-top: 14%;
            padding-bottom: 1%;
        }
        @include bp-xl {
            padding-left: 6em;
            padding-right: 6em;
            padding-top: 16%;
            padding-bottom: 3%;
        }
        @include bp-xxl {
            padding-left: 6em;
            padding-right: 6em;
            padding-top: 18%;
            padding-bottom: 6%;
        }
        @include bp-xxl {
            padding-left: 6em;
            padding-right: 6em;
            padding-top: 20%;
            padding-bottom: 9%;
        }
    }
    .owl-item img.owl-bg {
        max-width: 1000%;
        min-width: 100%;
        min-height: 100%;
        height: auto;
        width: auto;
        z-index: 400;
        @include bp-xs {
            height: auto;
            width: 100%;
            min-width: 100%;
            min-height: 100%;
            max-width: 1000%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        @include bp-sm {
            height: auto;
            width: 100%;
            min-width: 100%;
            min-height: 100%;
            max-width: 1000%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        @include bp-md {
            height: auto;
            width: 100%;
            min-width: 100%;
            min-height: 100%;
            max-width: 1000%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        @include bp-lg {
            height: auto;
            width: 100%;
            min-width: 100%;
            min-height: 100%;
            max-width: 1000%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        @include bp-xl {
            height: auto;
            width: 100%;
            min-width: 100%;
            min-height: 100%;
            max-width: 1000%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        @include bp-xxl {}
    }
    .owl-item img.owl-shadow {
        height: auto;
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        z-index: 450;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    @media only screen and (min-width: 769px) {
        .owl-stage {
            height: auto;
        }
        .owl-item {
            height: auto;
        }
        .seq-slide {
            height: auto;
        }
    }
    @media only screen and (min-width: 1024px) {
        .owl-stage {
            height: auto;
        }
        .owl-item {
            height: auto;
        }
        .seq-slide {
            height: auto;
        }
    }
    @media only screen and (min-width: 1232px) {
        .owl-item img {}
        .owl-stage-outer {}
        .owl-stage {
            height: auto;
        }
        .owl-item {
            height: auto;
        }
        .seq-slide {
            height: auto;
        }
    }
    .owl-nav {}
    .owl-nav [class*=owl-] {}
    .owl-nav [class*=owl-]:hover {}
    .owl-dots {}
    .owl-dots .owl-dot {}
    .owl-dots .owl-dot span {}
    .owl-dots .owl-dot.active span {}
    .owl-nav .owl-prev,
    .owl-nav .owl-next {}
    .owl-nav .owl-prev {}
    .owl-nav .owl-next {}
    .owl-slider:hover .owl-nav .owl-prev {}
    .owl-slider:hover .owl-nav .owl-next {}
    .owl-prev:after {}
    .owl-next:after {}
    //Set Text Animations
    .owl-title {
        opacity: 0;
        @include transition(opacity 1000ms ease-in-out 0s);
    }
    .owl-item.active .owl-title {
        opacity: 1;
    }
    .owl-text {
        opacity: 0;
        @include transition(opacity 1000ms ease-in-out 0s);
    }
    .owl-item.active .owl-text {
        opacity: 1;
    }
    .owl-link {
        opacity: 0;
        //background-color: #FFF;
        //background-color: rgba(255, 255, 255, 0);
        @include transition(opacity 1000ms ease-in-out 0s);
    }
    .owl-item.active .owl-link {
        display: inline-block;
        opacity: 1;
        position: relative;
        margin-bottom: 25px;
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding: 15px 40px 12px 40px;
        @include bp-xs {
            font-size: 14px;
            line-height: 12px;
            margin-bottom: 10px;
        }
        @include bp-sm {
            font-size: 16px;
            line-height: 14px;
            margin-bottom: 15px;
        }
        @include bp-md {
            font-size: 18px;
            line-height: 16px;
            margin-bottom: 20px;
        }
        @include bp-lg {
            font-size: 20px;
            line-height: 18px;
            margin-bottom: 25px;
        }
    }
    //Default Nav
    .owl-nav {
        position: absolute;
        background-color: transparent;
        background-image: none;
        top: 50%;
        left: 0px;
        right: 0px;
    }
    .owl-nav [class*=owl-] {
        position: absolute;
        background-color: transparent;
        background-image: none;
        padding-top: 0px;
        padding-right: 3px;
        padding-bottom: 0px;
        padding-left: 3px;
    }
    .owl-nav [class*=owl-]:hover {}
    .owl-dots {
        position: absolute;
        bottom: 0px;
        margin-left: auto;
        left: 0%;
        margin-right: auto;
        right: 0px;
        display: block;
        height: 20px;
        width: auto;
        line-height: normal;
        text-align: center;
    }
    @media only screen and (min-width: 0px) {
        .owl-dots {
            position: absolute;
            bottom: 20px;
        }
    }
    @media only screen and (min-width: 481px) {
        .owl-dots {
            position: absolute;
            bottom: 20px;
        }
    }
    @media only screen and (min-width: 769px) {
        .owl-dots {
            position: absolute;
            bottom: 40px;
        }
    }
    .owl-nav.disabled,
    .owl-dots.disabled {
        display: none;
    }
    .owl-dots .owl-dot {
        display: inline-block;
        margin: 0px;
        padding: 0px;
        height: auto;
        width: auto;
        height: 20px;
        margin-right: 15px;
    }
    .owl-dots .owl-dot:first-child {}
    .owl-dots .owl-dot:last-child {
        margin-right: 0px;
    }
    .owl-dots .owl-dot span {
        display: block;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-color: #FFFFFF;
        background-color: rgba(255, 255, 255, 0.5);
        height: 10px;
        width: 10px;
        border: 0px solid #7f7f7f;
    }
    .owl-dots .owl-dot.active span {
        background-color: #2f94bf;
    }
    .owl-nav .owl-prev,
    .owl-nav .owl-next {
        height: 4em;
        width: 4em;
        opacity: 0;
        transition: all 200ms ease-in-out 0s;
        border: 3px solid #FFF;
        border-radius: 50%;
        @include bp-xs {
            height: 2em;
            width: 2em;
            border: 2px solid #FFF;
        }
        @include bp-sm {
            height: 2em;
            width: 2em;
            border: 2px solid #FFF;
        }
        @include bp-md {
            height: 3em;
            width: 3em;
            border: 3px solid #FFF;
        }
        @include bp-lg {
            height: 4em;
            width: 4em;
        }
        &:hover {
            background-color: rgba(255, 255, 255, 0.4);
        }
    }
    .owl-nav .owl-prev {
        left: 3em;
        @include bp-xs {
            left: 1.5em;
        }
        @include bp-sm {
            left: 2em;
        }
        @include bp-md {
            left: 2.5em;
        }
        @include bp-lg {
            left: 3em;
        }
    }
    .owl-nav .owl-next {
        right: 3em;
        @include bp-xs {
            right: 1.5em;
        }
        @include bp-sm {
            right: 2em;
        }
        @include bp-md {
            right: 2.5em;
        }
        @include bp-lg {
            right: 3em;
        }
    }
    &:hover .owl-nav .owl-prev {
        opacity: 1;
    }
    &:hover .owl-nav .owl-next {
        opacity: 1;
    }
    .owl-prev:after,
    .owl-next:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .owl-prev:after {
        content: "";
        background-image: url(#{$assets_path}img/svg/nav-left-arrow.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        display: inline-block;
        height: 100%;
        width: 100%;
        margin-left: -5%;
    }
    .owl-next:after {
        content: "";
        background-image: url(#{$assets_path}img/svg/nav-right-arrow.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        display: inline-block;
        height: 100%;
        width: 100%;
        margin-left: 5%;
    }
    /*INLINE NAV*/
    .owl-inline-nav .owl-nav {
        position: absolute;
        background-color: transparent;
        background-image: none;
        top: auto;
        bottom: 10px;
        left: auto;
        right: auto;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    .owl-inline-nav .owl-prev:after {
        content: "";
        font-size: 12px;
        background-image: url(#{$assets_path}img/svg/arrow-prev.svg);
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        height: 20px;
        width: 10px;
    }
    .owl-inline-nav .owl-next:after {
        content: "";
        font-size: 12px;
        background-image: url(#{$assets_path}img/svg/arrow-next.svg);
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        height: 20px;
        width: 10px;
    }
    .owl-inline-nav .owl-nav [class*=owl-] {
        position: absolute;
        background-color: transparent;
        background-image: none;
        padding: 0px;
        margin-top: -20px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        @include bp-xs {
            margin-top: -20px;
        }
        @include bp-sm {
            margin-top: -20px;
        }
        @include bp-md {
            margin-top: -20px;
        }
        @include bp-lg {
            margin-top: -20px;
        }
    }
    .owl-inline-nav .owl-nav .owl-prev {
        left: -15px;
    }
    .owl-inline-nav .owl-nav .owl-next {
        right: -15px;
    }
    .owl-inline-nav:hover .owl-nav .owl-prev {
        left: -15px;
    }
    .owl-inline-nav:hover .owl-nav .owl-next {
        right: -15px;
    }
    .owl-title,
    .owl-title-alt {
        font-family: $FrutigerLT-cond;
        font-weight: normal;
        font-style: normal;
        font-size: 48px;
        padding-bottom: 0.1em;
        line-height: 1.2em;
        @include bp-xs {
            font-size: 32px;
            padding-bottom: 0.1em;
        }
        @include bp-sm {
            font-size: 40px;
            padding-bottom: 0.1em;
        }
        @include bp-md {
            font-size: 48px;
            padding-bottom: 0.2em;
        }
        @include bp-lg {
            font-size: 56px;
            padding-bottom: 0.2em;
        }
    }
    .owl-text {
        font-family: $FrutigerLT;
        font-weight: normal;
        font-style: normal;
        position: relative;
        display: block;
        z-index: 200;
        padding-bottom: 2em;
        @include bp-xs {
            font-size: 18px;
            padding-bottom: 0.5em;
        }
        @include bp-sm {
            font-size: 20px;
            padding-bottom: 0.9em;
        }
        @include bp-md {
            font-size: 22px;
            padding-bottom: 1.2em;
        }
        @include bp-lg {
            font-size: 24px;
            padding-bottom: 1.5em;
        }
    }
    .right .owl-text {
        margin-left: auto;
        margin-right: 0px;
    }
}