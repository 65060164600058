/*** Pre-Loading Animations  ***/

#loader-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: #FFF;
    opacity: 1;
    visibility: visible;
}

.fadeout #loader-wrapper {
    visibility: visible;
    opacity: 0;
    @include transition(all 250ms ease-in-out 50ms);
}

.loaded #loader-wrapper {
    visibility: hidden;
    opacity: 0;
    @include transition(all 100ms ease-in-out 0ms);
}


/* Loading Animation */

.loader-dots {
    position: absolute;
    z-index: 1005;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    .loader-dots-inner {
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        width: calc(100%);
        color: #11ada2;
        padding: 0 100px;
        text-align: center;
        label {
            font-size: 20px;
            opacity: 0;
            display: inline-block;
        }
        label:nth-child(6) {
            -webkit-animation: lol 3s infinite ease-in-out;
            animation: lol 3s infinite ease-in-out;
        }
        label:nth-child(5) {
            -webkit-animation: lol 3s 100ms infinite ease-in-out;
            animation: lol 3s 100ms infinite ease-in-out;
        }
        label:nth-child(4) {
            -webkit-animation: lol 3s 200ms infinite ease-in-out;
            animation: lol 3s 200ms infinite ease-in-out;
        }
        label:nth-child(3) {
            -webkit-animation: lol 3s 300ms infinite ease-in-out;
            animation: lol 3s 300ms infinite ease-in-out;
        }
        label:nth-child(2) {
            -webkit-animation: lol 3s 400ms infinite ease-in-out;
            animation: lol 3s 400ms infinite ease-in-out;
        }
        label:nth-child(1) {
            -webkit-animation: lol 3s 500ms infinite ease-in-out;
            animation: lol 3s 500ms infinite ease-in-out;
        }
    }
}

@keyframes lol {
    0% {
        opacity: 0;
        transform: translateX(-300px);
    }
    33% {
        opacity: 1;
        transform: translateX(0px);
    }
    66% {
        opacity: 1;
        transform: translateX(0px);
    }
    100% {
        opacity: 0;
        transform: translateX(300px);
    }
}

@-webkit-keyframes lol {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-300px);
    }
    33% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    66% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(300px);
    }
}


/* AJAX Loading Animation */

#loader {
    background-color: rgba(255, 255, 255, 1);
    text-align: center;
    display: block;
    margin: 0px;
    height: 100%;
    width: 100%;
    padding-top: 200px;
    padding-bottom: 200px;
    position: absolute;
    opacity: 1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 750;
}

#loader span {
    font-size: 24px;
    font-weight: bold;
    color: #971b1e;
    display: none;
}

.load-more {
    display: block;
}

#loader.fadeout {
    opacity: 0;
    @include transition(all 750ms ease-in-out 0ms);
}