.section-video {
    position: relative;
    overflow: hidden;
    height: auto;
    width: 100%;
    display: block;
    video {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        background-color: #000000;
    }
}