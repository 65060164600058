/*********************************************
	Parallax Styles
*********************************************/

.section-sweepstakes {
    text-align: center;
    min-height: 550px;
    .image-grid {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        .image-slot {
            display: inline-block;
            position: relative;
            width: 33.3333%;
            float: left;
            height: auto;
            div.inner {
                display: block;
                position: relative;
                margin-right: 10px;
                margin-bottom: 10px;
                img {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .sweep-img {
        display: block;
        float: right;
        width: 60%;
        margin-left: -2em;
        margin-bottom: -1em;
    }
    .font-sweep {
        font-family: $FrutigerLT;
        font-weight: 300;
        color: $orange;
        font-size: 36px;
        text-transform: uppercase;
        line-height: 1.5em;
        text-align: left;
        margin-bottom: 20px;
        span {
            font-family: $FrutigerLT;
            font-weight: 900;
        }
        .gopro {
            text-transform: none;
        }
        @include bp-xs {
            font-size: 24px;
        }
        @include bp-sm {
            font-size: 28px;
        }
        @include bp-md {
            font-size: 32px;
        }
        @include bp-lg {
            font-size: 36px;
        }
    }
    .font-facebook {
        display: block;
        font-family: $FrutigerLT-cond;
        font-weight: 600;
        line-height: 30px;
        text-transform: uppercase;
        color: $grey;
        font-size: 30px;
        text-align: left;
        vertical-align: middle;
        a {
            color: $grey;
            text-decoration: none;
            transition: all 300ms ease-in-out 0s;
            &:hover {
                color: $blue-dark;
            }
        }
        span {
            display: inline-block;
            vertical-align: middle;
        }
        img {
            display: inline-block;
            vertical-align: middle;
            width: 23px;
            height: 23px;
            margin-top: -1px;
            margin-left: 10px;
        }
    }
}