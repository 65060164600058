/*********************************************
	Footer
*********************************************/

#footer {
    font-family: 'Hind', sans-serif;
    display: block;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    background-color: #FFFFFF;
    color: #000000;
    z-index: 501;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    @include bp-xs {
        text-align: center;
    }
    @include bp-sm {
        text-align: center;
    }
    @include bp-md {
        text-align: left;
    }
    @include bp-lg {
        text-align: left;
    }
}

@include bp-xl {
    .footer-col-right {
        text-align: right;
    }
}

#footer:after {
    content: '';
    display: block;
    clear: both;
}

#footer .logo {
    display: inline-block;
    margin-bottom: 25px;
}

#footer a {
    font-family: $FrutigerLT;
    color: $primary-text-color-light;
    font-weight: 700;
}

#footer a:hover {
    color: #FFF;
}

#footer .f_title {
    font-family: 'Hind', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: #000000;
    display: inline-block;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #c1c1c1;
    line-height: 18px;
    margin-bottom: 12px;
}

#footer a.tel {
    color: #971b1e;
}

#footer a.tel:hover {
    color: #000;
}

.border-right {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #aeaeae;
    min-height: 140px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#footer .col {
    @include bp-xs {
        margin-bottom: 25px;
    }
    @include bp-sm {
        margin-bottom: 25px;
    }
    @include bp-md {
        margin-bottom: 0px;
    }
    @include bp-lg {
        margin-bottom: 0px;
    }
}

.f_nav {
    font-family: 'Hind', sans-serif;
    width: 100%;
    height: auto;
    min-height: 0px;
    margin: 0px;
    text-align: center;
    color: #FFF;
    list-style-image: none;
    list-style-type: none;
    display: block;
    position: relative;
    padding-top: 10px;
    padding-right: 0px;
    padding-bottom: 25px;
    padding-left: 0px;
    @include bp-sm {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    @include bp-md {
        padding-top: 25px;
        padding-bottom: 35px;
    }
    @include bp-lg {
        padding-top: 10px;
        padding-bottom: 55px;
    }
}

.f_nav li {
    float: none;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    color: #FFF;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    width: auto;
    background-color: transparent;
    background-image: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    @include bp-sm {
        float: none;
        display: block;
        text-align: center;
        border-left-width: 0px;
    }
    @include bp-md {
        float: none;
        display: block;
        text-align: center;
    }
    @include bp-lg {
        float: none;
        display: inline-block;
        text-align: left;
    }
}

.f_nav li:hover {
    background-color: transparent;
    background-image: none;
}

#f_nav2 ul {
    -moz-column-count: 2;
    -moz-column-gap: 30px;
    -webkit-column-count: 2;
    -webkit-column-gap: 30px;
    column-count: 2;
    column-gap: 30px;
    /*hack for moz/webkit extra spacing*/
    overflow: hidden;
    position: relative;
    @include bp-sm {
        -moz-column-count: 1;
        -moz-column-gap: 30px;
        -webkit-column-count: 1;
        -webkit-column-gap: 30px;
        column-count: 1;
        column-gap: 30px;
    }
    @include bp-md {
        -moz-column-count: 1;
        -moz-column-gap: 30px;
        -webkit-column-count: 1;
        -webkit-column-gap: 30px;
        column-count: 1;
        column-gap: 30px;
    }
    @include bp-lg {
        -moz-column-count: 2;
        -moz-column-gap: 30px;
        -webkit-column-count: 2;
        -webkit-column-gap: 30px;
        column-count: 2;
        column-gap: 30px;
    }
}

.oldie #f_nav2 ul li {
    width: 45% !important;
    @include bp-sm {
        width: 100% !important;
    }
    @include bp-md {
        width: 100% !important;
    }
    @include bp-lg {
        width: 45% !important;
    }
}

.oldie #f_nav2 ul li:nth-child(even) {
    float: right;
    @include bp-sm {
        float: none;
    }
    @include bp-md {
        float: none;
    }
    @include bp-lg {
        float: right;
    }
}

.f_nav ul {
    display: block;
    margin: 0px;
    padding: 0px;
}

.f_nav ul li {
    display: inline-block;
    margin: 0px;
    padding: 0px;
}

#footer .f_nav li a {
    color: #666666;
    display: inline-block;
    width: auto;
    padding-top: 3px;
    padding-right: 5px;
    padding-bottom: 3px;
    padding-left: 5px;
    font-size: 12px;
    font-weight: 600;
}

#footer .f_nav ul li a {
    color: #666666;
    text-transform: none;
    font-size: 12px;
    @include bp-sm {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    @include bp-md {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    @include bp-lg {
        padding-top: 3px;
        padding-bottom: 3px;
    }
}

#footer .f_nav li a:hover {
    color: #971b1e;
}

.f_logo {
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 150px;
    max-height: 150px;
    margin-bottom: 15px;
}

#copy {
    font-family: $FrutigerLT;
    //text-transform: uppercase;
    font-size: 12px;
    width: 100%;
    text-align: center;
    clear: both;
    color: #666666;
    padding-top: 35px;
    padding-bottom: 35px;
    position: relative;
    z-index: 500;
}

#copy a {
    color: #666666;
}