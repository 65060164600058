/*********************************************
	Parallax Styles
*********************************************/

.section-parallax {
    text-align: center;
    min-height: 550px;
    @include bp-xs {
        min-height: 350px;
    }
    @include bp-sm {
        min-height: 350px;
    }
    @include bp-md {
        min-height: 500px;
    }
    @include bp-lg {
        min-height: 550px;
        height: 40vh;
    }
    @include bp-xl {
        min-height: 600px;
        height: 50vh;
    }
    @include bp-xxl {
        min-height: 600px;
        height: 60vh;
    }
    @include bp-xxxl {
        min-height: 600px;
        height: 70vh;
    }
    .p_image {
        display: block;
        position: absolute;
        background-position: center center;
        background-size: 100% auto;
        width: 100%;
        max-width: 1000%;
        height: 200%;
        min-width: 100%;
        min-height: 100%;
        z-index: 400;
        top: 0%;
        left: 0%;
        @include bp-xs {
            background-size: auto 100%;
        }
        @include bp-sm {
            background-size: auto 100%;
        }
        @include bp-md {
            background-size: 100% auto;
        }
        @include bp-lg {
            background-size: 100% auto;
        }
    }
    .p_image_short {
        height: 150%;
        background-size: cover;
        @include bp-xs {
            background-size: auto 100%;
        }
        @include bp-sm {
            background-size: auto 100%;
        }
        @include bp-md {
            background-size: cover;
        }
        @include bp-lg {
            background-size: cover;
        }
    }
    .p_image_one {
        @include bp-xs {
            background-position: 75% center;
        }
        @include bp-sm {
            background-position: 75% center;
        }
        @include bp-md {
            background-position: center center;
        }
        @include bp-lg {
            background-position: center center;
        }
    }
    img {
        display: block;
        position: absolute;
        width: 100%;
        max-width: 1000%;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        z-index: 400;
        top: 0%;
        left: 0%;
        transform: translate(0%, 0%);
        margin-left: 0%;
        @include bp-xs {
            min-height: 200%;
            width: auto;
            margin-left: -100%;
        }
        @include bp-sm {
            width: auto;
            min-height: 200%;
            margin-left: -100%;
        }
        @include bp-md {
            width: 100%;
            height: auto;
            min-height: 100%;
            margin-left: 0%;
        }
        @include bp-lg {
            height: auto;
        }
    }
    .parallax-text {
        font-family: $font-family-base;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        text-align: center;
        left: 0px;
        right: 0px;
        display: inline-block;
        z-index: 400;
        transition: all 5000ms ease-in-out 0s;
        font-weight: 500;
        color: #ffffff;
        text-shadow: 1px 1px 3px rgba(42, 48, 51, 0);
        //text-transform: uppercase;
        margin-top: 20px;
        font-size: 36px;
    }
    .parallax-link {
        position: absolute;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        bottom: 25px;
        z-index: 400;
        font-family: $font-family-base;
        font-size: 22px;
        line-height: 22px;
        padding: 1em 2em 1em 2em;
        font-weight: 200;
        text-transform: uppercase;
        border: 1px solid rgba(255, 255, 255, .9);
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0);
        left: 50%;
        transform: translate(-50%, 0%);
    }
    .parallax-video {
        display: block;
        position: absolute;
        width: 100%;
        max-width: 1000%;
        height: 200%;
        min-width: 100%;
        min-height: 100%;
        z-index: 400;
        top: 0%;
        left: 0%;
        transform: translate(0%, 0%);
        margin-left: 0%;
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0);
        video {
            background-color: #fff;
            background-color: rgba(255, 255, 255, 0);
            position: relative;
            z-index: 405;
            width: 100%;
            min-width: 100%;
            min-height: 100%;
            max-width: 1000%;
            height: auto;
            object-fit: cover;
            @include bp-xs {
                display: none;
            }
            @include bp-sm {
                display: none;
            }
            @include bp-md {
                display: block;
                width: auto;
                height: 100%;
                min-height: 100%;
                margin-left: 0%;
            }
            @include bp-lg {
                display: block;
                width: auto;
                height: 100%;
                min-height: 100%;
                margin-left: 0%;
            }
        }
        .p_image {
            display: block;
            position: absolute;
            width: 100%;
            max-width: 1000%;
            height: 100%;
            min-width: 100%;
            min-height: 100%;
            z-index: 401;
            top: 0%;
            left: 0%;
            transform: translate(0%, 0%);
            margin-left: 0%;
            @include bp-xs {
                display: block;
            }
            @include bp-sm {
                display: block;
            }
            @include bp-md {
                display: none;
            }
            @include bp-lg {
                display: none;
            }
        }
    }
}