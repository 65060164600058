/*********************************************
	LOAD Boilerplayer min
*********************************************/

* {
    margin: 0px;
    padding: 0px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
small {
    display: block
}

audio,
canvas,
video {
    display: inline-block
}

img,
object,
embed,
video {
    max-width: 100%;
}

[hidden],
audio:not([controls]) {
    display: none
}

html {
    font-size: 100%;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

body {
    margin: 0;
    font-size: 13px;
    line-height: 1.231;
}

body,
button,
input,
select,
textarea {
    font-family: sans-serif;
    color: #222;
    outline: 0!important;
    outline: none!important;
}

a,
a:active,
a:hover,
a:focus,
a:-webkit-any-link {
    outline: none;
    outline: 0!important;
    outline: none!important;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-shadow: 0 0 0 transparent;
}

a {
    color: #00e
}

a:visited {
    color: #551a8b
}

a:hover {
    color: #06e
}

a:focus {
    outline: 0!important;
    outline: none!important;
}

a:active,
a:hover {
    outline: 0!important;
    outline: none!important;
}

*:focus {
    outline: 0!important;
    outline: none!important;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
a:focus {
    outline: 0!important;
    outline: none!important;
}

abbr[title] {
    border-bottom: 1px dotted
}

b,
strong {
    font-weight: 700
}

blockquote {
    margin: 1em 40px
}

dfn {
    font-style: italic
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0
}

ins {
    background: #ff9;
    color: #000;
    text-decoration: none
}

mark {
    background: #ff0;
    color: #000;
    font-style: italic;
    font-weight: 700
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word
}

q {
    quotes: none
}

q:after,
q:before {
    content: "";
    content: none
}

small {
    font-size: 85%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

ol,
ul {
    margin: 1em 0;
    padding: 0 0 0 40px
}

dd {
    margin: 0 0 0 40px
}

nav ol,
nav ul {
    list-style: none;
    margin: 0;
    padding: 0
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle
}

svg:not(:root) {
    overflow: hidden
}

figure,
form {
    margin: 0
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0
}

label {
    cursor: pointer
}

legend {
    border: 0;
    padding: 0
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline
}

button,
input {
    line-height: normal
}

button,
input[type=button],
input[type=reset],
input[type=submit] {
    cursor: pointer;
    -webkit-appearance: button
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box
}

input[type=search] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

button:focus {
    outline: 0;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical
}

input:invalid,
textarea:invalid {
    background-color: #f0dddd
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td {
    vertical-align: top
}

.ir {
    display: block;
    border: 0;
    text-indent: -999em;
    overflow: hidden;
    background-color: transparent;
    background-repeat: no-repeat;
    text-align: left;
    direction: ltr
}

.ir br {
    display: none
}

.hidden {
    display: none!important;
    visibility: hidden
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto
}

.invisible {
    visibility: hidden
}

.clearfix:after,
.clearfix:before {
    content: "";
    display: table
}

.clearfix:after {
    clear: both
}

.clearfix {
    zoom: 1
}

@media print {
    * {
        background: 0 0!important;
        color: #000!important;
        text-shadow: none!important;
        filter: none!important;
        -ms-filter: none!important
    }
    a,
    a:visited {
        text-decoration: underline
    }
    a[href]:after {
        content: " (" attr(href) ")"
    }
    abbr[title]:after {
        content: " (" attr(title) ")"
    }
    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: ""
    }
    blockquote,
    pre {
        border: 1px solid #999;
        page-break-inside: avoid
    }
    thead {
        display: table-header-group
    }
    img,
    tr {
        page-break-inside: avoid
    }
    img {
        max-width: 100%!important
    }
    @page {
        margin: .5cm
    }
    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }
    h2,
    h3 {
        page-break-after: avoid
    }
}


/** Fix for IE scrollbar covering page**/

@-ms-viewport {
    width: auto!important;
}