.social_wrap {
    display: inline-block;
    clear: none;
    text-align: left;
    margin: 0px;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 10px;
}

.socialbox {
    width: auto;
    margin-top: 10px;
    margin-bottom: 5px;
    display: inline-block;
    padding-right: 0px;
    padding-left: 0px;
    @include bp-sm {
        margin-top: 10px;
    }
    @include bp-md {
        margin-top: 10px;
    }
    @include bp-lg {
        margin-top: 10px;
    }
    @include bp-xl {}
    @include bp-xxl {}
    @include bp-xxxl {}
}

.social_slot {
    float: left;
    display: inline-block;
    height: 27px;
    width: 27px;
    padding: 0px;
    background-color: $primary-grey;
    @include border-radius(0%);
    position: relative;
    margin-top: 0px;
    margin-right: 8px;
    margin-bottom: 8px;
    margin-left: 0px;
    &:hover {
        background-color: $primary-accent-color;
    }
}

.social_slot img {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0px;
    padding: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    /* IE 9 */
    -webkit-transform: translate(-50%, -50%);
    /* Safari and Chrome */
    -o-transform: translate(-50%, -50%);
    /* Opera */
    -moz-transform: translate(-50%, -50%);
    /* Firefox */
}


/*
.social_slot.facebook {
	background-color: #3c5b9b;
}
.social_slot.twitter {
	background-color: #2daae1;
}
.social_slot.linkedin {
	background-color: #0173b2;
}
.social_slot.google {
	background-color: #DC4E41;
}
.social_slot.youtube {
	background-color: #cd201f;
}
*/