/** Collapsible Panel**/

.collapsible,
.page_collapsible {
    padding: 10px;
    height: 20px;
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    font-size: 1.1em;
    cursor: pointer;
    display: inline-block;
    clear: none;
    margin-top: 0px;
    margin-right: 0;
    margin-bottom: 10px;
    margin-left: 0;
    min-width: 50%;
    border-top-width: 0px;
    border-top-style: solid;
    border-top-color: #666;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #666;
    @include bp-sm {
        padding: 0px;
        min-width: 100%;
    }
    @include bp-md {
        padding: 5px;
        min-width: 50%;
    }
    @include bp-lg {
        padding: 5px;
        min-width: 50%;
    }
    @include bp-xl {}
    @include bp-xxl {}
    @include bp-xxxl {}
}

.collapsible:hover,
.page_collapsible:hover {
    background-color: #f2f0e4;
}

.optionsbox .quote_text .collapsible {
    font-size: 1em;
}

.collapsible_container {
    padding-top: 15px;
    padding-right: 0px;
    padding-bottom: 15px;
    padding-left: 0px;
}

.collapsible_container:after {
    content: '';
    display: block;
    clear: both;
}

.collapse-open {
    margin-bottom: 0px;
    background-color: #f2f0e4;
    color: #000;
}

.collapse-open span {
    display: block;
    float: right;
    padding: 10px;
}

.collapse-open {
    background-image: url(#{$assets_path}/dist/img/icons/minus.png);
    background-repeat: no-repeat;
    background-position: right center;
}

.collapse-close {
    background-image: url(#{$assets_path}/dist/img/icons/plus.png);
    background-repeat: no-repeat;
    background-position: right center;
}