/*********************************************
	Homepage Styles
*********************************************/

body.home {
    p {
        color: #000;
        font-size: 18px;
        line-height: 20px;
    }
}