@charset "UTF-8";
/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #868e96; }

code,
kbd,
pre,
samp {
  font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f8f9fa;
  border-radius: 0.25rem; }
  a > code {
    padding: 0;
    color: inherit;
    background-color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  max-width: 0px; }
  @media (min-width: 481px) {
    .container {
      max-width: 481px; } }
  @media (min-width: 769px) {
    .container {
      max-width: 769px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 992px; } }
  @media (min-width: 1024px) {
    .container {
      max-width: 1024px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%; }

.col-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.col-4 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-5 {
  flex: 0 0 20.83333%;
  max-width: 20.83333%; }

.col-6 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-7 {
  flex: 0 0 29.16667%;
  max-width: 29.16667%; }

.col-8 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.col-10 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-11 {
  flex: 0 0 45.83333%;
  max-width: 45.83333%; }

.col-12 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-13 {
  flex: 0 0 54.16667%;
  max-width: 54.16667%; }

.col-14 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.col-16 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-17 {
  flex: 0 0 70.83333%;
  max-width: 70.83333%; }

.col-18 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-19 {
  flex: 0 0 79.16667%;
  max-width: 79.16667%; }

.col-20 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.col-22 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-23 {
  flex: 0 0 95.83333%;
  max-width: 95.83333%; }

.col-24 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.order-13 {
  order: 13; }

.order-14 {
  order: 14; }

.order-15 {
  order: 15; }

.order-16 {
  order: 16; }

.order-17 {
  order: 17; }

.order-18 {
  order: 18; }

.order-19 {
  order: 19; }

.order-20 {
  order: 20; }

.order-21 {
  order: 21; }

.order-22 {
  order: 22; }

.order-23 {
  order: 23; }

.order-24 {
  order: 24; }

@media (min-width: 481px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; } }

@media (min-width: 769px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; } }

@media (min-width: 1024px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .order-xl-17 {
    order: 17; }
  .order-xl-18 {
    order: 18; }
  .order-xl-19 {
    order: 19; }
  .order-xl-20 {
    order: 20; }
  .order-xl-21 {
    order: 21; }
  .order-xl-22 {
    order: 22; }
  .order-xl-23 {
    order: 23; }
  .order-xl-24 {
    order: 24; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e9ecef; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e9ecef; }
  .table tbody + tbody {
    border-top: 2px solid #e9ecef; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e9ecef; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e9ecef; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2; }

.table-hover .table-secondary:hover {
  background-color: #cfd2d6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cfd2d6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.thead-inverse th {
  color: #fff;
  background-color: #212529; }

.thead-default th {
  color: #495057;
  background-color: #e9ecef; }

.table-inverse {
  color: #fff;
  background-color: #212529; }
  .table-inverse th,
  .table-inverse td,
  .table-inverse thead th {
    border-color: #32383e; }
  .table-inverse.table-bordered {
    border: 0; }
  .table-inverse.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-inverse.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 991px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive.table-bordered {
      border: 0; } }

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: none; }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block; }

.col-form-label {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  margin-bottom: 0; }

.col-form-label-lg {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem - 1px * 2);
  padding-bottom: calc(0.25rem - 1px * 2);
  font-size: 0.875rem; }

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem; }

.form-control-plaintext {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .form-control-plaintext.input-group-addon,
  .input-group-sm > .input-group-btn > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .form-control-plaintext.input-group-addon,
  .input-group-lg > .input-group-btn > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem; }
  .form-check.disabled .form-check-label {
    color: #868e96; }

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0; }

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem; }

.form-check-inline {
  display: inline-block; }
  .form-check-inline .form-check-label {
    vertical-align: middle; }
  .form-check-inline + .form-check-inline {
    margin-left: 0.75rem; }

.invalid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .invalid-feedback,
  .was-validated .form-control:valid ~ .invalid-tooltip, .form-control.is-valid ~ .invalid-feedback,
  .form-control.is-valid ~ .invalid-tooltip, .was-validated
  .custom-select:valid ~ .invalid-feedback,
  .was-validated
  .custom-select:valid ~ .invalid-tooltip,
  .custom-select.is-valid ~ .invalid-feedback,
  .custom-select.is-valid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 481px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-control-label {
      margin-bottom: 0;
      vertical-align: middle; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      margin-top: 0;
      margin-bottom: 0; }
    .form-inline .form-check-label {
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0; }
    .form-inline .custom-control-indicator {
      position: static;
      display: inline-block;
      margin-right: 0.25rem;
      vertical-align: text-bottom; }
    .form-inline .has-feedback .form-control-feedback {
      top: 0; } }

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:focus, .btn:hover {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: .65; }
  .btn:active, .btn.active {
    background-image: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #0069d9;
    background-image: none;
    border-color: #0062cc; }

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #727b84;
    border-color: #6c757d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #868e96;
    border-color: #868e96; }
  .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #727b84;
    background-image: none;
    border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    background-color: #218838;
    background-image: none;
    border-color: #1e7e34; }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    background-color: #138496;
    background-image: none;
    border-color: #117a8b; }

.btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #111;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    background-color: #e0a800;
    background-image: none;
    border-color: #d39e00; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    background-color: #c82333;
    background-image: none;
    border-color: #bd2130; }

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #111;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    background-color: #e2e6ea;
    background-image: none;
    border-color: #dae0e5; }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    background-color: #23272b;
    background-image: none;
    border-color: #1d2124; }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:active, .btn-outline-primary.active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #868e96;
    background-color: transparent; }
  .btn-outline-secondary:active, .btn-outline-secondary.active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:active, .btn-outline-success.active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:active, .btn-outline-info.active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:active, .btn-outline-warning.active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:active, .btn-outline-danger.active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:active, .btn-outline-light.active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:active, .btn-outline-dark.active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }

.btn-link {
  font-weight: normal;
  color: #007bff;
  border-radius: 0; }
  .btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
    background-color: transparent; }
  .btn-link, .btn-link:focus, .btn-link:active {
    border-color: transparent;
    box-shadow: none; }
  .btn-link:hover {
    border-color: transparent; }
  .btn-link:focus, .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent; }
  .btn-link:disabled {
    color: #868e96; }
    .btn-link:disabled:focus, .btn-link:disabled:hover {
      text-decoration: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0; }
  .dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #868e96;
    background-color: transparent; }

.show > a {
  outline: 0; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto;
    margin-bottom: 0; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 2; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 2; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0; }

.btn-group > .btn:first-child {
  margin-left: 0; }
  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .btn + .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0; }

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  width: 100%; }
  .input-group .form-control {
    position: relative;
    z-index: 2;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
      z-index: 3; }

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: flex;
  align-items: center; }
  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0; }

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
  vertical-align: middle; }

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .input-group-addon.form-control-sm,
  .input-group-sm > .input-group-addon,
  .input-group-sm > .input-group-btn > .input-group-addon.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem; }
  .input-group-addon.form-control-lg,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .input-group-addon.btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem; }
  .input-group-addon input[type="radio"],
  .input-group-addon input[type="checkbox"] {
    margin-top: 0; }

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group-addon:not(:last-child) {
  border-right: 0; }

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0; }

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }
  .input-group-btn > .btn {
    position: relative;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15); }
    .input-group-btn > .btn + .btn {
      margin-left: -1px; }
    .input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover {
      z-index: 3; }
  .input-group-btn:not(:last-child) > .btn,
  .input-group-btn:not(:last-child) > .btn-group {
    margin-right: -1px; }
  .input-group-btn:not(:first-child) > .btn,
  .input-group-btn:not(:first-child) > .btn-group {
    z-index: 2;
    margin-left: -1px; }
    .input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover,
    .input-group-btn:not(:first-child) > .btn-group:focus,
    .input-group-btn:not(:first-child) > .btn-group:active,
    .input-group-btn:not(:first-child) > .btn-group:hover {
      z-index: 3; }

.custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-indicator {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-indicator {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #007bff; }
  .custom-control-input:active ~ .custom-control-indicator {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-indicator {
    background-color: #e9ecef; }
  .custom-control-input:disabled ~ .custom-control-description {
    color: #868e96; }

.custom-control-indicator {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-indicator {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #007bff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-indicator {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-controls-stacked {
  display: flex;
  flex-direction: column; }
  .custom-controls-stacked .custom-control {
    margin-bottom: 0.25rem; }
    .custom-controls-stacked .custom-control + .custom-control {
      margin-left: 0; }

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.25;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-clip: padding-box;
  background-size: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: none; }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select:disabled {
    color: #868e96;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #007bff; }

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: calc(2.25rem + 2px);
  padding: 0.5rem 0.75rem;
  overflow: hidden;
  line-height: 1.25;
  color: #495057;
  pointer-events: none;
  user-select: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .custom-file-control:lang(en):empty::after {
    content: "Choose file..."; }
  .custom-file-control::before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 6;
    display: block;
    height: calc(2.25rem + 2px);
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    color: #495057;
    background-color: #e9ecef;
    border-left: 1px solid rgba(0, 0, 0, 0.15); }
  .custom-file-control:lang(en)::before {
    content: "Browse"; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled {
    color: #868e96; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
      border-color: #e9ecef #e9ecef #ddd; }
    .nav-tabs .nav-link.disabled {
      color: #868e96;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #ddd #ddd #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }
  .nav-pills .nav-link.active,
  .show > .nav-pills .nav-link {
    color: #fff;
    background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 480px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 481px) {
  .navbar-expand-sm {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 768px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 769px) {
  .navbar-expand-md {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1023px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1024px) {
  .navbar-expand-xl {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }

.navbar-dark .navbar-brand {
  color: white; }
  .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: white; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: white; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

@media (min-width: 481px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: 15px;
      margin-left: 15px; } }

@media (min-width: 481px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group .card {
      flex: 1 0 0%; }
      .card-group .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group .card:first-child .card-img-top {
          border-top-right-radius: 0; }
        .card-group .card:first-child .card-img-bottom {
          border-bottom-right-radius: 0; }
      .card-group .card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group .card:last-child .card-img-top {
          border-top-left-radius: 0; }
        .card-group .card:last-child .card-img-bottom {
          border-bottom-left-radius: 0; }
      .card-group .card:not(:first-child):not(:last-child) {
        border-radius: 0; }
        .card-group .card:not(:first-child):not(:last-child) .card-img-top,
        .card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
          border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 481px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }
  .breadcrumb::after {
    display: block;
    clear: both;
    content: ""; }

.breadcrumb-item {
  float: left; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #868e96;
    content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #868e96; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  background-color: #fff;
  border-color: #ddd; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #ddd; }
  .page-link:focus, .page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #ddd; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  .badge-primary[href]:focus, .badge-primary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #868e96; }
  .badge-secondary[href]:focus, .badge-secondary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #6c757d; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:focus, .badge-success[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:focus, .badge-info[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #111;
  background-color: #ffc107; }
  .badge-warning[href]:focus, .badge-warning[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  .badge-danger[href]:focus, .badge-danger[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #111;
  background-color: #f8f9fa; }
  .badge-light[href]:focus, .badge-light[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:focus, .badge-dark[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 481px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: bold; }

.alert-dismissible .close {
  position: relative;
  top: -0.75rem;
  right: -1.25rem;
  padding: 0.75rem 1.25rem;
  color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #464a4e;
  background-color: #e7e8ea;
  border-color: #dddfe2; }
  .alert-secondary hr {
    border-top-color: #cfd2d6; }
  .alert-secondary .alert-link {
    color: #2e3133; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #007bff; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:focus, .list-group-item-action:hover {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:focus, .list-group-item:hover {
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #868e96;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }

a.list-group-item-primary,
button.list-group-item-primary {
  color: #004085; }
  a.list-group-item-primary:focus, a.list-group-item-primary:hover,
  button.list-group-item-primary:focus,
  button.list-group-item-primary:hover {
    color: #004085;
    background-color: #9fcdff; }
  a.list-group-item-primary.active,
  button.list-group-item-primary.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #464a4e;
  background-color: #dddfe2; }

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #464a4e; }
  a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
  button.list-group-item-secondary:focus,
  button.list-group-item-secondary:hover {
    color: #464a4e;
    background-color: #cfd2d6; }
  a.list-group-item-secondary.active,
  button.list-group-item-secondary.active {
    color: #fff;
    background-color: #464a4e;
    border-color: #464a4e; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }

a.list-group-item-success,
button.list-group-item-success {
  color: #155724; }
  a.list-group-item-success:focus, a.list-group-item-success:hover,
  button.list-group-item-success:focus,
  button.list-group-item-success:hover {
    color: #155724;
    background-color: #b1dfbb; }
  a.list-group-item-success.active,
  button.list-group-item-success.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }

a.list-group-item-info,
button.list-group-item-info {
  color: #0c5460; }
  a.list-group-item-info:focus, a.list-group-item-info:hover,
  button.list-group-item-info:focus,
  button.list-group-item-info:hover {
    color: #0c5460;
    background-color: #abdde5; }
  a.list-group-item-info.active,
  button.list-group-item-info.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #856404; }
  a.list-group-item-warning:focus, a.list-group-item-warning:hover,
  button.list-group-item-warning:focus,
  button.list-group-item-warning:hover {
    color: #856404;
    background-color: #ffe8a1; }
  a.list-group-item-warning.active,
  button.list-group-item-warning.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #721c24; }
  a.list-group-item-danger:focus, a.list-group-item-danger:hover,
  button.list-group-item-danger:focus,
  button.list-group-item-danger:hover {
    color: #721c24;
    background-color: #f1b0b7; }
  a.list-group-item-danger.active,
  button.list-group-item-danger.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }

a.list-group-item-light,
button.list-group-item-light {
  color: #818182; }
  a.list-group-item-light:focus, a.list-group-item-light:hover,
  button.list-group-item-light:focus,
  button.list-group-item-light:hover {
    color: #818182;
    background-color: #ececf6; }
  a.list-group-item-light.active,
  button.list-group-item-light.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }

a.list-group-item-dark,
button.list-group-item-dark {
  color: #1b1e21; }
  a.list-group-item-dark:focus, a.list-group-item-dark:hover,
  button.list-group-item-dark:focus,
  button.list-group-item-dark:hover {
    color: #1b1e21;
    background-color: #b9bbbe; }
  a.list-group-item-dark.active,
  button.list-group-item-dark.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:focus, .close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75; }

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef; }
  .modal-header .close {
    margin-left: auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 481px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 5px;
    height: 5px; }
  .tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^="top"] {
    padding: 5px 0; }
    .tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
      bottom: 0; }
    .tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
      margin-left: -3px;
      content: "";
      border-width: 5px 5px 0;
      border-top-color: #000; }
  .tooltip.bs-tooltip-right, .tooltip.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 5px; }
    .tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow {
      left: 0; }
    .tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
      margin-top: -3px;
      content: "";
      border-width: 5px 5px 5px 0;
      border-right-color: #000; }
  .tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 5px 0; }
    .tooltip.bs-tooltip-bottom .arrow, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow {
      top: 0; }
    .tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      margin-left: -3px;
      content: "";
      border-width: 0 5px 5px;
      border-bottom-color: #000; }
  .tooltip.bs-tooltip-left, .tooltip.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 5px; }
    .tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow {
      right: 0; }
    .tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
      right: 0;
      margin-top: -3px;
      content: "";
      border-width: 5px 0 5px 5px;
      border-left-color: #000; }
  .tooltip .arrow::before {
    position: absolute;
    border-color: transparent;
    border-style: solid; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  padding: 1px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 5px; }
  .popover .arrow::before,
  .popover .arrow::after {
    position: absolute;
    display: block;
    border-color: transparent;
    border-style: solid; }
  .popover .arrow::before {
    content: "";
    border-width: 11px; }
  .popover .arrow::after {
    content: "";
    border-width: 11px; }
  .popover.bs-popover-top, .popover.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 10px; }
    .popover.bs-popover-top .arrow, .popover.bs-popover-auto[x-placement^="top"] .arrow {
      bottom: 0; }
    .popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before,
    .popover.bs-popover-top .arrow::after, .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
      border-bottom-width: 0; }
    .popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before {
      bottom: -11px;
      margin-left: -6px;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .popover.bs-popover-top .arrow::after, .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
      bottom: -10px;
      margin-left: -6px;
      border-top-color: #fff; }
  .popover.bs-popover-right, .popover.bs-popover-auto[x-placement^="right"] {
    margin-left: 10px; }
    .popover.bs-popover-right .arrow, .popover.bs-popover-auto[x-placement^="right"] .arrow {
      left: 0; }
    .popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before,
    .popover.bs-popover-right .arrow::after, .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
      margin-top: -8px;
      border-left-width: 0; }
    .popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before {
      left: -11px;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .popover.bs-popover-right .arrow::after, .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
      left: -10px;
      border-right-color: #fff; }
  .popover.bs-popover-bottom, .popover.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 10px; }
    .popover.bs-popover-bottom .arrow, .popover.bs-popover-auto[x-placement^="bottom"] .arrow {
      top: 0; }
    .popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
    .popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      margin-left: -7px;
      border-top-width: 0; }
    .popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before {
      top: -11px;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      top: -10px;
      border-bottom-color: #fff; }
    .popover.bs-popover-bottom .popover-header::before, .popover.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 20px;
      margin-left: -10px;
      content: "";
      border-bottom: 1px solid #f7f7f7; }
  .popover.bs-popover-left, .popover.bs-popover-auto[x-placement^="left"] {
    margin-right: 10px; }
    .popover.bs-popover-left .arrow, .popover.bs-popover-auto[x-placement^="left"] .arrow {
      right: 0; }
    .popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before,
    .popover.bs-popover-left .arrow::after, .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
      margin-top: -8px;
      border-right-width: 0; }
    .popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before {
      right: -11px;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .popover.bs-popover-left .arrow::after, .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
      right: -10px;
      border-left-color: #fff; }

.popover-header {
  padding: 8px 14px;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 9px 14px;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:focus, .carousel-control-prev:hover,
  .carousel-control-next:focus,
  .carousel-control-next:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #868e96 !important; }

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50%; }

.rounded-0 {
  border-radius: 0; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 481px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 769px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1024px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 481px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 769px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1024px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 481px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 769px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1024px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 481px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 769px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1024px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 481px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 769px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1024px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: normal !important; }

.font-weight-bold {
  font-weight: bold !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important; }

.text-secondary {
  color: #868e96 !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important; }

.text-muted {
  color: #868e96 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  max-width: 0px; }
  @media (min-width: 481px) {
    .container {
      max-width: 481px; } }
  @media (min-width: 769px) {
    .container {
      max-width: 769px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 992px; } }
  @media (min-width: 1024px) {
    .container {
      max-width: 1024px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%; }

.col-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.col-4 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-5 {
  flex: 0 0 20.83333%;
  max-width: 20.83333%; }

.col-6 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-7 {
  flex: 0 0 29.16667%;
  max-width: 29.16667%; }

.col-8 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.col-10 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-11 {
  flex: 0 0 45.83333%;
  max-width: 45.83333%; }

.col-12 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-13 {
  flex: 0 0 54.16667%;
  max-width: 54.16667%; }

.col-14 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.col-16 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-17 {
  flex: 0 0 70.83333%;
  max-width: 70.83333%; }

.col-18 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-19 {
  flex: 0 0 79.16667%;
  max-width: 79.16667%; }

.col-20 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.col-22 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-23 {
  flex: 0 0 95.83333%;
  max-width: 95.83333%; }

.col-24 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.order-13 {
  order: 13; }

.order-14 {
  order: 14; }

.order-15 {
  order: 15; }

.order-16 {
  order: 16; }

.order-17 {
  order: 17; }

.order-18 {
  order: 18; }

.order-19 {
  order: 19; }

.order-20 {
  order: 20; }

.order-21 {
  order: 21; }

.order-22 {
  order: 22; }

.order-23 {
  order: 23; }

.order-24 {
  order: 24; }

@media (min-width: 481px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; } }

@media (min-width: 769px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; } }

@media (min-width: 1024px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .order-xl-17 {
    order: 17; }
  .order-xl-18 {
    order: 18; }
  .order-xl-19 {
    order: 19; }
  .order-xl-20 {
    order: 20; }
  .order-xl-21 {
    order: 21; }
  .order-xl-22 {
    order: 22; }
  .order-xl-23 {
    order: 23; }
  .order-xl-24 {
    order: 24; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 481px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 769px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1024px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

/*   
@include bp-xs {}
@include bp-sm {}
@include bp-md {}
@include bp-lg {}
@include bp-xl {}
@include bp-xxl {}
*/
/*   
@include bp-xs-max {}
@include bp-sm-max {}
@include bp-md-max {}
@include bp-lg-max {}
@include bp-xl-max {}
@include bp-xxl-max {}
*/
/*********************************************
	LOAD Boilerplayer min
*********************************************/
* {
  margin: 0px;
  padding: 0px; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
small {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

img,
object,
embed,
video {
  max-width: 100%; }

[hidden],
audio:not([controls]) {
  display: none; }

html {
  font-size: 100%;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0;
  font-size: 13px;
  line-height: 1.231; }

body,
button,
input,
select,
textarea {
  font-family: sans-serif;
  color: #222;
  outline: 0 !important;
  outline: none !important; }

a,
a:active,
a:hover,
a:focus,
a:-webkit-any-link {
  outline: none;
  outline: 0 !important;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 0 0 transparent; }

a {
  color: #00e; }

a:visited {
  color: #551a8b; }

a:hover {
  color: #06e; }

a:focus {
  outline: 0 !important;
  outline: none !important; }

a:active,
a:hover {
  outline: 0 !important;
  outline: none !important; }

*:focus {
  outline: 0 !important;
  outline: none !important; }

input:focus,
select:focus,
textarea:focus,
button:focus,
a:focus {
  outline: 0 !important;
  outline: none !important; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: 700; }

blockquote {
  margin: 1em 40px; }

dfn {
  font-style: italic; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

ins {
  background: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: 700; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:after,
q:before {
  content: "";
  content: none; }

small {
  font-size: 85%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

ol,
ul {
  margin: 1em 0;
  padding: 0 0 0 40px; }

dd {
  margin: 0 0 0 40px; }

nav ol,
nav ul {
  list-style: none;
  margin: 0;
  padding: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

figure,
form {
  margin: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

label {
  cursor: pointer; }

legend {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline; }

button,
input {
  line-height: normal; }

button,
input[type=button],
input[type=reset],
input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button; }

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box; }

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

button:focus {
  outline: 0; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

input:invalid,
textarea:invalid {
  background-color: #f0dddd; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr; }

.ir br {
  display: none; }

.hidden {
  display: none !important;
  visibility: hidden; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:after,
.clearfix:before {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

@media print {
  * {
    background: 0 0 !important;
    color: #000 !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }
  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  img,
  tr {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: .5cm; }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

/** Fix for IE scrollbar covering page**/
@-ms-viewport {
  width: auto !important; }

/*********************************************
	browsehappy
*********************************************/
.browsehappy {
  display: block;
  position: relative;
  z-index: 999999;
  width: 100%;
  height: 200px;
  background-color: #f2dede;
  margin: 0px;
  padding: 20px;
  font-size: 22px;
  line-height: 1.4em;
  color: #333;
  vertical-align: middle;
  text-align: center; }

/*********************************************
	CORE STYLES HERE
*********************************************/
html {
  background-color: #FFF; }

html,
body {
  width: 100%;
  display: block;
  margin: 0px;
  padding: 0px; }

body {
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
  background-color: #FFF;
  font-size: 14px;
  /*background-color: rgba(48, 74, 95, 0.8);*/
  /*color: #304a5f;*/
  color: #000; }

/*body {
    @include animation('-amp-start 8s steps(1, end) 0s 1 normal both');
}

@include keyframes(-amp-start) {
    from {
        visibility: hidden;
    }
    to {
        visibility: visible;
    }
}*/
.bodywrap {
  display: block;
  height: 100%;
  width: 100%;
  background-color: #FFF;
  margin-right: auto;
  margin-left: auto;
  position: relative; }

.sectionwrap {
  display: block;
  background-color: #FFF; }

/* JavaScript Turned Off */
.no-js #loader-wrapper {
  display: none !important; }

@font-face {
  font-family: "FrutigerLTStd";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Frutiger/FrutigerLTStd-Light.eot?") format("eot"), url("../fonts/Frutiger/FrutigerLTStd-Light.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-Light.woff2") format("woff2"), url("../fonts/Frutiger/FrutigerLTStd-Light.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-Light.svg#FrutigerLTStd") format("svg"), url("../fonts/Frutiger/FrutigerLTStd-Light.ttf") format("truetype"); }

@font-face {
  font-family: "FrutigerLTStd";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Frutiger/FrutigerLTStd-Roman.eot?") format("eot"), url("../fonts/Frutiger/FrutigerLTStd-Roman.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-Roman.woff2") format("woff2"), url("../fonts/Frutiger/FrutigerLTStd-Roman.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-Roman.svg#FrutigerLTStd") format("svg"), url("../fonts/Frutiger/FrutigerLTStd-Roman.ttf") format("truetype"); }

@font-face {
  font-family: "FrutigerLTStd";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Frutiger/FrutigerLTStd-Bold.eot?") format("eot"), url("../fonts/Frutiger/FrutigerLTStd-Bold.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-Bold.woff2") format("woff2"), url("../fonts/Frutiger/FrutigerLTStd-Bold.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-Bold.svg#FrutigerLTStd") format("svg"), url("../fonts/Frutiger/FrutigerLTStd-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "FrutigerLTStd";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/Frutiger/FrutigerLTStd-Black.eot?") format("eot"), url("../fonts/Frutiger/FrutigerLTStd-Black.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-Black.woff2") format("woff2"), url("../fonts/Frutiger/FrutigerLTStd-Black.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-Black.svg#FrutigerLTStd") format("svg"), url("../fonts/Frutiger/FrutigerLTStd-Black.ttf") format("truetype"); }

@font-face {
  font-family: "Frutiger Cond";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Frutiger/Frutiger-Cn.eot?") format("eot"), url("../fonts/Frutiger/Frutiger-Cn.woff") format("woff"), url("../fonts/Frutiger/Frutiger-Cn.woff2") format("woff2"), url("../fonts/Frutiger/Frutiger-Cn.woff") format("woff"), url("../fonts/Frutiger/Frutiger-Cn.svg#Frutiger_Cond") format("svg"), url("../fonts/Frutiger/Frutiger-Cn.ttf") format("truetype"); }

@font-face {
  font-family: "Frutiger Cond";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Frutiger/Frutiger-BoldCn.eot?") format("eot"), url("../fonts/Frutiger/Frutiger-BoldCn.woff") format("woff"), url("../fonts/Frutiger/Frutiger-BoldCn.woff2") format("woff2"), url("../fonts/Frutiger/Frutiger-BoldCn.woff") format("woff"), url("../fonts/Frutiger/Frutiger-BoldCn.svg#Frutiger_Cond") format("svg"), url("../fonts/Frutiger/Frutiger-BoldCn.ttf") format("truetype"); }

@font-face {
  font-family: "FrutigerLTStd Cond";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Frutiger/FrutigerLTStd-LightCn.eot?") format("eot"), url("../fonts/Frutiger/FrutigerLTStd-LightCn.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-LightCn.woff2") format("woff2"), url("../fonts/Frutiger/FrutigerLTStd-LightCn.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-LightCn.svg#FrutigerLTStd_Cond") format("svg"), url("../fonts/Frutiger/FrutigerLTStd-LightCn.ttf") format("truetype"); }

@font-face {
  font-family: "FrutigerLTStd Cond";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Frutiger/FrutigerLTStd-Cn.eot?") format("eot"), url("../fonts/Frutiger/FrutigerLTStd-Cn.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-Cn.woff2") format("woff2"), url("../fonts/Frutiger/FrutigerLTStd-Cn.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-Cn.svg#FrutigerLTStd_Cond") format("svg"), url("../fonts/Frutiger/FrutigerLTStd-Cn.ttf") format("truetype"); }

@font-face {
  font-family: "FrutigerLTStd Cond";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Frutiger/FrutigerLTStd-BoldCn.eot?") format("eot"), url("../fonts/Frutiger/FrutigerLTStd-BoldCn.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-BoldCn.woff2") format("woff2"), url("../fonts/Frutiger/FrutigerLTStd-BoldCn.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-BoldCn.svg#FrutigerLTStd_Cond") format("svg"), url("../fonts/Frutiger/FrutigerLTStd-BoldCn.ttf") format("truetype"); }

@font-face {
  font-family: "FrutigerLTStd Cond";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/Frutiger/FrutigerLTStd-BlackCn.eot?") format("eot"), url("../fonts/Frutiger/FrutigerLTStd-BlackCn.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-BlackCn.woff2") format("woff2"), url("../fonts/Frutiger/FrutigerLTStd-BlackCn.woff") format("woff"), url("../fonts/Frutiger/FrutigerLTStd-BlackCn.svg#FrutigerLTStd_Cond") format("svg"), url("../fonts/Frutiger/FrutigerLTStd-BlackCn.ttf") format("truetype"); }

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal;
  font-style: normal; }

/*********************************************
	Link Styles
*********************************************/
a {
  outline: 0; }

a:active {
  text-decoration: none;
  outline: 0; }

a:link {
  color: #ef8522;
  text-decoration: none;
  outline: 0; }

a:visited {
  color: #ef8522;
  text-decoration: none;
  outline: 0; }

a:hover {
  color: #ef8522;
  text-decoration: none;
  outline: 0; }

/*********************************************
	Headings
*********************************************/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0px;
  padding: 0px;
  font-weight: inherit;
  font-size: inherit; }

h1 {
  font-size: 36px; }

h2 {
  font-size: 32px; }

h3 {
  font-size: 28px; }

h4 {
  font-size: 24px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 16px; }

/*********************************************
	Fonts
*********************************************/
.font-s-8 {
  font-size: 8px; }

.font-s-10 {
  font-size: 10px; }

.font-s-12 {
  font-size: 12px; }

.font-s-14 {
  font-size: 14px; }

.font-s-16 {
  font-size: 16px; }

.font-s-18 {
  font-size: 18px; }

.font-s-20 {
  font-size: 20px; }

.font-s-22 {
  font-size: 22px; }

.font-s-24 {
  font-size: 24px; }

.font-s-26 {
  font-size: 26px; }

.font-s-28 {
  font-size: 28px; }

.font-s-30 {
  font-size: 30px; }

.font-s-32 {
  font-size: 32px; }

.font-s-34 {
  font-size: 34px; }

.font-s-36 {
  font-size: 36px; }

.font-s-38 {
  font-size: 38px; }

.font-s-40 {
  font-size: 40px; }

.font-s-42 {
  font-size: 42px; }

.font-s-44 {
  font-size: 44px; }

.font-s-46 {
  font-size: 46px; }

.font-s-48 {
  font-size: 48px; }

.font-s-60 {
  font-size: 60px; }

.font-w-100 {
  font-weight: 100; }

.font-w-200 {
  font-weight: 200; }

.font-w-300 {
  font-weight: 300; }

.font-w-400 {
  font-weight: 400; }

.font-w-500 {
  font-weight: 500; }

.font-w-600 {
  font-weight: 600; }

.font-w-700 {
  font-weight: 700; }

.font-w-800 {
  font-weight: 800; }

.align-left {
  text-align: left; }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }

.font-p {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  line-height: 2em;
  padding-top: 0.5em;
  padding-bottom: 1em;
  font-weight: normal;
  font-style: normal; }

.font-pad-small {
  padding-top: 0.5em;
  padding-bottom: 0.5em; }

.font-pad-medium {
  padding-top: 0.5em;
  padding-bottom: 0.7em; }

.font-title {
  font-family: "FrutigerLTStd Cond", "FrutigerLTStd", Arial, sans-serif;
  color: #72767c;
  text-transform: uppercase;
  font-weight: bold;
  font-style: normal;
  padding-top: 0em;
  padding-bottom: 0.7em;
  letter-spacing: 0.1em; }
  @media only screen and (max-width: 0px) {
    .font-title {
      font-size: 1em; } }
  @media only screen and (max-width: 481px) {
    .font-title {
      font-size: 1.5em; } }
  @media only screen and (max-width: 769px) {
    .font-title {
      font-size: 2em; } }

/*********************************************
	Set Font Colors
*********************************************/
.white {
  color: #FFFFFF !important; }

.black {
  color: #000000 !important; }

.grey {
  color: #b7b7b7 !important; }

.red {
  color: #971b1e !important; }

.red-dark {
  color: #580d08 !important; }

.brown {
  color: #3a2007 !important; }

.orange {
  color: #db8524 !important; }

.blue {
  color: #004b8d !important; }

/*********************************************
	Text Classes
*********************************************/
p {
  margin-top: 4px;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px; }

strong {
  font-weight: 700; }

.bold {
  font-weight: bold; }

.left {
  text-align: left !important; }

.right {
  text-align: right !important; }

.center {
  text-align: center !important; }

.uppercase {
  text-transform: uppercase; }

blockquote {
  background: transparent;
  border-left: 0px solid #000;
  quotes: "“" "”" "‘" "’";
  margin-top: 1em;
  margin-right: 10px;
  margin-bottom: 1.5em;
  margin-left: 10px;
  padding-top: 0.5em;
  padding-right: 10px;
  padding-bottom: 0.5em;
  padding-left: 10px;
  font-size: 2.5em;
  line-height: 1.1em; }

blockquote:before {
  color: #000;
  content: ”\201C”;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em; }

blockquote:after {
  display: block;
  color: #000;
  content: ”\201D”;
  content: close-quote;
  font-size: 0em;
  line-height: 0.1em;
  margin-left: 0.25em;
  vertical-align: -2em; }

blockquote p {
  display: inline; }

blockquote em {
  display: inline;
  font-size: 0.5em;
  color: #000000;
  clear: both; }

/*********************************************
	Text Classes
*********************************************/
.title {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  display: block;
  text-align: center;
  font-size: 3em;
  font-weight: 300;
  line-height: 1.5em;
  text-transform: uppercase;
  color: #007eb3;
  margin-bottom: 40px;
  letter-spacing: 0.25em; }
  .title span {
    display: inline-block; }
  @media only screen and (min-width: 0px) {
    .title {
      font-size: 2em;
      padding-right: 20px;
      padding-left: 20px; } }
  @media only screen and (min-width: 481px) {
    .title {
      font-size: 2.5em;
      padding-right: 20px;
      padding-left: 20px; } }
  @media only screen and (min-width: 769px) {
    .title {
      font-size: 3em;
      padding-right: 20px;
      padding-left: 20px; } }

.title_alt span {
  display: inline-block; }
  .title_alt span:after {
    content: '';
    display: none;
    clear: both;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 2.5em;
    height: 2px;
    background-color: #004b8d; }

.font-quote {
  font-family: "FrutigerLTStd Cond", "FrutigerLTStd", Arial, sans-serif;
  display: block;
  text-align: left;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.5em;
  text-transform: normal;
  color: #ef8522;
  margin-bottom: 1.5em; }

.font-q-author {
  font-family: "FrutigerLTStd Cond", "FrutigerLTStd", Arial, sans-serif;
  display: block;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: #007eb3;
  margin-bottom: 10px; }

/*** Pre-Loading Animations  ***/
#loader-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #FFF;
  opacity: 1;
  visibility: visible; }

.fadeout #loader-wrapper {
  visibility: visible;
  opacity: 0;
  -webkit-transition: all 250ms ease-in-out 50ms;
  -moz-transition: all 250ms ease-in-out 50ms;
  -ms-transition: all 250ms ease-in-out 50ms;
  -o-transition: all 250ms ease-in-out 50ms;
  transition: all 250ms ease-in-out 50ms; }

.loaded #loader-wrapper {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 100ms ease-in-out 0ms;
  -moz-transition: all 100ms ease-in-out 0ms;
  -ms-transition: all 100ms ease-in-out 0ms;
  -o-transition: all 100ms ease-in-out 0ms;
  transition: all 100ms ease-in-out 0ms; }

/* Loading Animation */
.loader-dots {
  position: absolute;
  z-index: 1005;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }
  .loader-dots .loader-dots-inner {
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    width: calc(100%);
    color: #11ada2;
    padding: 0 100px;
    text-align: center; }
    .loader-dots .loader-dots-inner label {
      font-size: 20px;
      opacity: 0;
      display: inline-block; }
    .loader-dots .loader-dots-inner label:nth-child(6) {
      -webkit-animation: lol 3s infinite ease-in-out;
      animation: lol 3s infinite ease-in-out; }
    .loader-dots .loader-dots-inner label:nth-child(5) {
      -webkit-animation: lol 3s 100ms infinite ease-in-out;
      animation: lol 3s 100ms infinite ease-in-out; }
    .loader-dots .loader-dots-inner label:nth-child(4) {
      -webkit-animation: lol 3s 200ms infinite ease-in-out;
      animation: lol 3s 200ms infinite ease-in-out; }
    .loader-dots .loader-dots-inner label:nth-child(3) {
      -webkit-animation: lol 3s 300ms infinite ease-in-out;
      animation: lol 3s 300ms infinite ease-in-out; }
    .loader-dots .loader-dots-inner label:nth-child(2) {
      -webkit-animation: lol 3s 400ms infinite ease-in-out;
      animation: lol 3s 400ms infinite ease-in-out; }
    .loader-dots .loader-dots-inner label:nth-child(1) {
      -webkit-animation: lol 3s 500ms infinite ease-in-out;
      animation: lol 3s 500ms infinite ease-in-out; }

@keyframes lol {
  0% {
    opacity: 0;
    transform: translateX(-300px); }
  33% {
    opacity: 1;
    transform: translateX(0px); }
  66% {
    opacity: 1;
    transform: translateX(0px); }
  100% {
    opacity: 0;
    transform: translateX(300px); } }

@-webkit-keyframes lol {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-300px); }
  33% {
    opacity: 1;
    -webkit-transform: translateX(0px); }
  66% {
    opacity: 1;
    -webkit-transform: translateX(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(300px); } }

/* AJAX Loading Animation */
#loader {
  background-color: white;
  text-align: center;
  display: block;
  margin: 0px;
  height: 100%;
  width: 100%;
  padding-top: 200px;
  padding-bottom: 200px;
  position: absolute;
  opacity: 1;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 750; }

#loader span {
  font-size: 24px;
  font-weight: bold;
  color: #971b1e;
  display: none; }

.load-more {
  display: block; }

#loader.fadeout {
  opacity: 0;
  -webkit-transition: all 750ms ease-in-out 0ms;
  -moz-transition: all 750ms ease-in-out 0ms;
  -ms-transition: all 750ms ease-in-out 0ms;
  -o-transition: all 750ms ease-in-out 0ms;
  transition: all 750ms ease-in-out 0ms; }

/*** Pre-Loading Animations  ***/
#idle-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #FFF;
  opacity: 0;
  visibility: hidden; }

#idle-wrapper.active {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 250ms ease-in-out 50ms;
  -moz-transition: all 250ms ease-in-out 50ms;
  -ms-transition: all 250ms ease-in-out 50ms;
  -o-transition: all 250ms ease-in-out 50ms;
  transition: all 250ms ease-in-out 50ms; }

/* Loading Animation */
.idle-icon-spin {
  width: 120px;
  height: 120px;
  margin: 0;
  z-index: 1005;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);
  /* Safari and Chrome */
  -o-transform: translate(-50%, -50%);
  /* Opera */
  -moz-transform: translate(-50%, -50%);
  /* Firefox */ }

.idle-icon-spin img {
  position: absolute;
  display: block;
  -webkit-animation: spin 2000ms infinite linear;
  -moz-animation: spin 2000ms infinite linear;
  -ms-animation: spin 2000ms infinite linear;
  -o-animation: spin 2000ms infinite linear;
  animation: spin 2000ms infinite linear; }

@-webkit-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-ms-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/* AJAX Loading Animation */
#idle {
  background-color: white;
  text-align: center;
  display: block;
  margin: 0px;
  height: 100%;
  width: 100%;
  padding-top: 200px;
  padding-bottom: 200px;
  position: absolute;
  opacity: 1;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 750; }

#idle span {
  font-size: 24px;
  font-weight: bold;
  color: #971b1e;
  display: none; }

.load-more {
  display: block; }

#idle.fadeout {
  opacity: 0;
  -webkit-transition: all 750ms ease-in-out 0ms;
  -moz-transition: all 750ms ease-in-out 0ms;
  -ms-transition: all 750ms ease-in-out 0ms;
  -o-transition: all 750ms ease-in-out 0ms;
  transition: all 750ms ease-in-out 0ms; }

.col {
  width: 100%; }
  @media only screen and (max-width: 0px) {
    .col {
      width: 100%; } }
  @media only screen and (max-width: 481px) {
    .col {
      width: 100%; } }

.container-max {
  width: 100%;
  display: block;
  position: relative;
  max-width: 1160px;
  margin-right: auto;
  margin-left: auto; }

.container-pad {
  padding-right: 20px;
  padding-left: 20px; }

.pad {
  padding-right: 20px;
  padding-left: 20px; }

.pad-10 {
  padding-right: 10px;
  padding-left: 10px; }

.pad-15 {
  padding-right: 15px;
  padding-left: 15px; }

.pad-20 {
  padding-right: 20px;
  padding-left: 20px; }

.pad-25 {
  padding-right: 25px;
  padding-left: 25px; }

.pad-30 {
  padding-right: 30px;
  padding-left: 30px; }

.pad-35 {
  padding-right: 35px;
  padding-left: 35px; }

.pad-top-bottom {
  padding-top: 20px;
  padding-bottom: 20px; }

.pad-top-bottom-10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.pad-top-bottom-15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.pad-top-bottom-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.pad-top-bottom-25 {
  padding-top: 25px;
  padding-bottom: 25px; }

.pad-bottom {
  padding-bottom: 20px; }

.pad-left {
  padding-left: 20px; }

.pad-right {
  padding-right: 20px; }

.pad-right-alt {
  padding-right: 20px; }
  @media only screen and (min-width: 0px) {
    .pad-right-alt {
      padding-right: 20px; } }
  @media only screen and (min-width: 481px) {
    .pad-right-alt {
      padding-right: 20px; } }
  @media only screen and (min-width: 769px) {
    .pad-right-alt {
      padding-right: 40px; } }
  @media only screen and (min-width: 992px) {
    .pad-right-alt {
      padding-right: 40px; } }

.pad-off {
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0; }

.pad-right-off {
  padding-right: 0; }

.pad-left-off {
  padding-left: 0; }

.pad-equal {
  padding-top: 15px;
  padding-bottom: 15px; }

.percent {
  line-height: 1.5em; }

@media only screen and (max-width: 0px) {
  .no-pad-mobile {
    padding-right: 0;
    padding-left: 0; } }

@media only screen and (max-width: 481px) {
  .no-pad-mobile {
    padding-right: 0;
    padding-left: 0; } }

@media only screen and (max-width: 769px) {
  .no-pad-mobile {
    padding-right: 0;
    padding-left: 0; } }

@media only screen and (max-width: 0px) {
  .pad-mobile {
    padding-right: 20px;
    padding-left: 20px; } }

@media only screen and (max-width: 481px) {
  .pad-mobile {
    padding-right: 20px;
    padding-left: 20px; } }

@media only screen and (max-width: 769px) {
  .pad-mobile {
    padding-right: 20px;
    padding-left: 20px; } }

@media only screen and (max-width: 0px) {
  .pad-tablet {
    padding-right: 20px;
    padding-left: 20px; } }

@media only screen and (max-width: 481px) {
  .pad-tablet {
    padding-right: 20px;
    padding-left: 20px; } }

@media only screen and (max-width: 769px) {
  .pad-tablet {
    padding-right: 20px;
    padding-left: 20px; } }

@media only screen and (max-width: 992px) {
  .pad-tablet {
    padding-right: 20px;
    padding-left: 20px; } }

@media only screen and (max-width: 0px) {
  .pad-max-below {
    padding-right: 20px;
    padding-left: 20px; } }

@media only screen and (max-width: 481px) {
  .pad-max-below {
    padding-right: 20px;
    padding-left: 20px; } }

@media only screen and (max-width: 769px) {
  .pad-max-below {
    padding-right: 20px;
    padding-left: 20px; } }

@media only screen and (max-width: 992px) {
  .pad-max-below {
    padding-right: 20px;
    padding-left: 20px; } }

@media only screen and (max-width: 1024px) {
  .pad-max-below {
    padding-right: 20px;
    padding-left: 20px; } }

@media only screen and (max-width: 1232px) {
  .pad-max-below {
    padding-right: 20px;
    padding-left: 20px; } }

.col-border-left {
  border-left-color: #72767c;
  border-left-style: solid;
  border-left-width: 2px;
  padding-left: 40px;
  padding-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px; }
  @media only screen and (max-width: 0px) {
    .col-border-left {
      border-left-width: 0px; } }
  @media only screen and (max-width: 481px) {
    .col-border-left {
      border-left-width: 0px; } }
  @media only screen and (max-width: 769px) {
    .col-border-left {
      border-left-width: 2px; } }

.col-border-left-inner {
  padding-left: 40px;
  padding-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative; }
  .col-border-left-inner:before {
    content: '';
    display: block;
    position: absolute;
    top: 1em;
    bottom: 0em;
    left: 0px;
    border-left-color: #72767c;
    border-left-style: solid;
    border-left-width: 1px; }
    @media only screen and (min-width: 0px) {
      .col-border-left-inner:before {
        border-left-width: 0px; } }
    @media only screen and (min-width: 481px) {
      .col-border-left-inner:before {
        border-left-width: 0px; } }
    @media only screen and (min-width: 769px) {
      .col-border-left-inner:before {
        border-left-width: 1px; } }
    @media only screen and (min-width: 992px) {
      .col-border-left-inner:before {
        border-left-width: 1px; } }
  .col-border-left-inner.alt {
    padding-left: 80px;
    padding-right: 0px; }
    @media only screen and (min-width: 0px) {
      .col-border-left-inner.alt {
        padding-left: 0px; } }
    @media only screen and (min-width: 481px) {
      .col-border-left-inner.alt {
        padding-left: 0px; } }
    @media only screen and (min-width: 769px) {
      .col-border-left-inner.alt {
        padding-left: 60px; } }
    @media only screen and (min-width: 992px) {
      .col-border-left-inner.alt {
        padding-left: 60px; } }

@media only screen and (max-width: 0px) {
  .pad-mobile .col-border-left-inner {
    padding-left: 0px;
    padding-right: 0px; } }

@media only screen and (max-width: 481px) {
  .pad-mobile .col-border-left-inner {
    padding-left: 0px;
    padding-right: 0px; } }

@media only screen and (max-width: 769px) {
  .pad-mobile .col-border-left-inner {
    padding-left: 0px;
    padding-right: 0px; } }

/** Button Styling **/
.btn,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  text-transform: uppercase;
  display: inline-block;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #FFF;
  text-shadow: 0px 0px 0px transparent;
  font-weight: 600;
  letter-spacing: 0.15em;
  background-color: #ef8522;
  margin-right: auto;
  margin-left: auto;
  padding-top: 9px;
  padding-right: 25px;
  padding-bottom: 6px;
  padding-left: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-box-shadow: 0px 0px 0px transparent;
  -webkit-box-shadow: 0px 0px 0px transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 1px solid #ef8522;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  width: auto;
  max-width: 100%; }
  @media only screen and (min-width: 481px) {
    .btn,
    button,
    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
      display: inline-block;
      margin-bottom: 15px; } }
  @media only screen and (min-width: 769px) {
    .btn,
    button,
    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
      display: inline-block;
      margin-bottom: 0px; } }
  @media only screen and (min-width: 992px) {
    .btn,
    button,
    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
      display: inline-block; } }

a:not([href]):not([tabindex]) {
  color: #FFF; }

.btn-alt {
  color: #FFF;
  border: 2px solid #FFF; }

a.btn,
.btn {
  color: #FFF; }

a.btn:hover,
.btn:hover {
  background-color: transparent;
  color: #ef8522 !important; }

a.btn-alt,
.btn-alt {
  color: #FFF; }

a.btn-alt:hover,
.btn-alt:hover {
  color: #FFF !important; }

.btn:hover,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  color: #ef8522; }

.btn:first-child {
  *margin-left: 0; }

.btn:hover,
button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  color: #ef8522;
  text-decoration: none;
  /* Buttons in IE7 don't get borders, so darken on hover */
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear; }

.btn:focus,
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus {
  outline: 0px dotted #ef8522;
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: 0px; }

.btn.active,
.btn:active {
  background-image: none;
  outline: 0; }

.btn.disabled,
.btn[disabled] {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

a.btn,
a.btn:active,
a.btn:hover,
a.btn:focus,
a.btn:-webkit-any-link,
.btn,
.btn:active,
.btn:hover,
.btn:focus,
.btn:-webkit-any-link {
  outline: none;
  outline: 0 !important;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 0 0 transparent; }

.btn-wrap {
  white-space: pre-wrap; }

.btn-wrap {
  margin: 0 0 0 0;
  display: inline-block; }
  .btn-wrap img {
    cursor: pointer;
    display: inline-block;
    width: 33px;
    margin: 0 0 0 16px; }

/*********************************************
	Inputs
*********************************************/
input,
textarea,
select {
  color: #000;
  background-color: #f2f2f2;
  line-height: 14px;
  font-size: 14px;
  padding: 5px;
  -webkit-transition: all 500ms ease-in-out 0s;
  -moz-transition: all 500ms ease-in-out 0s;
  -ms-transition: all 500ms ease-in-out 0s;
  -o-transition: all 500ms ease-in-out 0s;
  transition: all 500ms ease-in-out 0s;
  background-repeat: no-repeat;
  background-position: 5px center;
  border: 0px solid #FFF;
  height: auto;
  width: auto;
  max-width: 1005; }

input[type="submit"],
textarea,
select {
  background-image: none; }

input:focus,
textarea:focus,
select:focus {
  color: #000;
  background-color: #f2f2f2;
  background-image: none; }

input:invalid,
textarea:invalid,
select:invalid {
  background-color: #f2f2f2; }

input[placeholder] {
  font-weight: 300; }

::-webkit-input-placeholder {
  color: #971b1e; }

:-moz-placeholder {
  color: #971b1e; }

::-moz-placeholder {
  color: #971b1e; }

:-ms-input-placeholder {
  color: #971b1e; }

label {
  font-family: 'Hind', sans-serif;
  font-size: 14px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: normal;
  display: inline-block; }

input#submit {
  width: auto;
  color: #ef8522;
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: center; }

input[type="checkbox"] {
  background-color: #CCC;
  display: inline-block; }

/*********************************************
	Homepage Styles
*********************************************/
body.home p {
  color: #000;
  font-size: 18px;
  line-height: 20px; }

/* ScrollTop Animation */
#scrolltop {
  background-color: #ef8522;
  background-color: rgba(239, 133, 34, 0.5);
  display: block;
  position: fixed;
  z-index: 99999;
  right: 30px;
  bottom: 30px;
  height: 40px;
  width: 40px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  cursor: pointer;
  transition: all 500ms ease-in-out 0s; }
  #scrolltop:after {
    content: '';
    display: block;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 15px;
    height: 15px;
    border-right: 2px solid #FFF;
    border-top: 2px solid #FFF; }

#scrolltop:hover {
  background-color: #ef8522;
  background-color: rgba(239, 133, 34, 0.9); }

/** CSS3 Arrows **/
.scrollto i {
  border: solid #FFFFFF;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 15px; }

.scrollto .right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.scrollto .left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.scrollto .up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg); }

.scrollto .down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

/** SCROLL TO ANIMATION BUTTON **/
.scrollto {
  display: block;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  margin-top: 0px;
  margin-bottom: 0px;
  top: 0px;
  cursor: pointer;
  z-index: 99999;
  -webkit-transition: all 500ms ease-in-out 0s;
  -moz-transition: all 500ms ease-in-out 0s;
  -ms-transition: all 500ms ease-in-out 0s;
  -o-transition: all 500ms ease-in-out 0s;
  transition: all 500ms ease-in-out 0s;
  left: 0px;
  right: 0px;
  width: 0;
  height: 0; }

.scrollto i {
  position: absolute;
  bottom: 0px; }

.scrollto-circle span {
  height: 3.5em;
  width: 3.5em;
  background-color: #7f7f7f;
  border: 0px solid #FFF;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: -2em;
  transform: translate(-50%, 0%);
  bottom: auto;
  padding: 0px;
  margin-left: 0;
  display: block; }
  .scrollto-circle span:hover {
    background-color: #004c8b; }
  .scrollto-circle span:after {
    content: '';
    background-image: url(../../assets/img/svg/nav-down-arrow.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    display: inline-block;
    height: 100%;
    width: 100%; }

.scrollto-slider {
  bottom: 50px; }
  @media only screen and (min-width: 0px) {
    .scrollto-slider {
      bottom: 45px; } }
  @media only screen and (min-width: 481px) {
    .scrollto-slider {
      bottom: 45px; } }
  @media only screen and (min-width: 769px) {
    .scrollto-slider {
      bottom: 60px; } }

/*********************************************
	Header
*********************************************/
#header-wrap {
  display: block;
  position: absolute;
  margin: 0px;
  padding: 0px;
  height: auto;
  width: 100%;
  z-index: 600;
  top: 0px;
  border-top-color: #43525a;
  border-top-width: 6px;
  border-top-style: solid; }

#header-wrap:after {
  content: '';
  display: none;
  clear: both;
  z-index: -1;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, transparent 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.65) 0%, transparent 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, transparent 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  height: 150px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  opacity: 0.2; }

#header-wrap.sticky-ghost {
  min-height: 90px; }

.navbar-wrap {
  display: inline-block;
  min-height: 0px;
  position: relative;
  margin: 0px;
  padding: 0px;
  height: auto;
  width: auto;
  z-index: 599;
  float: right; }
  @media only screen and (min-width: 481px) {
    .navbar-wrap {
      width: auto;
      z-index: 599; } }
  @media only screen and (min-width: 769px) {
    .navbar-wrap {
      width: auto;
      z-index: 598; } }
  @media only screen and (min-width: 992px) {
    .navbar-wrap {
      width: auto;
      z-index: 598; } }

#header-wrap.sticky .navbar-wrap {
  z-index: 598; }

#app.show #header-wrap .navbar-wrap {
  z-index: 599; }

@media only screen and (min-width: 481px) {
  #app.show #header-wrap.sticky .navbar-wrap {
    z-index: 599; } }

@media only screen and (min-width: 769px) {
  #app.show #header-wrap.sticky .navbar-wrap {
    z-index: 598; } }

@media only screen and (min-width: 992px) {
  #app.show #header-wrap.sticky .navbar-wrap {
    z-index: 598; } }

.header-logo-wrap {
  display: inline-block;
  min-height: 0px;
  position: relative;
  margin: 0px;
  padding: 0px;
  height: auto;
  width: auto;
  z-index: 598;
  float: left; }
  @media only screen and (min-width: 481px) {
    .header-logo-wrap {
      width: auto;
      z-index: 598; } }
  @media only screen and (min-width: 769px) {
    .header-logo-wrap {
      width: auto;
      z-index: 599; } }
  @media only screen and (min-width: 992px) {
    .header-logo-wrap {
      width: auto;
      z-index: 599; } }

#header-wrap.sticky .header-logo-wrap {
  z-index: 599; }

#app.show #header-wrap .header-logo-wrap {
  z-index: 598; }

@media only screen and (min-width: 481px) {
  #app.show #header-wrap.sticky .header-logo-wrap {
    z-index: 598; } }

@media only screen and (min-width: 769px) {
  #app.show #header-wrap.sticky .header-logo-wrap {
    z-index: 599; } }

@media only screen and (min-width: 992px) {
  #app.show #header-wrap.sticky .header-logo-wrap {
    z-index: 599; } }

#logo {
  display: block;
  position: relative;
  background-repeat: no-repeat;
  z-index: 400;
  background-position: center center;
  float: left;
  display: inline-block;
  margin-right: auto;
  background-color: #dbdbdb;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-transition: all 1000ms ease 0s;
  -moz-transition: all 1000ms ease 0s;
  -ms-transition: all 1000ms ease 0s;
  -o-transition: all 1000ms ease 0s;
  transition: all 1000ms ease 0s; }
  @media only screen and (min-width: 481px) {
    #logo {
      float: none;
      display: inline-block;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 0px;
      padding-bottom: 0px;
      margin-left: 0px;
      margin-right: auto;
      margin-top: 0px;
      margin-bottom: 0px; } }
  @media only screen and (min-width: 769px) {
    #logo {
      float: none;
      display: inline-block;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 0px;
      padding-bottom: 0px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0px;
      margin-bottom: 0px; } }
  @media only screen and (min-width: 992px) {
    #logo {
      float: left;
      display: inline-block;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 0px;
      padding-bottom: 0px;
      margin-left: 0px;
      margin-right: auto;
      margin-top: 0px;
      margin-bottom: 0px; } }

#logo:hover {
  background-color: #dbdbdb; }

#logo svg,
#gatewaypdx {
  max-height: 60px; }

#header-wrap.sticky-ghost #logo {
  padding-top: 0px;
  padding-bottom: 0px;
  max-height: 49px; }
  @media only screen and (min-width: 992px) {
    #header-wrap.sticky-ghost #logo {
      top: -100px; } }

#header-wrap.sticky-ghost #logo svg,
#header-wrap.sticky-ghost #gatewaypdx {
  max-height: 49px; }

#logoarrow {
  display: block;
  width: 125px;
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: -125px;
  text-align: left; }

#header-wrap.sticky #logoarrow {
  display: none; }

#header-wrap.sticky #logo {
  float: left;
  display: inline-block;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000; }

#logo:after {
  content: '';
  display: none;
  position: absolute;
  right: -20px;
  top: 0px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 0 30px 20px;
  border-color: transparent transparent transparent #dbdbdb; }

#header-wrap.sticky #logo:after {
  display: none; }

#logo img {
  display: block;
  max-width: 100%;
  max-height: 100%; }
  @media only screen and (min-width: 481px) {
    #logo img {
      margin-top: 5px;
      max-height: 25px; } }
  @media only screen and (min-width: 769px) {
    #logo img {
      margin-top: 0px;
      max-height: 35px; } }
  @media only screen and (min-width: 992px) {
    #logo img {
      max-height: 40px; } }

@media only screen and (min-width: 992px) {
  #header-wrap.sticky #logo img {
    max-height: 30px; } }

.header-wrap-right {
  text-align: right;
  display: inline-block;
  float: right;
  width: auto;
  margin-right: 200px; }
  @media only screen and (min-width: 481px) {
    .header-wrap-right {
      text-align: left;
      float: right;
      margin-right: 0px; } }
  @media only screen and (min-width: 769px) {
    .header-wrap-right {
      text-align: left;
      float: right;
      margin-right: 0px; } }
  @media only screen and (min-width: 992px) {
    .header-wrap-right {
      text-align: right;
      float: right;
      margin-right: 175px; } }

.header-wrap-right .tel {
  font-size: 1em;
  display: inline-block;
  float: right;
  margin-top: 10px;
  padding-top: 3px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: bold; }

/*********************************************
	Footer
*********************************************/
#footer {
  font-family: 'Hind', sans-serif;
  display: block;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  background-color: #FFFFFF;
  color: #000000;
  z-index: 501;
  font-size: 14px;
  font-weight: 400;
  text-align: center; }
  @media only screen and (min-width: 0px) {
    #footer {
      text-align: center; } }
  @media only screen and (min-width: 481px) {
    #footer {
      text-align: center; } }
  @media only screen and (min-width: 769px) {
    #footer {
      text-align: left; } }
  @media only screen and (min-width: 992px) {
    #footer {
      text-align: left; } }

@media only screen and (min-width: 1024px) {
  .footer-col-right {
    text-align: right; } }

#footer:after {
  content: '';
  display: block;
  clear: both; }

#footer .logo {
  display: inline-block;
  margin-bottom: 25px; }

#footer a {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  color: #e7ded0;
  font-weight: 700; }

#footer a:hover {
  color: #FFF; }

#footer .f_title {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
  display: inline-block;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #c1c1c1;
  line-height: 18px;
  margin-bottom: 12px; }

#footer a.tel {
  color: #971b1e; }

#footer a.tel:hover {
  color: #000; }

.border-right {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #aeaeae;
  min-height: 140px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

@media only screen and (min-width: 0px) {
  #footer .col {
    margin-bottom: 25px; } }

@media only screen and (min-width: 481px) {
  #footer .col {
    margin-bottom: 25px; } }

@media only screen and (min-width: 769px) {
  #footer .col {
    margin-bottom: 0px; } }

@media only screen and (min-width: 992px) {
  #footer .col {
    margin-bottom: 0px; } }

.f_nav {
  font-family: 'Hind', sans-serif;
  width: 100%;
  height: auto;
  min-height: 0px;
  margin: 0px;
  text-align: center;
  color: #FFF;
  list-style-image: none;
  list-style-type: none;
  display: block;
  position: relative;
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 25px;
  padding-left: 0px; }
  @media only screen and (min-width: 481px) {
    .f_nav {
      padding-top: 25px;
      padding-bottom: 25px; } }
  @media only screen and (min-width: 769px) {
    .f_nav {
      padding-top: 25px;
      padding-bottom: 35px; } }
  @media only screen and (min-width: 992px) {
    .f_nav {
      padding-top: 10px;
      padding-bottom: 55px; } }

.f_nav li {
  float: none;
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  color: #FFF;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  width: auto;
  background-color: transparent;
  background-image: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px; }
  @media only screen and (min-width: 481px) {
    .f_nav li {
      float: none;
      display: block;
      text-align: center;
      border-left-width: 0px; } }
  @media only screen and (min-width: 769px) {
    .f_nav li {
      float: none;
      display: block;
      text-align: center; } }
  @media only screen and (min-width: 992px) {
    .f_nav li {
      float: none;
      display: inline-block;
      text-align: left; } }

.f_nav li:hover {
  background-color: transparent;
  background-image: none; }

#f_nav2 ul {
  -moz-column-count: 2;
  -moz-column-gap: 30px;
  -webkit-column-count: 2;
  -webkit-column-gap: 30px;
  column-count: 2;
  column-gap: 30px;
  /*hack for moz/webkit extra spacing*/
  overflow: hidden;
  position: relative; }
  @media only screen and (min-width: 481px) {
    #f_nav2 ul {
      -moz-column-count: 1;
      -moz-column-gap: 30px;
      -webkit-column-count: 1;
      -webkit-column-gap: 30px;
      column-count: 1;
      column-gap: 30px; } }
  @media only screen and (min-width: 769px) {
    #f_nav2 ul {
      -moz-column-count: 1;
      -moz-column-gap: 30px;
      -webkit-column-count: 1;
      -webkit-column-gap: 30px;
      column-count: 1;
      column-gap: 30px; } }
  @media only screen and (min-width: 992px) {
    #f_nav2 ul {
      -moz-column-count: 2;
      -moz-column-gap: 30px;
      -webkit-column-count: 2;
      -webkit-column-gap: 30px;
      column-count: 2;
      column-gap: 30px; } }

.oldie #f_nav2 ul li {
  width: 45% !important; }
  @media only screen and (min-width: 481px) {
    .oldie #f_nav2 ul li {
      width: 100% !important; } }
  @media only screen and (min-width: 769px) {
    .oldie #f_nav2 ul li {
      width: 100% !important; } }
  @media only screen and (min-width: 992px) {
    .oldie #f_nav2 ul li {
      width: 45% !important; } }

.oldie #f_nav2 ul li:nth-child(even) {
  float: right; }
  @media only screen and (min-width: 481px) {
    .oldie #f_nav2 ul li:nth-child(even) {
      float: none; } }
  @media only screen and (min-width: 769px) {
    .oldie #f_nav2 ul li:nth-child(even) {
      float: none; } }
  @media only screen and (min-width: 992px) {
    .oldie #f_nav2 ul li:nth-child(even) {
      float: right; } }

.f_nav ul {
  display: block;
  margin: 0px;
  padding: 0px; }

.f_nav ul li {
  display: inline-block;
  margin: 0px;
  padding: 0px; }

#footer .f_nav li a {
  color: #666666;
  display: inline-block;
  width: auto;
  padding-top: 3px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-left: 5px;
  font-size: 12px;
  font-weight: 600; }

#footer .f_nav ul li a {
  color: #666666;
  text-transform: none;
  font-size: 12px; }
  @media only screen and (min-width: 481px) {
    #footer .f_nav ul li a {
      padding-top: 8px;
      padding-bottom: 8px; } }
  @media only screen and (min-width: 769px) {
    #footer .f_nav ul li a {
      padding-top: 5px;
      padding-bottom: 5px; } }
  @media only screen and (min-width: 992px) {
    #footer .f_nav ul li a {
      padding-top: 3px;
      padding-bottom: 3px; } }

#footer .f_nav li a:hover {
  color: #971b1e; }

.f_logo {
  display: block;
  margin-right: auto;
  margin-left: auto;
  max-width: 150px;
  max-height: 150px;
  margin-bottom: 15px; }

#copy {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  font-size: 12px;
  width: 100%;
  text-align: center;
  clear: both;
  color: #666666;
  padding-top: 35px;
  padding-bottom: 35px;
  position: relative;
  z-index: 500; }

#copy a {
  color: #666666; }

.action_box {
  position: relative;
  text-align: center;
  display: block;
  padding-top: 20px;
  padding-bottom: 20px; }

.action_title {
  display: inline-block;
  width: auto;
  margin-right: auto;
  margin-left: auto;
  padding-top: 15px;
  padding-right: 25px;
  padding-bottom: 15px;
  padding-left: 25px;
  position: relative;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ef8522;
  text-align: center; }

a.action_btn {
  display: inline-block;
  width: auto;
  margin-right: auto;
  margin-left: auto;
  padding-top: 15px;
  padding-right: 25px;
  padding-bottom: 15px;
  padding-left: 25px;
  position: relative;
  font-size: 42px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ef8522;
  text-align: center; }

.action_box:hover a.action_btn {
  color: #ef8522; }

.action_box:before {
  content: '';
  background-color: #ef8522;
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  -webkit-transition: all 500ms ease-in-out 0s;
  -moz-transition: all 500ms ease-in-out 0s;
  -ms-transition: all 500ms ease-in-out 0s;
  -o-transition: all 500ms ease-in-out 0s;
  transition: all 500ms ease-in-out 0s;
  -webkit-transform: scale3D(0, 0, 1);
  transform: scale3D(0, 0, 1); }

.action_slot {
  display: inline-block;
  margin: 20px;
  padding: 5px;
  font-size: 18px;
  color: #43525a;
  cursor: pointer;
  -webkit-transition: all 700ms ease-in-out 0s;
  -moz-transition: all 700ms ease-in-out 0s;
  -ms-transition: all 700ms ease-in-out 0s;
  -o-transition: all 700ms ease-in-out 0s;
  transition: all 700ms ease-in-out 0s; }

.action_box.active .action_slot {
  font-size: 12px; }

.action_slot.active {
  color: #008dd2; }

.action_slot i {
  display: block;
  font-size: 50px;
  padding: 5px;
  color: #43525a;
  -webkit-transition: all 700ms ease-in-out 0s;
  -moz-transition: all 700ms ease-in-out 0s;
  -ms-transition: all 700ms ease-in-out 0s;
  -o-transition: all 700ms ease-in-out 0s;
  transition: all 700ms ease-in-out 0s; }

.action_box.active .action_slot i {
  font-size: 30px; }

.action_slot.active i {
  color: #008dd2; }

.action_tab_wrap {
  display: block; }

.action_tab {
  display: block;
  overflow: hidden;
  max-height: 0px;
  text-align: left;
  -webkit-transition: all 700ms ease-in-out 0s;
  -moz-transition: all 700ms ease-in-out 0s;
  -ms-transition: all 700ms ease-in-out 0s;
  -o-transition: all 700ms ease-in-out 0s;
  transition: all 700ms ease-in-out 0s; }

.action_tab.active {
  display: block; }

@media only screen and (min-width: 0px) {
  .row {
    margin-left: 0px;
    margin-right: 0px; } }

@media only screen and (min-width: 481px) {
  .row {
    margin-left: 0px;
    margin-right: 0px; } }

@media only screen and (min-width: 769px) {
  .row {
    margin-left: 0px;
    margin-right: 0px; } }

.section {
  display: block;
  position: relative;
  z-index: 500;
  overflow: hidden;
  height: auto;
  width: 100%;
  clear: both;
  background-color: #ffffff; }

.section-nav {
  display: block;
  position: relative;
  z-index: 500;
  overflow: visible;
  height: auto;
  width: 100%;
  clear: both;
  z-index: 501; }

.section-over {
  overflow: visible; }

.section-top {
  display: block;
  border-top-width: 0px;
  border-top-style: solid;
  border-top-color: #971b1e; }

.section-parallax-front {
  z-index: 499; }

.section-parallax {
  position: relative;
  overflow: hidden;
  min-height: 200px;
  text-align: center; }

.section-parallax .parallax-img {
  display: block;
  position: absolute;
  height: 200%;
  width: auto;
  min-width: 100%;
  min-height: 50%;
  top: -100%;
  max-width: 800%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  /* IE 9 */
  -webkit-transform: translate(-50%, 0%);
  /* Safari and Chrome */
  -o-transform: translate(-50%, 0%);
  /* Opera */
  -moz-transform: translate(-50%, 0%);
  /* Firefox */ }
  @media only screen and (min-width: 0px) {
    .section-parallax .parallax-img {
      height: 150%;
      width: auto;
      left: 50%;
      transform: translate(-50%, 0%);
      -ms-transform: translate(-50%, 0%);
      /* IE 9 */
      -webkit-transform: translate(-50%, 0%);
      /* Safari and Chrome */
      -o-transform: translate(-50%, 0%);
      /* Opera */
      -moz-transform: translate(-50%, 0%);
      /* Firefox */ } }
  @media only screen and (min-width: 481px) {
    .section-parallax .parallax-img {
      height: 150%;
      width: auto; } }
  @media only screen and (min-width: 769px) {
    .section-parallax .parallax-img {
      height: 150%;
      width: auto; } }
  @media only screen and (min-width: 992px) {
    .section-parallax .parallax-img {
      height: 150%;
      width: auto; } }
  @media only screen and (min-width: 1024px) {
    .section-parallax .parallax-img {
      height: 150%;
      width: auto; } }
  @media only screen and (min-width: 1232px) {
    .section-parallax .parallax-img {
      height: auto;
      width: 100%;
      left: 0%;
      transform: translate(0%, 0%);
      -ms-transform: translate(0%, 0%);
      /* IE 9 */
      -webkit-transform: translate(0%, 0%);
      /* Safari and Chrome */
      -o-transform: translate(0%, 0%);
      /* Opera */
      -moz-transform: translate(0%, 0%);
      /* Firefox */ } }

.section-image {
  position: relative;
  overflow: hidden;
  min-height: 200px;
  text-align: center; }
  .section-image img {
    position: relative;
    display: block;
    width: 100%;
    z-index: 100; }

.section-image-text {
  position: relative;
  overflow: hidden;
  min-height: 200px;
  text-align: center; }
  .section-image-text img {
    position: absolute;
    display: block;
    width: 100%;
    z-index: 100; }
  .section-image-text .image-overlay {
    position: relative;
    width: 50%;
    padding: 60px 20px 60px 20px;
    text-align: left;
    display: block;
    z-index: 101; }
    .section-image-text .image-overlay .headline {
      display: block;
      font-size: 42px;
      line-height: 52px; }
    .section-image-text .image-overlay .text {
      display: block;
      font-size: 18px; }
    .section-image-text .image-overlay a {
      display: block;
      font-size: 18px; }

.section-full {
  display: block; }

.section-padded-xs {
  padding-top: 15px;
  padding-bottom: 15px; }

.section-padded-sm {
  padding-top: 25px;
  padding-bottom: 25px; }

.section-padded {
  padding-top: 35px;
  padding-bottom: 35px; }

.section-padded-med {
  padding-top: 70px;
  padding-bottom: 70px; }

.section-padded-big {
  padding-top: 120px;
  padding-bottom: 120px; }

.section-padded-huge {
  padding-top: 200px;
  padding-bottom: 200px; }

.section-no-pad-bottom {
  padding-bottom: 0px; }

.section-no-pad-top-bottom {
  padding-top: 0px;
  padding-bottom: 0px; }

.section-border {
  border-top-width: 10px;
  border-bottom-width: 10px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: #0086ac;
  border-bottom-color: #0086ac; }

.section-border-top {
  border-top-width: 10px;
  border-top-style: solid;
  border-top-color: #0086ac; }

.section-border-bottom {
  border-bottom-width: 10px;
  border-bottom-style: solid;
  border-bottom-color: #0086ac; }

.section-colored {
  background-color: #ef8522; }

.section-red {
  background-color: #971b1e; }

.section-grey {
  background-color: #dbdbdb; }

.section-blue {
  background-color: #282b4a; }

.section-texture1 {
  background-image: url(../../assets/img/textures/light_toast.png);
  background-repeat: repeat;
  background-attachment: scroll; }

.section-texture2 {
  background-image: url(../../assets/img/textures/tiny_grid.png);
  background-repeat: repeat;
  background-attachment: scroll; }

.section-texture3 {
  background-image: url(../../assets/img/textures/rockywall.png);
  background-repeat: repeat;
  background-attachment: scroll; }

.section-texture4 {
  background-image: url(../../assets/img/textures/noisy.png);
  background-repeat: repeat;
  background-attachment: scroll; }

.section-background {
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center center;
  text-align: center;
  background-size: auto 100%; }

.section-bg1 {
  background-image: url(../../assets/img/img-600.jpg); }

.section-background:before,
.section-background:after {
  content: '';
  background-color: #FFF;
  background-color: rgba(255, 255, 255, 0.3);
  display: none;
  height: 20px;
  width: 100%;
  left: 0px;
  right: 0px;
  position: absolute;
  z-index: 505; }

.section-background:before {
  top: 0px; }

.section-background:after {
  bottom: 0px; }

.section-background-open:before,
.section-background-open:after {
  content: '';
  background-color: #FFF;
  background-color: rgba(255, 255, 255, 0.3);
  display: none;
  height: 50%;
  width: 100%;
  left: 0px;
  right: 0px;
  position: absolute;
  z-index: 505;
  -webkit-transition: all 800ms ease-in-out 0s;
  -moz-transition: all 800ms ease-in-out 0s;
  -ms-transition: all 800ms ease-in-out 0s;
  -o-transition: all 800ms ease-in-out 0s;
  transition: all 800ms ease-in-out 0s; }

.section-background-open:hover:before,
.section-background-open:hover:after {
  height: 20px; }

.section-background-open:before {
  top: 0px; }

.section-background-open:after {
  bottom: 0px; }

.section-background .subtitle,
.section-background h2,
.section-background blockquote,
.section-background .listbox {
  color: #FFF; }

.section-background .title {
  font-family: 'Hind', sans-serif;
  color: #222428;
  font-size: 2.1em;
  font-weight: bold;
  margin-bottom: 20px; }
  @media only screen and (min-width: 481px) {
    .section-background .title {
      font-size: 1em; } }
  @media only screen and (min-width: 769px) {
    .section-background .title {
      font-size: 1.5em; } }
  @media only screen and (min-width: 992px) {
    .section-background .title {
      font-size: 2.1em; } }

.section-background .title-underline:after {
  content: '';
  display: block;
  height: auto;
  width: 33%;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #222428;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px; }

.section-background .subtitle {
  font-family: 'Oswald light', 'Oswald', sans-serif;
  color: #FFF;
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 10px; }
  @media only screen and (min-width: 481px) {
    .section-background .subtitle {
      font-size: 2em; } }
  @media only screen and (min-width: 769px) {
    .section-background .subtitle {
      font-size: 2.5em; } }
  @media only screen and (min-width: 992px) {
    .section-background .subtitle {
      font-size: 3em; } }

.section-action {
  background-color: #FFF;
  padding-top: 0px;
  padding-bottom: 0px; }

.section-action-bg img.bg {
  display: block;
  position: absolute;
  width: 100%;
  height: auto; }

.section-footer-nav {
  background-color: #004b8d; }
  .section-footer-nav .fnav-title {
    display: block;
    font-size: 2em;
    color: #FFF; }
  .section-footer-nav ul.fnav {
    display: block;
    margin: 0em 0;
    padding: 0 0 0 0px;
    list-style-position: inside;
    list-style-image: none;
    list-style-type: none; }
    .section-footer-nav ul.fnav li {
      display: block; }
      .section-footer-nav ul.fnav li a {
        display: block;
        font-size: 1em;
        color: #FFF;
        padding: 3px 0px 3px 0px; }

.section-background-video {
  position: relative; }

.section-background-video .bg-video-wrap {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1; }

.section-background-video .bg-video-wrap .video-js {
  min-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.section-background-video .bg-video-wrap video {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  background-color: transparent; }

.section-map {
  display: block;
  position: relative;
  z-index: 500;
  overflow: hidden;
  height: auto;
  width: 100%;
  min-height: 800px; }

.section-map .gmap {
  min-height: 800px;
  height: 800px; }

.section-break {
  display: block;
  clear: both;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 185px;
  height: 1px;
  background-color: #72767c; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.section-nav {
  border-top: 2px solid #FFF;
  position: relative;
  overflow: visible;
  height: 0px;
  width: 100%;
  display: block;
  background-color: #f18523;
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 600; }
  .section-nav ul.nav {
    display: block;
    position: relative; }
    .section-nav ul.nav:after {
      content: '';
      display: block;
      clear: both; }
    .section-nav ul.nav li {
      display: inline-block;
      float: left; }
      @media only screen and (min-width: 0px) {
        .section-nav ul.nav li {
          width: 100%;
          margin-right: 0%; } }
      @media only screen and (min-width: 481px) {
        .section-nav ul.nav li {
          width: 100%;
          margin-right: 0%; } }
      @media only screen and (min-width: 769px) {
        .section-nav ul.nav li {
          width: 20%;
          margin-right: 0%; } }
      @media only screen and (min-width: 992px) {
        .section-nav ul.nav li {
          width: 20%;
          margin-right: 0%; } }
      .section-nav ul.nav li a {
        display: block;
        position: relative;
        text-align: center;
        font-size: 18px;
        font-weight: 200;
        color: #004b8d;
        text-transform: uppercase;
        padding: 20px 15px 20px 15px; }
        @media only screen and (min-width: 0px) {
          .section-nav ul.nav li a {
            font-size: 18px; } }
        @media only screen and (min-width: 481px) {
          .section-nav ul.nav li a {
            font-size: 18px; } }
        @media only screen and (min-width: 769px) {
          .section-nav ul.nav li a {
            font-size: 18px; } }
        @media only screen and (min-width: 992px) {
          .section-nav ul.nav li a {
            font-size: 18px; } }
  .section-nav #s-logo {
    position: absolute;
    z-index: 601;
    right: 0px !important;
    margin-right: 0 !important;
    top: -35px;
    display: block;
    width: 230px;
    height: auto;
    background-color: #013678; }
    .section-nav #s-logo a {
      display: block; }
    @media only screen and (min-width: 0px) {
      .section-nav #s-logo {
        width: 150px;
        top: -20px; } }
    @media only screen and (min-width: 481px) {
      .section-nav #s-logo {
        width: 200px;
        top: -30px; } }
    @media only screen and (min-width: 769px) {
      .section-nav #s-logo {
        width: 230px;
        top: -35px; } }
    @media only screen and (min-width: 992px) {
      .section-nav #s-logo {
        width: 230px; } }

#app.stickynav #s-nav {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f18523;
  padding-top: 0px;
  padding-bottom: 0px; }
  @media only screen and (min-width: 0px) {
    #app.stickynav #s-nav {
      position: relative; } }
  @media only screen and (min-width: 481px) {
    #app.stickynav #s-nav {
      position: relative; } }
  @media only screen and (min-width: 769px) {
    #app.stickynav #s-nav {
      position: fixed; } }
  @media only screen and (min-width: 992px) {
    #app.stickynav #s-nav {
      position: fixed; } }

.section-hero-slider {
  position: relative;
  height: 80vh;
  width: 100%; }
  .section-hero-slider .section-slider-fixed {
    position: fixed;
    display: block;
    height: 80vh;
    width: 100%; }
    .section-hero-slider .section-slider-fixed .h_slide_box {
      position: relative;
      display: block;
      margin: 0px;
      padding: 0px;
      height: 80vh;
      width: 100%;
      min-height: 200px;
      z-index: 450;
      -webkit-box-sizing: border-box;
      box-sizing: border-box; }
      @media only screen and (min-width: 0px) {
        .section-hero-slider .section-slider-fixed .h_slide_box {
          height: 300px;
          height: 70vh; } }
      @media only screen and (min-width: 481px) {
        .section-hero-slider .section-slider-fixed .h_slide_box {
          height: 350px;
          height: 70vh; } }
      @media only screen and (min-width: 769px) {
        .section-hero-slider .section-slider-fixed .h_slide_box {
          height: 400px;
          height: 75vh; } }
      @media only screen and (min-width: 992px) {
        .section-hero-slider .section-slider-fixed .h_slide_box {
          height: 500px;
          height: 80vh; } }
      @media only screen and (min-width: 1024px) {
        .section-hero-slider .section-slider-fixed .h_slide_box {
          height: 500px;
          height: 85vh; } }
      @media only screen and (min-width: 1232px) {
        .section-hero-slider .section-slider-fixed .h_slide_box {
          height: 500px;
          height: 90vh; } }
    @media only screen and (min-width: 0px) {
      .section-hero-slider .section-slider-fixed {
        height: 300px;
        height: 70vh; } }
    @media only screen and (min-width: 481px) {
      .section-hero-slider .section-slider-fixed {
        height: 350px;
        height: 70vh; } }
    @media only screen and (min-width: 769px) {
      .section-hero-slider .section-slider-fixed {
        height: 400px;
        height: 75vh; } }
    @media only screen and (min-width: 992px) {
      .section-hero-slider .section-slider-fixed {
        height: 500px;
        height: 80vh; } }
    @media only screen and (min-width: 1024px) {
      .section-hero-slider .section-slider-fixed {
        height: 500px;
        height: 85vh; } }
    @media only screen and (min-width: 1232px) {
      .section-hero-slider .section-slider-fixed {
        height: 500px;
        height: 90vh; } }
  @media only screen and (min-width: 0px) {
    .section-hero-slider {
      height: 300px;
      height: 70vh; } }
  @media only screen and (min-width: 481px) {
    .section-hero-slider {
      height: 350px;
      height: 70vh; } }
  @media only screen and (min-width: 769px) {
    .section-hero-slider {
      height: 400px;
      height: 75vh; } }
  @media only screen and (min-width: 992px) {
    .section-hero-slider {
      height: 500px;
      height: 80vh; } }
  @media only screen and (min-width: 1024px) {
    .section-hero-slider {
      height: 500px;
      height: 85vh; } }
  @media only screen and (min-width: 1232px) {
    .section-hero-slider {
      height: 500px;
      height: 90vh; } }

.section-video {
  position: relative;
  overflow: hidden;
  height: auto;
  width: 100%;
  display: block; }
  .section-video video {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    background-color: #000000; }

/*********************************************
	Parallax Styles
*********************************************/
.section-parallax {
  text-align: center;
  min-height: 550px; }
  @media only screen and (min-width: 0px) {
    .section-parallax {
      min-height: 350px; } }
  @media only screen and (min-width: 481px) {
    .section-parallax {
      min-height: 350px; } }
  @media only screen and (min-width: 769px) {
    .section-parallax {
      min-height: 500px; } }
  @media only screen and (min-width: 992px) {
    .section-parallax {
      min-height: 550px;
      height: 40vh; } }
  @media only screen and (min-width: 1024px) {
    .section-parallax {
      min-height: 600px;
      height: 50vh; } }
  @media only screen and (min-width: 1232px) {
    .section-parallax {
      min-height: 600px;
      height: 60vh; } }
  @media only screen and (min-width: 1900px) {
    .section-parallax {
      min-height: 600px;
      height: 70vh; } }
  .section-parallax .p_image {
    display: block;
    position: absolute;
    background-position: center center;
    background-size: 100% auto;
    width: 100%;
    max-width: 1000%;
    height: 200%;
    min-width: 100%;
    min-height: 100%;
    z-index: 400;
    top: 0%;
    left: 0%; }
    @media only screen and (min-width: 0px) {
      .section-parallax .p_image {
        background-size: auto 100%; } }
    @media only screen and (min-width: 481px) {
      .section-parallax .p_image {
        background-size: auto 100%; } }
    @media only screen and (min-width: 769px) {
      .section-parallax .p_image {
        background-size: 100% auto; } }
    @media only screen and (min-width: 992px) {
      .section-parallax .p_image {
        background-size: 100% auto; } }
  .section-parallax .p_image_short {
    height: 150%;
    background-size: cover; }
    @media only screen and (min-width: 0px) {
      .section-parallax .p_image_short {
        background-size: auto 100%; } }
    @media only screen and (min-width: 481px) {
      .section-parallax .p_image_short {
        background-size: auto 100%; } }
    @media only screen and (min-width: 769px) {
      .section-parallax .p_image_short {
        background-size: cover; } }
    @media only screen and (min-width: 992px) {
      .section-parallax .p_image_short {
        background-size: cover; } }
  @media only screen and (min-width: 0px) {
    .section-parallax .p_image_one {
      background-position: 75% center; } }
  @media only screen and (min-width: 481px) {
    .section-parallax .p_image_one {
      background-position: 75% center; } }
  @media only screen and (min-width: 769px) {
    .section-parallax .p_image_one {
      background-position: center center; } }
  @media only screen and (min-width: 992px) {
    .section-parallax .p_image_one {
      background-position: center center; } }
  .section-parallax img {
    display: block;
    position: absolute;
    width: 100%;
    max-width: 1000%;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    z-index: 400;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    margin-left: 0%; }
    @media only screen and (min-width: 0px) {
      .section-parallax img {
        min-height: 200%;
        width: auto;
        margin-left: -100%; } }
    @media only screen and (min-width: 481px) {
      .section-parallax img {
        width: auto;
        min-height: 200%;
        margin-left: -100%; } }
    @media only screen and (min-width: 769px) {
      .section-parallax img {
        width: 100%;
        height: auto;
        min-height: 100%;
        margin-left: 0%; } }
    @media only screen and (min-width: 992px) {
      .section-parallax img {
        height: auto; } }
  .section-parallax .parallax-text {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    left: 0px;
    right: 0px;
    display: inline-block;
    z-index: 400;
    transition: all 5000ms ease-in-out 0s;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 1px 1px 3px rgba(42, 48, 51, 0);
    margin-top: 20px;
    font-size: 36px; }
  .section-parallax .parallax-link {
    position: absolute;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    bottom: 25px;
    z-index: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 22px;
    line-height: 22px;
    padding: 1em 2em 1em 2em;
    font-weight: 200;
    text-transform: uppercase;
    border: 1px solid rgba(255, 255, 255, 0.9);
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0);
    left: 50%;
    transform: translate(-50%, 0%); }
  .section-parallax .parallax-video {
    display: block;
    position: absolute;
    width: 100%;
    max-width: 1000%;
    height: 200%;
    min-width: 100%;
    min-height: 100%;
    z-index: 400;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    margin-left: 0%;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0); }
    .section-parallax .parallax-video video {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0);
      position: relative;
      z-index: 405;
      width: 100%;
      min-width: 100%;
      min-height: 100%;
      max-width: 1000%;
      height: auto;
      object-fit: cover; }
      @media only screen and (min-width: 0px) {
        .section-parallax .parallax-video video {
          display: none; } }
      @media only screen and (min-width: 481px) {
        .section-parallax .parallax-video video {
          display: none; } }
      @media only screen and (min-width: 769px) {
        .section-parallax .parallax-video video {
          display: block;
          width: auto;
          height: 100%;
          min-height: 100%;
          margin-left: 0%; } }
      @media only screen and (min-width: 992px) {
        .section-parallax .parallax-video video {
          display: block;
          width: auto;
          height: 100%;
          min-height: 100%;
          margin-left: 0%; } }
    .section-parallax .parallax-video .p_image {
      display: block;
      position: absolute;
      width: 100%;
      max-width: 1000%;
      height: 100%;
      min-width: 100%;
      min-height: 100%;
      z-index: 401;
      top: 0%;
      left: 0%;
      transform: translate(0%, 0%);
      margin-left: 0%; }
      @media only screen and (min-width: 0px) {
        .section-parallax .parallax-video .p_image {
          display: block; } }
      @media only screen and (min-width: 481px) {
        .section-parallax .parallax-video .p_image {
          display: block; } }
      @media only screen and (min-width: 769px) {
        .section-parallax .parallax-video .p_image {
          display: none; } }
      @media only screen and (min-width: 992px) {
        .section-parallax .parallax-video .p_image {
          display: none; } }

/*********************************************
	Parallax Styles
*********************************************/
.section-sweepstakes {
  text-align: center;
  min-height: 550px; }
  .section-sweepstakes .image-grid {
    display: block;
    position: relative;
    width: 100%;
    height: auto; }
    .section-sweepstakes .image-grid .image-slot {
      display: inline-block;
      position: relative;
      width: 33.3333%;
      float: left;
      height: auto; }
      .section-sweepstakes .image-grid .image-slot div.inner {
        display: block;
        position: relative;
        margin-right: 10px;
        margin-bottom: 10px; }
        .section-sweepstakes .image-grid .image-slot div.inner img {
          display: block;
          position: relative;
          width: 100%;
          height: auto; }
  .section-sweepstakes .sweep-img {
    display: block;
    float: right;
    width: 60%;
    margin-left: -2em;
    margin-bottom: -1em; }
  .section-sweepstakes .font-sweep {
    font-family: "FrutigerLTStd", Arial, sans-serif;
    font-weight: 300;
    color: #ef8522;
    font-size: 36px;
    text-transform: uppercase;
    line-height: 1.5em;
    text-align: left;
    margin-bottom: 20px; }
    .section-sweepstakes .font-sweep span {
      font-family: "FrutigerLTStd", Arial, sans-serif;
      font-weight: 900; }
    .section-sweepstakes .font-sweep .gopro {
      text-transform: none; }
    @media only screen and (min-width: 0px) {
      .section-sweepstakes .font-sweep {
        font-size: 24px; } }
    @media only screen and (min-width: 481px) {
      .section-sweepstakes .font-sweep {
        font-size: 28px; } }
    @media only screen and (min-width: 769px) {
      .section-sweepstakes .font-sweep {
        font-size: 32px; } }
    @media only screen and (min-width: 992px) {
      .section-sweepstakes .font-sweep {
        font-size: 36px; } }
  .section-sweepstakes .font-facebook {
    display: block;
    font-family: "FrutigerLTStd Cond", "FrutigerLTStd", Arial, sans-serif;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
    color: #7f7f7f;
    font-size: 30px;
    text-align: left;
    vertical-align: middle; }
    .section-sweepstakes .font-facebook a {
      color: #7f7f7f;
      text-decoration: none;
      transition: all 300ms ease-in-out 0s; }
      .section-sweepstakes .font-facebook a:hover {
        color: #004c8b; }
    .section-sweepstakes .font-facebook span {
      display: inline-block;
      vertical-align: middle; }
    .section-sweepstakes .font-facebook img {
      display: inline-block;
      vertical-align: middle;
      width: 23px;
      height: 23px;
      margin-top: -1px;
      margin-left: 10px; }

/*
Listbox Format

<ul class="listbox listbox_2">
    <li><a href="" title="">Title</a></li>
</ul>

*/
.listbox {
  list-style-position: inside;
  list-style-image: none;
  list-style-type: none;
  display: block;
  clear: both;
  height: auto;
  width: 100%;
  margin: 0px;
  padding-top: 15px;
  padding-right: 0px;
  padding-bottom: 15px;
  padding-left: 0px;
  margin-top: 90px;
  margin-bottom: 90px;
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 100; }

.listbox li {
  font-family: 'Oswald', sans-serif;
  text-align: center;
  display: inline-block;
  float: none;
  width: 25%;
  margin-right: 0%;
  margin-bottom: 15px;
  color: #000;
  background-color: transparent;
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: 400;
  text-transform: uppercase;
  border: 1px solid #000000;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  min-height: 200px; }
  @media only screen and (min-width: 0px) {
    .listbox li {
      display: block;
      float: none;
      width: 100%;
      margin-right: 0%;
      min-height: 200px; } }
  @media only screen and (min-width: 481px) {
    .listbox li {
      display: inline-block;
      width: auto;
      margin-right: 2%;
      min-height: 250px; } }
  @media only screen and (min-width: 769px) {
    .listbox li {
      display: inline-block;
      width: auto;
      margin-right: 2%;
      min-height: 300px; } }
  @media only screen and (min-width: 992px) {
    .listbox li {
      display: inline-block;
      width: auto;
      margin-right: 2%;
      min-height: 300px; } }

.listbox_2 li {
  width: 48%;
  margin-right: 2%;
  font-size: 1.6em; }
  @media only screen and (min-width: 0px) {
    .listbox_2 li {
      width: 98%;
      margin-right: 2%; } }
  @media only screen and (min-width: 481px) {
    .listbox_2 li {
      width: 45%;
      margin-right: 2%; } }
  @media only screen and (min-width: 769px) {
    .listbox_2 li {
      width: 45%;
      margin-right: 2%; } }
  @media only screen and (min-width: 992px) {
    .listbox_2 li {
      width: 45%;
      margin-right: 2%; } }

.listbox_3 li {
  width: 31%;
  margin-right: 2%;
  font-size: 1.6em; }
  @media only screen and (min-width: 0px) {
    .listbox_3 li {
      width: 98%;
      margin-right: 2%; } }
  @media only screen and (min-width: 481px) {
    .listbox_3 li {
      width: 48%;
      margin-right: 2%; } }
  @media only screen and (min-width: 769px) {
    .listbox_3 li {
      width: 31%;
      margin-right: 2%; } }
  @media only screen and (min-width: 992px) {
    .listbox_3 li {
      width: 31%;
      margin-right: 2%; } }

.listbox_4 li {
  width: 23%;
  margin-right: 2%;
  font-size: 1.6em; }
  @media only screen and (min-width: 0px) {
    .listbox_4 li {
      width: 98%;
      margin-right: 2%; } }
  @media only screen and (min-width: 481px) {
    .listbox_4 li {
      float: left;
      width: 48%;
      margin-right: 2%; } }
  @media only screen and (min-width: 769px) {
    .listbox_4 li {
      width: 23%;
      margin-right: 2%; } }
  @media only screen and (min-width: 992px) {
    .listbox_4 li {
      width: 23%;
      margin-right: 2%; } }

.listbox li span {
  display: block;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px; }

.listbox li a {
  display: block;
  position: relative;
  height: auto;
  width: 100%;
  line-height: 1em;
  font-size: 1.3em;
  padding-right: 15px;
  padding-left: 15px;
  position: absolute;
  text-align: center;
  -webkit-transition: all 700ms ease-in-out 0s;
  -moz-transition: all 700ms ease-in-out 0s;
  -ms-transition: all 700ms ease-in-out 0s;
  -o-transition: all 700ms ease-in-out 0s;
  transition: all 700ms ease-in-out 0s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);
  /* Safari and Chrome */
  -o-transform: translate(-50%, -50%);
  /* Opera */
  -moz-transform: translate(-50%, -50%);
  /* Firefox */ }
  @media only screen and (min-width: 0px) {
    .listbox li a {
      font-size: 1.3em; } }
  @media only screen and (min-width: 481px) {
    .listbox li a {
      font-size: 1.4em; } }
  @media only screen and (min-width: 769px) {
    .listbox li a {
      font-size: 1.5em; } }
  @media only screen and (min-width: 992px) {
    .listbox li a {
      font-size: 1.5em; } }

.listbox li:hover {
  background-color: #ef8522;
  color: #FFF; }

.listbox li:hover a {
  color: #FFF; }

.listbox:after {
  content: '';
  display: block;
  clear: both;
  width: 100%;
  position: relative; }

/*********************************************
	Lists
*********************************************/
ul {
  margin: 1em 0;
  padding: 0 0 0 40px;
  list-style-position: inside;
  list-style-image: none;
  list-style-type: square; }

ul ul {
  margin: 1em 0; }

ol {
  list-style-position: inside;
  list-style-image: none; }

.list-none {
  list-style-position: inside;
  list-style-image: none;
  list-style-type: none; }

/*********************************************
	Map Styles
*********************************************/
.map-wrap {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px; }
  @media only screen and (min-width: 481px) {
    .map-wrap {
      position: relative;
      height: auto; } }
  @media only screen and (min-width: 769px) {
    .map-wrap {
      position: relative;
      height: auto; } }
  @media only screen and (min-width: 992px) {
    .map-wrap {
      position: absolute;
      height: 100%; } }

.map-overlay {
  background-color: #666666;
  background-color: rgba(48, 74, 98, 0.9);
  display: block;
  height: 100%;
  width: 250px;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  position: absolute;
  z-index: 500;
  left: 0px;
  top: 0px;
  bottom: 0px; }
  @media only screen and (min-width: 481px) {
    .map-overlay {
      display: none; } }
  @media only screen and (min-width: 769px) {
    .map-overlay {
      display: none; } }
  @media only screen and (min-width: 992px) {
    .map-overlay {
      display: none; } }
  @media only screen and (min-width: 1024px) {
    .map-overlay {
      display: block; } }

.map-overlay .inner {
  display: block; }

.map-overlay .inner a {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer; }

.map-overlay .inner a:hover {
  color: #971b1e; }

.map_title {
  font-size: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 700;
  margin-bottom: 5px; }

#map,
.gmap,
.acf-map {
  height: 200px;
  width: 100%;
  color: #000;
  text-align: center;
  float: none;
  position: relative;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 0px solid #3e3d37;
  background-color: #CCC;
  display: block;
  margin-right: auto;
  margin-left: auto; }

#map.single,
.gmap.single,
.acf-map.single {
  min-height: 200px;
  height: 200px; }

.map-full-wrapper {
  min-height: 200px;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px; }
  @media only screen and (min-width: 481px) {
    .map-full-wrapper {
      position: relative;
      height: auto; } }
  @media only screen and (min-width: 1024px) {
    .map-full-wrapper {
      position: absolute;
      height: 100%; } }

.map-full-wrapper:after {
  content: '';
  clear: both;
  display: block; }

.map-full-wrapper iframe,
.map-full-wrapper object,
.map-full-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.map-full-wrapper #map.single,
.map-full-wrapper .acf-map.single {
  position: absolute;
  min-height: 500px;
  height: 100%;
  width: 100%; }
  @media only screen and (min-width: 481px) {
    .map-full-wrapper #map.single,
    .map-full-wrapper .acf-map.single {
      position: relative;
      height: auto; } }
  @media only screen and (min-width: 1024px) {
    .map-full-wrapper #map.single,
    .map-full-wrapper .acf-map.single {
      position: absolute;
      height: 100%; } }

#map iframe,
#map object,
#map embed,
.gmap iframe,
.gmap object,
.gmap embed {
  position: relative;
  width: 100%;
  height: 100%; }

#map_info {
  display: block;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0px;
  position: relative;
  z-index: 500; }

.gm-style-iw div {
  display: block; }

.gm-style-iw {
  display: block;
  text-align: left;
  min-width: 100px; }

.gm-style-iw h4,
.gm-style-iw p {
  margin: 0;
  padding: 0;
  width: auto; }

.gm-style-iw p {
  font-size: 14px;
  display: block;
  padding-top: 1px;
  padding-bottom: 2px; }

.gm-style-iw h1,
.gm-style-iw h2,
.gm-style-iw h3,
.gm-style-iw h4 {
  color: #971b1e;
  font-weight: bold;
  margin-bottom: 5px; }

.gm-style-iw h1 {
  font-size: 22px; }

.gm-style-iw h2 {
  font-size: 20px; }

.gm-style-iw h3 {
  font-size: 18px; }

.gm-style-iw h4 {
  font-size: 16px; }

.gm-style-iw a {
  color: #4272db; }

.gm-style-iw .open {
  color: #00CC00; }

.social_wrap {
  display: inline-block;
  clear: none;
  text-align: left;
  margin: 0px;
  padding-top: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 10px; }

.socialbox {
  width: auto;
  margin-top: 10px;
  margin-bottom: 5px;
  display: inline-block;
  padding-right: 0px;
  padding-left: 0px; }
  @media only screen and (min-width: 481px) {
    .socialbox {
      margin-top: 10px; } }
  @media only screen and (min-width: 769px) {
    .socialbox {
      margin-top: 10px; } }
  @media only screen and (min-width: 992px) {
    .socialbox {
      margin-top: 10px; } }

.social_slot {
  float: left;
  display: inline-block;
  height: 27px;
  width: 27px;
  padding: 0px;
  background-color: #5e5e5e;
  -webkit-border-radius: 0%;
  -khtml-border-radius: 0%;
  -moz-border-radius: 0%;
  -ms-border-radius: 0%;
  -o-border-radius: 0%;
  border-radius: 0%;
  -webkit-background-clip: padding-box;
  -khtml-background-clip: padding-box;
  -moz-background-clip: padding-box;
  -ms-background-clip: padding-box;
  -o-background-clip: padding-box;
  background-clip: padding-box;
  position: relative;
  margin-top: 0px;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-left: 0px; }
  .social_slot:hover {
    background-color: #ef8522; }

.social_slot img {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);
  /* Safari and Chrome */
  -o-transform: translate(-50%, -50%);
  /* Opera */
  -moz-transform: translate(-50%, -50%);
  /* Firefox */ }

/*
.social_slot.facebook {
	background-color: #3c5b9b;
}
.social_slot.twitter {
	background-color: #2daae1;
}
.social_slot.linkedin {
	background-color: #0173b2;
}
.social_slot.google {
	background-color: #DC4E41;
}
.social_slot.youtube {
	background-color: #cd201f;
}
*/
.scroll-fade {
  opacity: 0;
  transition: opacity 10ms ease-in-out 0s; }

.scroll-fade.in {
  opacity: 1; }

/** Collapsible Panel**/
.collapsible,
.page_collapsible {
  padding: 10px;
  height: 20px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  font-size: 1.1em;
  cursor: pointer;
  display: inline-block;
  clear: none;
  margin-top: 0px;
  margin-right: 0;
  margin-bottom: 10px;
  margin-left: 0;
  min-width: 50%;
  border-top-width: 0px;
  border-top-style: solid;
  border-top-color: #666;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #666; }
  @media only screen and (min-width: 481px) {
    .collapsible,
    .page_collapsible {
      padding: 0px;
      min-width: 100%; } }
  @media only screen and (min-width: 769px) {
    .collapsible,
    .page_collapsible {
      padding: 5px;
      min-width: 50%; } }
  @media only screen and (min-width: 992px) {
    .collapsible,
    .page_collapsible {
      padding: 5px;
      min-width: 50%; } }

.collapsible:hover,
.page_collapsible:hover {
  background-color: #f2f0e4; }

.optionsbox .quote_text .collapsible {
  font-size: 1em; }

.collapsible_container {
  padding-top: 15px;
  padding-right: 0px;
  padding-bottom: 15px;
  padding-left: 0px; }

.collapsible_container:after {
  content: '';
  display: block;
  clear: both; }

.collapse-open {
  margin-bottom: 0px;
  background-color: #f2f0e4;
  color: #000; }

.collapse-open span {
  display: block;
  float: right;
  padding: 10px; }

.collapse-open {
  background-image: url(../../assets//dist/img/icons/minus.png);
  background-repeat: no-repeat;
  background-position: right center; }

.collapse-close {
  background-image: url(../../assets//dist/img/icons/plus.png);
  background-repeat: no-repeat;
  background-position: right center; }

/*********************************************
	Contact Styles
*********************************************/
.contact_form {
  display: block;
  margin-top: 10px;
  margin-bottom: 0px; }

.contact_form p em {
  font-style: italic;
  text-transform: none;
  font-weight: normal; }

.contact_form label {
  color: #000;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  width: 100%;
  max-width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 15px;
  margin-bottom: 0px; }

.contact_form input,
.contact_form textarea {
  font-size: 16px;
  width: 100%;
  max-width: 100%; }

.contact_form input[type="submit"] {
  font-size: 30px;
  width: auto; }

.contact_info {
  display: block;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px; }

.contact_info strong {
  text-transform: uppercase; }

.contact_info a {
  color: #971b1e;
  font-weight: 600; }

/*********************************************
	Widget Styling
*********************************************/
/*********************************************
	Video Styles
*********************************************/
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

iframe {
  max-width: 100%; }

/*********************************************
	OWL Carousel Core
*********************************************/
/**
 * Owl Carousel v2.3.0
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */ }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
   *  Owl Carousel - Animate Plugin
   */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
   * 	Owl Carousel - Auto Height Plugin
   */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
   * 	Owl Carousel - Lazy Load Plugin
   */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

/*
   * 	Owl Carousel - Video Plugin
   */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/** Base Styles **/
.owl-sub {
  display: block;
  height: auto;
  width: 100%;
  z-index: 500;
  -webkit-transition: all 500ms ease-in-out 300ms;
  -moz-transition: all 500ms ease-in-out 300ms;
  -ms-transition: all 500ms ease-in-out 300ms;
  -o-transition: all 500ms ease-in-out 300ms;
  transition: all 500ms ease-in-out 300ms;
  text-align: center;
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);
  /* Safari and Chrome */
  -o-transform: translate(-50%, -50%);
  /* Opera */
  -moz-transform: translate(-50%, -50%);
  /* Firefox */
  margin-right: auto;
  margin-left: auto;
  max-width: 1080px;
  padding-left: 7em;
  padding-right: 7em; }

.owl-sub.left {
  text-align: left; }

.owl-sub.center {
  text-align: center; }

.owl-sub.right {
  text-align: right; }

.owl-carousel .active .owl-sub {
  -webkit-transition: all 500ms ease-in-out 300ms;
  -moz-transition: all 500ms ease-in-out 300ms;
  -ms-transition: all 500ms ease-in-out 300ms;
  -o-transition: all 500ms ease-in-out 300ms;
  transition: all 500ms ease-in-out 300ms; }

.owl-carousel .fadeout .owl-sub {
  -webkit-transition: all 500ms ease-in-out 300ms;
  -moz-transition: all 500ms ease-in-out 300ms;
  -ms-transition: all 500ms ease-in-out 300ms;
  -o-transition: all 500ms ease-in-out 300ms;
  transition: all 500ms ease-in-out 300ms; }

.owl-carousel .owl-title {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  font-size: 28px;
  font-weight: 200;
  color: #FFF;
  padding-bottom: 5px;
  text-transform: none;
  line-height: 1em;
  text-transform: uppercase; }
  @media only screen and (min-width: 0px) {
    .owl-carousel .owl-title {
      font-size: 40px;
      line-height: 50px;
      padding-bottom: 10px; } }
  @media only screen and (min-width: 481px) {
    .owl-carousel .owl-title {
      font-size: 65px;
      line-height: 65px;
      padding-bottom: 15px; } }
  @media only screen and (min-width: 769px) {
    .owl-carousel .owl-title {
      font-size: 85px;
      line-height: 85px;
      padding-bottom: 20px; } }
  @media only screen and (min-width: 992px) {
    .owl-carousel .owl-title {
      font-size: 85px;
      line-height: 85px;
      padding-bottom: 25px; } }

.owl-carousel .owl-title-alt {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  font-size: 28px;
  font-weight: 200;
  color: #FFF;
  padding-bottom: 5px;
  text-transform: none;
  line-height: 1em;
  text-transform: uppercase; }
  @media only screen and (min-width: 0px) {
    .owl-carousel .owl-title-alt {
      font-size: 30px;
      line-height: 1.5em;
      padding-bottom: 5px; } }
  @media only screen and (min-width: 481px) {
    .owl-carousel .owl-title-alt {
      font-size: 40px;
      line-height: 1.5em;
      padding-bottom: 10px; } }
  @media only screen and (min-width: 769px) {
    .owl-carousel .owl-title-alt {
      font-size: 50px;
      line-height: 1.5em;
      padding-bottom: 15px; } }
  @media only screen and (min-width: 992px) {
    .owl-carousel .owl-title-alt {
      font-size: 60px;
      line-height: 1.5em;
      padding-bottom: 15px; } }

.owl-carousel .owl-subtitle {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  font-size: 16px;
  font-weight: 200;
  color: #FFF;
  padding-bottom: 5px;
  text-transform: none;
  line-height: 1em;
  text-transform: uppercase; }
  @media only screen and (min-width: 0px) {
    .owl-carousel .owl-subtitle {
      font-size: 16px;
      padding-bottom: 5px; } }
  @media only screen and (min-width: 481px) {
    .owl-carousel .owl-subtitle {
      font-size: 18px;
      padding-bottom: 10px; } }
  @media only screen and (min-width: 769px) {
    .owl-carousel .owl-subtitle {
      font-size: 20px;
      padding-bottom: 15px; } }
  @media only screen and (min-width: 992px) {
    .owl-carousel .owl-subtitle {
      font-size: 20px;
      padding-bottom: 20px; } }

.owl-carousel .owl-text {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  color: #FFF;
  font-size: 26px;
  line-height: 1.75em;
  font-weight: 300;
  padding-top: 10px;
  padding-bottom: 10px; }
  @media only screen and (min-width: 0px) {
    .owl-carousel .owl-text {
      font-size: 18px; } }
  @media only screen and (min-width: 481px) {
    .owl-carousel .owl-text {
      font-size: 22px; } }
  @media only screen and (min-width: 769px) {
    .owl-carousel .owl-text {
      font-size: 24px; } }
  @media only screen and (min-width: 992px) {
    .owl-carousel .owl-text {
      font-size: 26px; } }

.owl-carousel .owl-text p {
  font-size: 16px;
  font-weight: 300; }

.owl-carousel a.owl-link {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border: 1px solid #FFF;
  background-color: #FFF;
  padding: 20px 40px 17px 40px;
  color: #004c8b; }
  .owl-carousel a.owl-link:hover {
    color: #004c8b;
    background-color: rgba(255, 255, 255, 0.4); }

.owl-item-video,
.owl-slide-video {
  display: block; }
  @media only screen and (min-width: 0px) {
    .owl-item-video,
    .owl-slide-video {
      display: none; } }
  @media only screen and (min-width: 481px) {
    .owl-item-video,
    .owl-slide-video {
      display: none; } }
  @media only screen and (min-width: 769px) {
    .owl-item-video,
    .owl-slide-video {
      display: none; } }
  @media only screen and (min-width: 992px) {
    .owl-item-video,
    .owl-slide-video {
      display: block; } }

.owl-slide-video .bg-video-wrap {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1; }

.owl-slide-video .bg-video-wrap:before {
  content: '';
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px; }

.owl-slide-video .bg-video-wrap .video-js {
  min-width: 100%;
  position: absolute;
  background-size: cover;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  min-width: 100% !important;
  min-height: 100% !important;
  max-width: 1000% !important;
  margin: 0px !important;
  padding: 0px !important; }

.owl-slide-video .bg-video-wrap video {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  background-color: transparent;
  min-width: 100% !important;
  min-height: 100% !important;
  max-width: 1000% !important; }

@media (min-aspect-ratio: 16 / 9) {
  .owl-slide-video .bg-video-wrap video {
    height: 300%;
    top: -100%; } }

@media (max-aspect-ratio: 16 / 9) {
  .owl-slide-video .bg-video-wrap video {
    width: 300%;
    left: -100%; } }

.owl-simple .owl-slide-video .bg-video-wrap video {
  height: auto;
  width: 100%;
  left: 0%; }

.owl-video-frame {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px; }

.owl-video-frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/*********************************************
	OWL Carousel Core
*********************************************/
/**
 * Owl Carousel v2.3.0
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */ }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
   *  Owl Carousel - Animate Plugin
   */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
   * 	Owl Carousel - Auto Height Plugin
   */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
   * 	Owl Carousel - Lazy Load Plugin
   */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

/*
   * 	Owl Carousel - Video Plugin
   */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/** Base Styles **/
.owl-sub {
  display: block;
  height: auto;
  width: 100%;
  z-index: 500;
  -webkit-transition: all 500ms ease-in-out 300ms;
  -moz-transition: all 500ms ease-in-out 300ms;
  -ms-transition: all 500ms ease-in-out 300ms;
  -o-transition: all 500ms ease-in-out 300ms;
  transition: all 500ms ease-in-out 300ms;
  text-align: center;
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);
  /* Safari and Chrome */
  -o-transform: translate(-50%, -50%);
  /* Opera */
  -moz-transform: translate(-50%, -50%);
  /* Firefox */
  margin-right: auto;
  margin-left: auto;
  max-width: 1080px;
  padding-left: 7em;
  padding-right: 7em; }

.owl-sub.left {
  text-align: left; }

.owl-sub.center {
  text-align: center; }

.owl-sub.right {
  text-align: right; }

.owl-carousel .active .owl-sub {
  -webkit-transition: all 500ms ease-in-out 300ms;
  -moz-transition: all 500ms ease-in-out 300ms;
  -ms-transition: all 500ms ease-in-out 300ms;
  -o-transition: all 500ms ease-in-out 300ms;
  transition: all 500ms ease-in-out 300ms; }

.owl-carousel .fadeout .owl-sub {
  -webkit-transition: all 500ms ease-in-out 300ms;
  -moz-transition: all 500ms ease-in-out 300ms;
  -ms-transition: all 500ms ease-in-out 300ms;
  -o-transition: all 500ms ease-in-out 300ms;
  transition: all 500ms ease-in-out 300ms; }

.owl-carousel .owl-title {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  font-size: 28px;
  font-weight: 200;
  color: #FFF;
  padding-bottom: 5px;
  text-transform: none;
  line-height: 1em;
  text-transform: uppercase; }
  @media only screen and (min-width: 0px) {
    .owl-carousel .owl-title {
      font-size: 40px;
      line-height: 50px;
      padding-bottom: 10px; } }
  @media only screen and (min-width: 481px) {
    .owl-carousel .owl-title {
      font-size: 65px;
      line-height: 65px;
      padding-bottom: 15px; } }
  @media only screen and (min-width: 769px) {
    .owl-carousel .owl-title {
      font-size: 85px;
      line-height: 85px;
      padding-bottom: 20px; } }
  @media only screen and (min-width: 992px) {
    .owl-carousel .owl-title {
      font-size: 85px;
      line-height: 85px;
      padding-bottom: 25px; } }

.owl-carousel .owl-title-alt {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  font-size: 28px;
  font-weight: 200;
  color: #FFF;
  padding-bottom: 5px;
  text-transform: none;
  line-height: 1em;
  text-transform: uppercase; }
  @media only screen and (min-width: 0px) {
    .owl-carousel .owl-title-alt {
      font-size: 30px;
      line-height: 1.5em;
      padding-bottom: 5px; } }
  @media only screen and (min-width: 481px) {
    .owl-carousel .owl-title-alt {
      font-size: 40px;
      line-height: 1.5em;
      padding-bottom: 10px; } }
  @media only screen and (min-width: 769px) {
    .owl-carousel .owl-title-alt {
      font-size: 50px;
      line-height: 1.5em;
      padding-bottom: 15px; } }
  @media only screen and (min-width: 992px) {
    .owl-carousel .owl-title-alt {
      font-size: 60px;
      line-height: 1.5em;
      padding-bottom: 15px; } }

.owl-carousel .owl-subtitle {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  font-size: 16px;
  font-weight: 200;
  color: #FFF;
  padding-bottom: 5px;
  text-transform: none;
  line-height: 1em;
  text-transform: uppercase; }
  @media only screen and (min-width: 0px) {
    .owl-carousel .owl-subtitle {
      font-size: 16px;
      padding-bottom: 5px; } }
  @media only screen and (min-width: 481px) {
    .owl-carousel .owl-subtitle {
      font-size: 18px;
      padding-bottom: 10px; } }
  @media only screen and (min-width: 769px) {
    .owl-carousel .owl-subtitle {
      font-size: 20px;
      padding-bottom: 15px; } }
  @media only screen and (min-width: 992px) {
    .owl-carousel .owl-subtitle {
      font-size: 20px;
      padding-bottom: 20px; } }

.owl-carousel .owl-text {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  color: #FFF;
  font-size: 26px;
  line-height: 1.75em;
  font-weight: 300;
  padding-top: 10px;
  padding-bottom: 10px; }
  @media only screen and (min-width: 0px) {
    .owl-carousel .owl-text {
      font-size: 18px; } }
  @media only screen and (min-width: 481px) {
    .owl-carousel .owl-text {
      font-size: 22px; } }
  @media only screen and (min-width: 769px) {
    .owl-carousel .owl-text {
      font-size: 24px; } }
  @media only screen and (min-width: 992px) {
    .owl-carousel .owl-text {
      font-size: 26px; } }

.owl-carousel .owl-text p {
  font-size: 16px;
  font-weight: 300; }

.owl-carousel a.owl-link {
  font-family: "FrutigerLTStd", Arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border: 1px solid #FFF;
  background-color: #FFF;
  padding: 20px 40px 17px 40px;
  color: #004c8b; }
  .owl-carousel a.owl-link:hover {
    color: #004c8b;
    background-color: rgba(255, 255, 255, 0.4); }

.owl-item-video,
.owl-slide-video {
  display: block; }
  @media only screen and (min-width: 0px) {
    .owl-item-video,
    .owl-slide-video {
      display: none; } }
  @media only screen and (min-width: 481px) {
    .owl-item-video,
    .owl-slide-video {
      display: none; } }
  @media only screen and (min-width: 769px) {
    .owl-item-video,
    .owl-slide-video {
      display: none; } }
  @media only screen and (min-width: 992px) {
    .owl-item-video,
    .owl-slide-video {
      display: block; } }

.owl-slide-video .bg-video-wrap {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1; }

.owl-slide-video .bg-video-wrap:before {
  content: '';
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px; }

.owl-slide-video .bg-video-wrap .video-js {
  min-width: 100%;
  position: absolute;
  background-size: cover;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  min-width: 100% !important;
  min-height: 100% !important;
  max-width: 1000% !important;
  margin: 0px !important;
  padding: 0px !important; }

.owl-slide-video .bg-video-wrap video {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  background-color: transparent;
  min-width: 100% !important;
  min-height: 100% !important;
  max-width: 1000% !important; }

@media (min-aspect-ratio: 16 / 9) {
  .owl-slide-video .bg-video-wrap video {
    height: 300%;
    top: -100%; } }

@media (max-aspect-ratio: 16 / 9) {
  .owl-slide-video .bg-video-wrap video {
    width: 300%;
    left: -100%; } }

.owl-simple .owl-slide-video .bg-video-wrap video {
  height: auto;
  width: 100%;
  left: 0%; }

.owl-video-frame {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px; }

.owl-video-frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/** fold Slider Slide Styles**/
.owl-carousel.owl-slider {
  display: block;
  height: auto;
  width: 100%;
  overflow: hidden;
  /*INLINE NAV*/ }
  @media only screen and (min-width: 0px) {
    .owl-carousel.owl-slider {
      height: auto;
      max-height: 3000px; } }
  @media only screen and (min-width: 481px) {
    .owl-carousel.owl-slider {
      height: auto;
      max-height: 3000px; } }
  @media only screen and (min-width: 769px) {
    .owl-carousel.owl-slider {
      height: auto;
      max-height: 3000px; } }
  @media only screen and (min-width: 992px) {
    .owl-carousel.owl-slider {
      height: auto;
      max-height: 3000px; } }
  @media only screen and (min-width: 1024px) {
    .owl-carousel.owl-slider {
      height: auto;
      max-height: 3000px; } }
  @media only screen and (min-width: 1232px) {
    .owl-carousel.owl-slider {
      height: auto;
      max-height: 3000px; } }
  .owl-carousel.owl-slider .owl-stage-outer {
    min-height: 0px;
    overflow: hidden; }
  .owl-carousel.owl-slider .owl-item .owl-slide {
    position: relative;
    min-height: 300px;
    height: auto;
    overflow: hidden;
    text-align: center;
    -webkit-transition: all 500ms ease-in-out 0ms;
    -moz-transition: all 500ms ease-in-out 0ms;
    -ms-transition: all 500ms ease-in-out 0ms;
    -o-transition: all 500ms ease-in-out 0ms;
    transition: all 500ms ease-in-out 0ms; }
  .owl-carousel.owl-slider .owl-sub {
    display: block;
    height: auto;
    width: auto;
    z-index: 500;
    text-align: center;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    max-width: 1160px;
    padding-left: 7em;
    padding-right: 7em;
    top: 0%;
    left: 0%;
    padding-top: 2em;
    padding-bottom: 0em;
    overflow: hidden;
    transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    /* IE 9 */
    -webkit-transform: translate(0%, 0%);
    /* Safari and Chrome */
    -o-transform: translate(0%, 0%);
    /* Opera */
    -moz-transform: translate(0%, 0%);
    /* Firefox */
    -webkit-transition: all 500ms ease-in-out 0ms;
    -moz-transition: all 500ms ease-in-out 0ms;
    -ms-transition: all 500ms ease-in-out 0ms;
    -o-transition: all 500ms ease-in-out 0ms;
    transition: all 500ms ease-in-out 0ms; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-slider .owl-sub {
        padding-left: 4em;
        padding-right: 4em;
        padding-top: 6%; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-slider .owl-sub {
        padding-left: 5em;
        padding-right: 5em;
        padding-top: 10%; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-slider .owl-sub {
        padding-left: 5.5em;
        padding-right: 5.5em;
        padding-top: 12%; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-slider .owl-sub {
        padding-left: 6em;
        padding-right: 6em;
        padding-top: 14%;
        padding-bottom: 1%; } }
    @media only screen and (min-width: 1024px) {
      .owl-carousel.owl-slider .owl-sub {
        padding-left: 6em;
        padding-right: 6em;
        padding-top: 16%;
        padding-bottom: 3%; } }
    @media only screen and (min-width: 1232px) {
      .owl-carousel.owl-slider .owl-sub {
        padding-left: 6em;
        padding-right: 6em;
        padding-top: 18%;
        padding-bottom: 6%; } }
    @media only screen and (min-width: 1232px) {
      .owl-carousel.owl-slider .owl-sub {
        padding-left: 6em;
        padding-right: 6em;
        padding-top: 20%;
        padding-bottom: 9%; } }
  .owl-carousel.owl-slider .owl-item img.owl-bg {
    max-width: 1000%;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    z-index: 400; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-slider .owl-item img.owl-bg {
        height: auto;
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        max-width: 1000%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-slider .owl-item img.owl-bg {
        height: auto;
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        max-width: 1000%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-slider .owl-item img.owl-bg {
        height: auto;
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        max-width: 1000%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-slider .owl-item img.owl-bg {
        height: auto;
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        max-width: 1000%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } }
    @media only screen and (min-width: 1024px) {
      .owl-carousel.owl-slider .owl-item img.owl-bg {
        height: auto;
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        max-width: 1000%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } }
  .owl-carousel.owl-slider .owl-item img.owl-shadow {
    height: auto;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    z-index: 450;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  @media only screen and (min-width: 769px) {
    .owl-carousel.owl-slider .owl-stage {
      height: auto; }
    .owl-carousel.owl-slider .owl-item {
      height: auto; }
    .owl-carousel.owl-slider .seq-slide {
      height: auto; } }
  @media only screen and (min-width: 1024px) {
    .owl-carousel.owl-slider .owl-stage {
      height: auto; }
    .owl-carousel.owl-slider .owl-item {
      height: auto; }
    .owl-carousel.owl-slider .seq-slide {
      height: auto; } }
  @media only screen and (min-width: 1232px) {
    .owl-carousel.owl-slider .owl-stage {
      height: auto; }
    .owl-carousel.owl-slider .owl-item {
      height: auto; }
    .owl-carousel.owl-slider .seq-slide {
      height: auto; } }
  .owl-carousel.owl-slider .owl-title {
    opacity: 0;
    -webkit-transition: opacity 1000ms ease-in-out 0s;
    -moz-transition: opacity 1000ms ease-in-out 0s;
    -ms-transition: opacity 1000ms ease-in-out 0s;
    -o-transition: opacity 1000ms ease-in-out 0s;
    transition: opacity 1000ms ease-in-out 0s; }
  .owl-carousel.owl-slider .owl-item.active .owl-title {
    opacity: 1; }
  .owl-carousel.owl-slider .owl-text {
    opacity: 0;
    -webkit-transition: opacity 1000ms ease-in-out 0s;
    -moz-transition: opacity 1000ms ease-in-out 0s;
    -ms-transition: opacity 1000ms ease-in-out 0s;
    -o-transition: opacity 1000ms ease-in-out 0s;
    transition: opacity 1000ms ease-in-out 0s; }
  .owl-carousel.owl-slider .owl-item.active .owl-text {
    opacity: 1; }
  .owl-carousel.owl-slider .owl-link {
    opacity: 0;
    -webkit-transition: opacity 1000ms ease-in-out 0s;
    -moz-transition: opacity 1000ms ease-in-out 0s;
    -ms-transition: opacity 1000ms ease-in-out 0s;
    -o-transition: opacity 1000ms ease-in-out 0s;
    transition: opacity 1000ms ease-in-out 0s; }
  .owl-carousel.owl-slider .owl-item.active .owl-link {
    display: inline-block;
    opacity: 1;
    position: relative;
    margin-bottom: 25px;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 15px 40px 12px 40px; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-slider .owl-item.active .owl-link {
        font-size: 14px;
        line-height: 12px;
        margin-bottom: 10px; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-slider .owl-item.active .owl-link {
        font-size: 16px;
        line-height: 14px;
        margin-bottom: 15px; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-slider .owl-item.active .owl-link {
        font-size: 18px;
        line-height: 16px;
        margin-bottom: 20px; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-slider .owl-item.active .owl-link {
        font-size: 20px;
        line-height: 18px;
        margin-bottom: 25px; } }
  .owl-carousel.owl-slider .owl-nav {
    position: absolute;
    background-color: transparent;
    background-image: none;
    top: 50%;
    left: 0px;
    right: 0px; }
  .owl-carousel.owl-slider .owl-nav [class*=owl-] {
    position: absolute;
    background-color: transparent;
    background-image: none;
    padding-top: 0px;
    padding-right: 3px;
    padding-bottom: 0px;
    padding-left: 3px; }
  .owl-carousel.owl-slider .owl-dots {
    position: absolute;
    bottom: 0px;
    margin-left: auto;
    left: 0%;
    margin-right: auto;
    right: 0px;
    display: block;
    height: 20px;
    width: auto;
    line-height: normal;
    text-align: center; }
  @media only screen and (min-width: 0px) {
    .owl-carousel.owl-slider .owl-dots {
      position: absolute;
      bottom: 20px; } }
  @media only screen and (min-width: 481px) {
    .owl-carousel.owl-slider .owl-dots {
      position: absolute;
      bottom: 20px; } }
  @media only screen and (min-width: 769px) {
    .owl-carousel.owl-slider .owl-dots {
      position: absolute;
      bottom: 40px; } }
  .owl-carousel.owl-slider .owl-nav.disabled,
  .owl-carousel.owl-slider .owl-dots.disabled {
    display: none; }
  .owl-carousel.owl-slider .owl-dots .owl-dot {
    display: inline-block;
    margin: 0px;
    padding: 0px;
    height: auto;
    width: auto;
    height: 20px;
    margin-right: 15px; }
  .owl-carousel.owl-slider .owl-dots .owl-dot:last-child {
    margin-right: 0px; }
  .owl-carousel.owl-slider .owl-dots .owl-dot span {
    display: block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, 0.5);
    height: 10px;
    width: 10px;
    border: 0px solid #7f7f7f; }
  .owl-carousel.owl-slider .owl-dots .owl-dot.active span {
    background-color: #2f94bf; }
  .owl-carousel.owl-slider .owl-nav .owl-prev,
  .owl-carousel.owl-slider .owl-nav .owl-next {
    height: 4em;
    width: 4em;
    opacity: 0;
    transition: all 200ms ease-in-out 0s;
    border: 3px solid #FFF;
    border-radius: 50%; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-slider .owl-nav .owl-prev,
      .owl-carousel.owl-slider .owl-nav .owl-next {
        height: 2em;
        width: 2em;
        border: 2px solid #FFF; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-slider .owl-nav .owl-prev,
      .owl-carousel.owl-slider .owl-nav .owl-next {
        height: 2em;
        width: 2em;
        border: 2px solid #FFF; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-slider .owl-nav .owl-prev,
      .owl-carousel.owl-slider .owl-nav .owl-next {
        height: 3em;
        width: 3em;
        border: 3px solid #FFF; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-slider .owl-nav .owl-prev,
      .owl-carousel.owl-slider .owl-nav .owl-next {
        height: 4em;
        width: 4em; } }
    .owl-carousel.owl-slider .owl-nav .owl-prev:hover,
    .owl-carousel.owl-slider .owl-nav .owl-next:hover {
      background-color: rgba(255, 255, 255, 0.4); }
  .owl-carousel.owl-slider .owl-nav .owl-prev {
    left: 3em; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-slider .owl-nav .owl-prev {
        left: 1.5em; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-slider .owl-nav .owl-prev {
        left: 2em; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-slider .owl-nav .owl-prev {
        left: 2.5em; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-slider .owl-nav .owl-prev {
        left: 3em; } }
  .owl-carousel.owl-slider .owl-nav .owl-next {
    right: 3em; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-slider .owl-nav .owl-next {
        right: 1.5em; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-slider .owl-nav .owl-next {
        right: 2em; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-slider .owl-nav .owl-next {
        right: 2.5em; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-slider .owl-nav .owl-next {
        right: 3em; } }
  .owl-carousel.owl-slider:hover .owl-nav .owl-prev {
    opacity: 1; }
  .owl-carousel.owl-slider:hover .owl-nav .owl-next {
    opacity: 1; }
  .owl-carousel.owl-slider .owl-prev:after,
  .owl-carousel.owl-slider .owl-next:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .owl-carousel.owl-slider .owl-prev:after {
    content: "";
    background-image: url(../../assets/img/svg/nav-left-arrow.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    display: inline-block;
    height: 100%;
    width: 100%;
    margin-left: -5%; }
  .owl-carousel.owl-slider .owl-next:after {
    content: "";
    background-image: url(../../assets/img/svg/nav-right-arrow.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    display: inline-block;
    height: 100%;
    width: 100%;
    margin-left: 5%; }
  .owl-carousel.owl-slider .owl-inline-nav .owl-nav {
    position: absolute;
    background-color: transparent;
    background-image: none;
    top: auto;
    bottom: 10px;
    left: auto;
    right: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); }
  .owl-carousel.owl-slider .owl-inline-nav .owl-prev:after {
    content: "";
    font-size: 12px;
    background-image: url(../../assets/img/svg/arrow-prev.svg);
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    height: 20px;
    width: 10px; }
  .owl-carousel.owl-slider .owl-inline-nav .owl-next:after {
    content: "";
    font-size: 12px;
    background-image: url(../../assets/img/svg/arrow-next.svg);
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    height: 20px;
    width: 10px; }
  .owl-carousel.owl-slider .owl-inline-nav .owl-nav [class*=owl-] {
    position: absolute;
    background-color: transparent;
    background-image: none;
    padding: 0px;
    margin-top: -20px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-slider .owl-inline-nav .owl-nav [class*=owl-] {
        margin-top: -20px; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-slider .owl-inline-nav .owl-nav [class*=owl-] {
        margin-top: -20px; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-slider .owl-inline-nav .owl-nav [class*=owl-] {
        margin-top: -20px; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-slider .owl-inline-nav .owl-nav [class*=owl-] {
        margin-top: -20px; } }
  .owl-carousel.owl-slider .owl-inline-nav .owl-nav .owl-prev {
    left: -15px; }
  .owl-carousel.owl-slider .owl-inline-nav .owl-nav .owl-next {
    right: -15px; }
  .owl-carousel.owl-slider .owl-inline-nav:hover .owl-nav .owl-prev {
    left: -15px; }
  .owl-carousel.owl-slider .owl-inline-nav:hover .owl-nav .owl-next {
    right: -15px; }
  .owl-carousel.owl-slider .owl-title,
  .owl-carousel.owl-slider .owl-title-alt {
    font-family: "FrutigerLTStd Cond", "FrutigerLTStd", Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 48px;
    padding-bottom: 0.1em;
    line-height: 1.2em; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-slider .owl-title,
      .owl-carousel.owl-slider .owl-title-alt {
        font-size: 32px;
        padding-bottom: 0.1em; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-slider .owl-title,
      .owl-carousel.owl-slider .owl-title-alt {
        font-size: 40px;
        padding-bottom: 0.1em; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-slider .owl-title,
      .owl-carousel.owl-slider .owl-title-alt {
        font-size: 48px;
        padding-bottom: 0.2em; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-slider .owl-title,
      .owl-carousel.owl-slider .owl-title-alt {
        font-size: 56px;
        padding-bottom: 0.2em; } }
  .owl-carousel.owl-slider .owl-text {
    font-family: "FrutigerLTStd", Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    position: relative;
    display: block;
    z-index: 200;
    padding-bottom: 2em; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-slider .owl-text {
        font-size: 18px;
        padding-bottom: 0.5em; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-slider .owl-text {
        font-size: 20px;
        padding-bottom: 0.9em; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-slider .owl-text {
        font-size: 22px;
        padding-bottom: 1.2em; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-slider .owl-text {
        font-size: 24px;
        padding-bottom: 1.5em; } }
  .owl-carousel.owl-slider .right .owl-text {
    margin-left: auto;
    margin-right: 0px; }

/** fold Slider Slide Styles**/
.owl-carousel.owl-fold {
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /*INLINE NAV*/ }
  @media only screen and (min-width: 0px) {
    .owl-carousel.owl-fold {
      height: 100%;
      max-height: 3000px; } }
  @media only screen and (min-width: 481px) {
    .owl-carousel.owl-fold {
      height: 100%;
      max-height: 3000px; } }
  @media only screen and (min-width: 769px) {
    .owl-carousel.owl-fold {
      height: 100%;
      max-height: 3000px; } }
  @media only screen and (min-width: 992px) {
    .owl-carousel.owl-fold {
      height: 100%;
      max-height: 3000px; } }
  @media only screen and (min-width: 1024px) {
    .owl-carousel.owl-fold {
      height: 100%;
      max-height: 3000px; } }
  @media only screen and (min-width: 1232px) {
    .owl-carousel.owl-fold {
      height: 100%;
      max-height: 3000px; } }
  .owl-carousel.owl-fold .owl-stage-outer {
    display: block;
    height: 100%;
    min-height: 100%; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-fold .owl-stage-outer {
        height: 100%; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-fold .owl-stage-outer {
        height: 100%; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-fold .owl-stage-outer {
        height: 100%; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-fold .owl-stage-outer {
        height: 100%; } }
  .owl-carousel.owl-fold .owl-stage {
    height: 100%; }
  .owl-carousel.owl-fold .owl-item {
    height: 100%; }
  .owl-carousel.owl-fold .owl-item .owl-slide {
    position: relative;
    min-height: 100%;
    height: 100%;
    overflow: hidden; }
  .owl-carousel.owl-fold .owl-item img {
    display: block;
    max-width: 1000%;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: auto;
    top: 0%;
    z-index: 300; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-fold .owl-item img {
        height: 100%;
        width: auto;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0%); } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-fold .owl-item img {
        height: 100%;
        width: auto;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0%); } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-fold .owl-item img {
        height: 100%;
        width: auto;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0%); } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-fold .owl-item img {
        height: auto;
        width: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } }
    @media only screen and (min-width: 1024px) {
      .owl-carousel.owl-fold .owl-item img {
        height: auto;
        width: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } }
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      .owl-carousel.owl-fold .owl-item img {
        height: auto;
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } }
  @media only screen and (min-width: 0px) {
    .owl-carousel.owl-fold .owl-item .owl-slide-video img {
      display: block; } }
  @media only screen and (min-width: 481px) {
    .owl-carousel.owl-fold .owl-item .owl-slide-video img {
      display: block; } }
  @media only screen and (min-width: 769px) {
    .owl-carousel.owl-fold .owl-item .owl-slide-video img {
      display: none; } }
  @media only screen and (min-width: 992px) {
    .owl-carousel.owl-fold .owl-item .owl-slide-video img {
      display: none; } }
  .owl-carousel.owl-fold .owl-item .owl-slide-video .bg-video-wrap {
    display: block;
    position: relative;
    z-index: 350; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-fold .owl-item .owl-slide-video .bg-video-wrap video {
        display: none; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-fold .owl-item .owl-slide-video .bg-video-wrap video {
        display: none; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-fold .owl-item .owl-slide-video .bg-video-wrap video {
        display: block; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-fold .owl-item .owl-slide-video .bg-video-wrap video {
        display: block; } }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-fold .owl-item .owl-slide-video .bg-video-wrap {
        display: none; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-fold .owl-item .owl-slide-video .bg-video-wrap {
        display: none; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-fold .owl-item .owl-slide-video .bg-video-wrap {
        display: block; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-fold .owl-item .owl-slide-video .bg-video-wrap {
        display: block; } }
  @media only screen and (min-width: 769px) {
    .owl-carousel.owl-fold .owl-stage {
      height: 100%; }
    .owl-carousel.owl-fold .owl-item {
      height: 100%; }
    .owl-carousel.owl-fold .seq-slide {
      height: 100%; } }
  @media only screen and (min-width: 1024px) {
    .owl-carousel.owl-fold .owl-stage {
      height: 100%; }
    .owl-carousel.owl-fold .owl-item {
      height: 100%; }
    .owl-carousel.owl-fold .seq-slide {
      height: 100%; } }
  @media only screen and (min-width: 1232px) {
    .owl-carousel.owl-fold .owl-stage {
      height: 100%; }
    .owl-carousel.owl-fold .owl-item {
      height: 100%; }
    .owl-carousel.owl-fold .seq-slide {
      height: 100%; } }
  .owl-carousel.owl-fold .owl-title {
    opacity: 0;
    -webkit-transition: opacity 1000ms ease-in-out 0s;
    -moz-transition: opacity 1000ms ease-in-out 0s;
    -ms-transition: opacity 1000ms ease-in-out 0s;
    -o-transition: opacity 1000ms ease-in-out 0s;
    transition: opacity 1000ms ease-in-out 0s; }
  .owl-carousel.owl-fold .owl-item.active .owl-title {
    opacity: 1; }
  .owl-carousel.owl-fold .owl-text {
    opacity: 0;
    -webkit-transition: opacity 1000ms ease-in-out 0s;
    -moz-transition: opacity 1000ms ease-in-out 0s;
    -ms-transition: opacity 1000ms ease-in-out 0s;
    -o-transition: opacity 1000ms ease-in-out 0s;
    transition: opacity 1000ms ease-in-out 0s; }
  .owl-carousel.owl-fold .owl-item.active .owl-text {
    opacity: 1; }
  .owl-carousel.owl-fold .owl-link {
    opacity: 0;
    -webkit-transition: opacity 1000ms ease-in-out 0s;
    -moz-transition: opacity 1000ms ease-in-out 0s;
    -ms-transition: opacity 1000ms ease-in-out 0s;
    -o-transition: opacity 1000ms ease-in-out 0s;
    transition: opacity 1000ms ease-in-out 0s; }
  .owl-carousel.owl-fold .owl-item.active .owl-link {
    opacity: 1; }
  .owl-carousel.owl-fold .owl-nav {
    position: absolute;
    background-color: transparent;
    background-image: none;
    top: 50%;
    left: 0px;
    right: 0px; }
  .owl-carousel.owl-fold .owl-nav [class*=owl-] {
    position: absolute;
    background-color: transparent;
    background-image: none;
    padding-top: 0px;
    padding-right: 3px;
    padding-bottom: 0px;
    padding-left: 3px; }
  .owl-carousel.owl-fold .owl-dots {
    position: absolute;
    bottom: 0px;
    margin-left: auto;
    left: 0%;
    margin-right: auto;
    right: 0px;
    display: block;
    height: 20px;
    width: auto;
    line-height: normal;
    text-align: center; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-fold .owl-dots {
        position: absolute;
        bottom: 40px; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-fold .owl-dots {
        position: absolute;
        bottom: 40px; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-fold .owl-dots {
        position: absolute;
        bottom: 40px; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-fold .owl-dots {
        position: absolute;
        bottom: 40px; } }
  .owl-carousel.owl-fold .owl-dots button.owl-dot {
    background-color: transparent;
    border: 0px solid transparent;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    height: auto;
    width: auto;
    height: 20px;
    margin-right: 15px; }
  .owl-carousel.owl-fold .owl-dots .owl-dot:last-child {
    margin-right: 0px; }
  .owl-carousel.owl-fold .owl-dots .owl-dot span {
    display: block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #FFFFFF;
    background-color: rgba(255, 255, 255, 0.5);
    height: 10px;
    width: 10px;
    border: 0px solid #7f7f7f; }
  .owl-carousel.owl-fold .owl-dots .owl-dot.active span {
    background-color: #2f94bf; }
  .owl-carousel.owl-fold .owl-nav button.owl-prev,
  .owl-carousel.owl-fold .owl-nav button.owl-next {
    height: 4em;
    width: 4em;
    opacity: 0;
    transition: all 200ms ease-in-out 0s;
    border: 3px solid #FFF;
    border-radius: 50%;
    margin: 0; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-fold .owl-nav button.owl-prev,
      .owl-carousel.owl-fold .owl-nav button.owl-next {
        height: 1.5em;
        width: 1.5em;
        border: 2px solid #FFF; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-fold .owl-nav button.owl-prev,
      .owl-carousel.owl-fold .owl-nav button.owl-next {
        height: 1.5em;
        width: 1.5em;
        border: 2px solid #FFF; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-fold .owl-nav button.owl-prev,
      .owl-carousel.owl-fold .owl-nav button.owl-next {
        height: 2em;
        width: 2em;
        border: 3px solid #FFF; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-fold .owl-nav button.owl-prev,
      .owl-carousel.owl-fold .owl-nav button.owl-next {
        height: 2.5em;
        width: 2.5em; } }
    .owl-carousel.owl-fold .owl-nav button.owl-prev:hover,
    .owl-carousel.owl-fold .owl-nav button.owl-next:hover {
      background-color: rgba(255, 255, 255, 0.4); }
  .owl-carousel.owl-fold .owl-nav button.owl-prev {
    left: 3em; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-fold .owl-nav button.owl-prev {
        left: 1.5em; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-fold .owl-nav button.owl-prev {
        left: 2em; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-fold .owl-nav button.owl-prev {
        left: 2.5em; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-fold .owl-nav button.owl-prev {
        left: 3em; } }
  .owl-carousel.owl-fold .owl-nav button.owl-next {
    right: 3em; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-fold .owl-nav button.owl-next {
        right: 1.5em; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-fold .owl-nav button.owl-next {
        right: 2em; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-fold .owl-nav button.owl-next {
        right: 2.5em; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-fold .owl-nav button.owl-next {
        right: 3em; } }
  .owl-carousel.owl-fold:hover .owl-nav button.owl-prev {
    opacity: 1; }
  .owl-carousel.owl-fold:hover .owl-nav button.owl-next {
    opacity: 1; }
  .owl-carousel.owl-fold button.owl-prev:after,
  .owl-carousel.owl-fold button.owl-next:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .owl-carousel.owl-fold button.owl-prev:after {
    content: "";
    background-image: url(../../assets/img/svg/nav-left-arrow.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    display: inline-block;
    height: 100%;
    width: 100%;
    margin-left: -5%; }
  .owl-carousel.owl-fold button.owl-next:after {
    content: "";
    background-image: url(../../assets/img/svg/nav-right-arrow.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    display: inline-block;
    height: 100%;
    width: 100%;
    margin-left: 5%; }
  .owl-carousel.owl-fold .owl-title {
    font-family: "FrutigerLTStd Cond", "FrutigerLTStd", Arial, sans-serif;
    font-size: 110px;
    font-weight: 600;
    padding-bottom: 10px;
    text-transform: none;
    line-height: 1em;
    text-transform: uppercase;
    color: #0069a6; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-fold .owl-title {
        font-size: 72px; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-fold .owl-title {
        font-size: 72px; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-fold .owl-title {
        font-size: 110px; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-fold .owl-title {
        font-size: 110px; } }
  .owl-carousel.owl-fold .owl-title-alt {
    color: #fff; }
    .owl-carousel.owl-fold .owl-title-alt:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: -10px;
      top: -10px;
      right: -10px;
      bottom: -10px;
      z-index: -1;
      opacity: 0.4;
      background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.01) 70%, transparent 71%);
      background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.01) 70%, transparent 71%);
      background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.01) 70%, transparent 71%); }
  .owl-carousel.owl-fold .owl-subtitle {
    font-family: "FrutigerLTStd", Arial, sans-serif;
    font-size: 26px;
    font-weight: 600;
    padding-bottom: 10px;
    text-transform: none;
    line-height: 1em;
    text-transform: uppercase;
    color: #fff; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-fold .owl-subtitle {
        font-size: 18px; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-fold .owl-subtitle {
        font-size: 18px; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-fold .owl-subtitle {
        font-size: 26px; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-fold .owl-subtitle {
        font-size: 26px; } }
  .owl-carousel.owl-fold .owl-subtitle-alt {
    color: #0069a6;
    text-shadow: 0 0 18px rgba(0, 0, 0, 0.16); }
  .owl-carousel.owl-fold .owl-text {
    font-family: "FrutigerLTStd", Arial, sans-serif;
    position: absolute;
    top: -50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    left: 0px;
    right: 0px;
    display: inline-block;
    z-index: 200;
    transition: all 500ms ease-in-out 0s; }
  .owl-carousel.owl-fold .owl-item.active .owl-text {
    top: 50%; }
  .owl-carousel.owl-fold a.owl-link {
    font-size: 20px;
    padding: 13px 40px 9px 40px; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-fold a.owl-link {
        font-size: 16px; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-fold a.owl-link {
        font-size: 18px; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-fold a.owl-link {
        font-size: 20px; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-fold a.owl-link {
        font-size: 20px; } }
  .owl-carousel.owl-fold .owl-item-video,
  .owl-carousel.owl-fold .owl-slide-video {
    display: block; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-fold .owl-item-video,
      .owl-carousel.owl-fold .owl-slide-video {
        display: block; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-fold .owl-item-video,
      .owl-carousel.owl-fold .owl-slide-video {
        display: block; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-fold .owl-item-video,
      .owl-carousel.owl-fold .owl-slide-video {
        display: block; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-fold .owl-item-video,
      .owl-carousel.owl-fold .owl-slide-video {
        display: block; } }
  .owl-carousel.owl-fold .owl-inline-nav .owl-nav {
    position: absolute;
    background-color: transparent;
    background-image: none;
    top: auto;
    bottom: 10px;
    left: auto;
    right: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); }
  .owl-carousel.owl-fold .owl-inline-nav .owl-prev:after {
    content: "";
    font-size: 12px;
    background-image: url(../../assets/img/svg/arrow-prev.svg);
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    height: 20px;
    width: 10px; }
  .owl-carousel.owl-fold .owl-inline-nav .owl-next:after {
    content: "";
    font-size: 12px;
    background-image: url(../../assets/img/svg/arrow-next.svg);
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    height: 20px;
    width: 10px; }
  .owl-carousel.owl-fold .owl-inline-nav .owl-nav [class*=owl-] {
    position: absolute;
    background-color: transparent;
    background-image: none;
    padding: 0px;
    margin-top: -20px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px; }
    @media only screen and (min-width: 0px) {
      .owl-carousel.owl-fold .owl-inline-nav .owl-nav [class*=owl-] {
        margin-top: -20px; } }
    @media only screen and (min-width: 481px) {
      .owl-carousel.owl-fold .owl-inline-nav .owl-nav [class*=owl-] {
        margin-top: -20px; } }
    @media only screen and (min-width: 769px) {
      .owl-carousel.owl-fold .owl-inline-nav .owl-nav [class*=owl-] {
        margin-top: -20px; } }
    @media only screen and (min-width: 992px) {
      .owl-carousel.owl-fold .owl-inline-nav .owl-nav [class*=owl-] {
        margin-top: -20px; } }
  .owl-carousel.owl-fold .owl-inline-nav .owl-nav .owl-prev {
    left: -15px; }
  .owl-carousel.owl-fold .owl-inline-nav .owl-nav .owl-next {
    right: -15px; }
  .owl-carousel.owl-fold .owl-inline-nav:hover .owl-nav .owl-prev {
    left: -15px; }
  .owl-carousel.owl-fold .owl-inline-nav:hover .owl-nav .owl-next {
    right: -15px; }

/*
gallery-grid Format

<ul class="gallery-grid gallery-grid_2">
    <li><a href="" title="">Title</a></li>
</ul>

*/
.gallery-grid {
  list-style-position: inside;
  list-style-image: none;
  list-style-type: none;
  display: block;
  clear: both;
  height: auto;
  width: 100%;
  margin: 0px;
  padding-top: 15px;
  padding-right: 0px;
  padding-bottom: 15px;
  padding-left: 0px;
  margin-top: 90px;
  margin-bottom: 90px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px; }

.gallery-grid li {
  font-family: 'Oswald', sans-serif;
  text-align: center;
  display: inline-block;
  float: none;
  width: 25%;
  margin-right: 0%;
  margin-bottom: 15px;
  color: #000;
  background-color: #FFFFFF;
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: 400;
  text-transform: uppercase;
  border: 0px solid #FFFFFF;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  min-height: 100px; }
  @media only screen and (min-width: 0px) {
    .gallery-grid li {
      display: block;
      float: none;
      width: 100%;
      margin-right: 0%;
      min-height: 100px; } }
  @media only screen and (min-width: 481px) {
    .gallery-grid li {
      display: inline-block;
      width: auto;
      margin-right: 2%;
      min-height: 150px; } }
  @media only screen and (min-width: 769px) {
    .gallery-grid li {
      display: inline-block;
      width: auto;
      margin-right: 2%;
      min-height: 200px; } }
  @media only screen and (min-width: 992px) {
    .gallery-grid li {
      display: inline-block;
      width: auto;
      margin-right: 2%;
      min-height: 200px; } }

.gallery-grid_2 li {
  width: 48%;
  margin-right: 2%;
  font-size: 1.6em; }
  @media only screen and (min-width: 0px) {
    .gallery-grid_2 li {
      width: 98%;
      margin-right: 2%; } }
  @media only screen and (min-width: 481px) {
    .gallery-grid_2 li {
      width: 45%;
      margin-right: 2%; } }
  @media only screen and (min-width: 769px) {
    .gallery-grid_2 li {
      width: 45%;
      margin-right: 2%; } }
  @media only screen and (min-width: 992px) {
    .gallery-grid_2 li {
      width: 45%;
      margin-right: 2%; } }

.gallery-grid_3 li {
  width: 31%;
  margin-right: 2%;
  font-size: 1.6em; }
  @media only screen and (min-width: 0px) {
    .gallery-grid_3 li {
      width: 98%;
      margin-right: 2%; } }
  @media only screen and (min-width: 481px) {
    .gallery-grid_3 li {
      width: 48%;
      margin-right: 2%; } }
  @media only screen and (min-width: 769px) {
    .gallery-grid_3 li {
      width: 31%;
      margin-right: 2%; } }
  @media only screen and (min-width: 992px) {
    .gallery-grid_3 li {
      width: 31%;
      margin-right: 2%; } }

.gallery-grid_6 li {
  width: 16.66666666%;
  margin-right: 2%; }
  @media only screen and (min-width: 0px) {
    .gallery-grid_6 li {
      width: 98%;
      margin-right: 0%; } }
  @media only screen and (min-width: 481px) {
    .gallery-grid_6 li {
      width: 31%;
      margin-right: 0%; } }
  @media only screen and (min-width: 769px) {
    .gallery-grid_6 li {
      width: 16.666666%;
      margin-right: 0%; } }
  @media only screen and (min-width: 992px) {
    .gallery-grid_6 li {
      width: 16.666666%;
      margin-right: 0%; } }

.gallery-grid li span {
  display: block;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px; }

.gallery-grid li:hover {
  background-color: #ef8522;
  color: #FFF; }

.gallery-grid li:hover a {
  color: #FFF; }

.gallery-grid:after {
  content: '';
  display: block;
  clear: both;
  width: 100%;
  position: relative; }
