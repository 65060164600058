/*********************************************
	CORE STYLES HERE
*********************************************/

html {
    background-color: #FFF;
}

html,
body {
    width: 100%;
    display: block;
    margin: 0px;
    padding: 0px;
}

body {
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px;
    height: 100%;
    width: 100%;
    background-color: #FFF;
    font-size: 14px;
    /*background-color: rgba(48, 74, 95, 0.8);*/
    /*color: #304a5f;*/
    color: #000;
}

//fade in animation

/*body {
    @include animation('-amp-start 8s steps(1, end) 0s 1 normal both');
}

@include keyframes(-amp-start) {
    from {
        visibility: hidden;
    }
    to {
        visibility: visible;
    }
}*/

.bodywrap {
    display: block;
    height: 100%;
    width: 100%;
    background-color: #FFF;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

#app {}

.sectionwrap {
    display: block;
    background-color: #FFF;
}


/* JavaScript Turned Off */

.no-js #loader-wrapper {
    display: none!important;
}