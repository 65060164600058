.section-hero-slider {
    position: relative;
    height: 80vh;
    width: 100%;
    .section-slider-fixed {
        position: fixed;
        display: block;
        height: 80vh;
        width: 100%;
        .h_slide_box {
            position: relative;
            display: block;
            margin: 0px;
            padding: 0px;
            height: 80vh;
            width: 100%;
            min-height: 200px;
            z-index: 450;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            @include bp-xs {
                height: 300px;
                height: 70vh;
            }
            @include bp-sm {
                height: 350px;
                height: 70vh;
            }
            @include bp-md {
                height: 400px;
                height: 75vh;
            }
            @include bp-lg {
                height: 500px;
                height: 80vh;
            }
            @include bp-xl {
                height: 500px;
                height: 85vh;
            }
            @include bp-xxl {
                height: 500px;
                height: 90vh;
            }
        }
        @include bp-xs {
            height: 300px;
            height: 70vh;
        }
        @include bp-sm {
            height: 350px;
            height: 70vh;
        }
        @include bp-md {
            height: 400px;
            height: 75vh;
        }
        @include bp-lg {
            height: 500px;
            height: 80vh;
        }
        @include bp-xl {
            height: 500px;
            height: 85vh;
        }
        @include bp-xxl {
            height: 500px;
            height: 90vh;
        }
    }
    @include bp-xs {
        height: 300px;
        height: 70vh;
    }
    @include bp-sm {
        height: 350px;
        height: 70vh;
    }
    @include bp-md {
        height: 400px;
        height: 75vh;
    }
    @include bp-lg {
        height: 500px;
        height: 80vh;
    }
    @include bp-xl {
        height: 500px;
        height: 85vh;
    }
    @include bp-xxl {
        height: 500px;
        height: 90vh;
    }
}