.container {}

.row {
    @include bp-xs {
        margin-left: 0px;
        margin-right: 0px;
    }
    @include bp-sm {
        margin-left: 0px;
        margin-right: 0px;
    }
    @include bp-md {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.section {
    display: block;
    position: relative;
    z-index: 500;
    overflow: hidden;
    height: auto;
    width: 100%;
    clear: both;
    background-color: #ffffff;
}

.section-nav {
    display: block;
    position: relative;
    z-index: 500;
    overflow: visible;
    height: auto;
    width: 100%;
    clear: both;
    z-index: 501;
}

.section-over {
    overflow: visible;
}

.section-top {
    display: block;
    border-top-width: 0px;
    border-top-style: solid;
    border-top-color: #971b1e;
}

.section-parallax-front {
    z-index: 499;
}

.section-parallax {
    position: relative;
    overflow: hidden;
    min-height: 200px;
    text-align: center;
}

.section-parallax .parallax-img {
    display: block;
    position: absolute;
    height: 200%;
    width: auto;
    min-width: 100%;
    min-height: 50%;
    top: -100%;
    max-width: 800%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    /* IE 9 */
    -webkit-transform: translate(-50%, 0%);
    /* Safari and Chrome */
    -o-transform: translate(-50%, 0%);
    /* Opera */
    -moz-transform: translate(-50%, 0%);
    /* Firefox */
    @include bp-xs {
        height: 150%;
        width: auto;
        left: 50%;
        transform: translate(-50%, 0%);
        -ms-transform: translate(-50%, 0%);
        /* IE 9 */
        -webkit-transform: translate(-50%, 0%);
        /* Safari and Chrome */
        -o-transform: translate(-50%, 0%);
        /* Opera */
        -moz-transform: translate(-50%, 0%);
        /* Firefox */
    }
    @include bp-sm {
        height: 150%;
        width: auto;
    }
    @include bp-md {
        height: 150%;
        width: auto;
    }
    @include bp-lg {
        height: 150%;
        width: auto;
    }
    @include bp-xl {
        height: 150%;
        width: auto;
    }
    @include bp-xxl {
        height: auto;
        width: 100%;
        left: 0%;
        transform: translate(0%, 0%);
        -ms-transform: translate(0%, 0%);
        /* IE 9 */
        -webkit-transform: translate(0%, 0%);
        /* Safari and Chrome */
        -o-transform: translate(0%, 0%);
        /* Opera */
        -moz-transform: translate(0%, 0%);
        /* Firefox */
    }
}

.section-image {
    position: relative;
    overflow: hidden;
    min-height: 200px;
    text-align: center;
    img {
        position: relative;
        display: block;
        width: 100%;
        z-index: 100;
    }
}

.section-image-text {
    position: relative;
    overflow: hidden;
    min-height: 200px;
    text-align: center;
    img {
        position: absolute;
        display: block;
        width: 100%;
        z-index: 100;
    }
    .image-overlay {
        position: relative;
        width: 50%;
        padding: 60px 20px 60px 20px;
        text-align: left;
        display: block;
        z-index: 101;
        .headline {
            display: block;
            font-size: 42px;
            line-height: 52px;
        }
        .text {
            display: block;
            font-size: 18px;
        }
        a {
            display: block;
            font-size: 18px;
        }
    }
}

.section-full {
    display: block;
}

.section-padded-xs {
    padding-top: 15px;
    padding-bottom: 15px;
}

.section-padded-sm {
    padding-top: 25px;
    padding-bottom: 25px;
}

.section-padded {
    padding-top: 35px;
    padding-bottom: 35px;
}

.section-padded-med {
    padding-top: 70px;
    padding-bottom: 70px;
}

.section-padded-big {
    padding-top: 120px;
    padding-bottom: 120px;
}

.section-padded-huge {
    padding-top: 200px;
    padding-bottom: 200px;
}

.section-no-pad-bottom {
    padding-bottom: 0px;
}

.section-no-pad-top-bottom {
    padding-top: 0px;
    padding-bottom: 0px;
}

.section-border {
    border-top-width: 10px;
    border-bottom-width: 10px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: #0086ac;
    border-bottom-color: #0086ac;
}

.section-border-top {
    border-top-width: 10px;
    border-top-style: solid;
    border-top-color: #0086ac;
}

.section-border-bottom {
    border-bottom-width: 10px;
    border-bottom-style: solid;
    border-bottom-color: #0086ac;
}

.section-colored {
    background-color: #ef8522;
}

.section-red {
    background-color: #971b1e;
}

.section-grey {
    background-color: #dbdbdb;
}

.section-blue {
    background-color: #282b4a;
}

.section-texture1 {
    background-image: url(#{$assets_path}img/textures/light_toast.png);
    background-repeat: repeat;
    background-attachment: scroll;
}

.section-texture2 {
    background-image: url(#{$assets_path}img/textures/tiny_grid.png);
    background-repeat: repeat;
    background-attachment: scroll;
}

.section-texture3 {
    background-image: url(#{$assets_path}img/textures/rockywall.png);
    background-repeat: repeat;
    background-attachment: scroll;
}

.section-texture4 {
    background-image: url(#{$assets_path}img/textures/noisy.png);
    background-repeat: repeat;
    background-attachment: scroll;
}

.section-background {
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: center center;
    text-align: center;
    background-size: auto 100%;
}

.section-bg1 {
    background-image: url(#{$assets_path}img/img-600.jpg);
}

.section-background:before,
.section-background:after {
    content: '';
    background-color: #FFF;
    background-color: rgba(255, 255, 255, 0.3);
    display: none;
    height: 20px;
    width: 100%;
    left: 0px;
    right: 0px;
    position: absolute;
    z-index: 505;
}

.section-background:before {
    top: 0px;
}

.section-background:after {
    bottom: 0px;
}

.section-background-open:before,
.section-background-open:after {
    content: '';
    background-color: #FFF;
    background-color: rgba(255, 255, 255, 0.3);
    display: none;
    height: 50%;
    width: 100%;
    left: 0px;
    right: 0px;
    position: absolute;
    z-index: 505;
    -webkit-transition: all 800ms ease-in-out 0s;
    -moz-transition: all 800ms ease-in-out 0s;
    -ms-transition: all 800ms ease-in-out 0s;
    -o-transition: all 800ms ease-in-out 0s;
    transition: all 800ms ease-in-out 0s;
}

.section-background-open:hover:before,
.section-background-open:hover:after {
    height: 20px;
}

.section-background-open:before {
    top: 0px;
}

.section-background-open:after {
    bottom: 0px;
}

.section-background .subtitle,
.section-background h2,
.section-background blockquote,
.section-background .listbox {
    color: #FFF;
}

.section-background .title {
    font-family: 'Hind', sans-serif;
    color: #222428;
    font-size: 2.1em;
    font-weight: bold;
    margin-bottom: 20px;
    @include bp-sm {
        font-size: 1em;
    }
    @include bp-md {
        font-size: 1.5em;
    }
    @include bp-lg {
        font-size: 2.1em;
    }
}

.section-background .title-underline:after {
    content: '';
    display: block;
    height: auto;
    width: 33%;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #222428;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
}

.section-background .subtitle {
    font-family: 'Oswald light', 'Oswald', sans-serif;
    color: #FFF;
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 10px;
    @include bp-sm {
        font-size: 2em;
    }
    @include bp-md {
        font-size: 2.5em;
    }
    @include bp-lg {
        font-size: 3em;
    }
}

.section-action {
    background-color: #FFF;
    padding-top: 0px;
    padding-bottom: 0px;
}

.section-action-bg {
    img.bg {
        display: block;
        position: absolute;
        width: 100%;
        height: auto;
    }
}

.section-footer-nav {
    background-color: #004b8d;
    .fnav-title {
        display: block;
        font-size: 2em;
        color: #FFF;
    }
    ul.fnav {
        display: block;
        margin: 0em 0;
        padding: 0 0 0 0px;
        list-style-position: inside;
        list-style-image: none;
        list-style-type: none;
        li {
            display: block;
            a {
                display: block;
                font-size: 1em;
                color: #FFF;
                padding: 3px 0px 3px 0px;
            }
        }
    }
}

.section-background-video {
    position: relative;
}

.section-background-video .bg-video-wrap {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: -1;
}

.section-background-video .bg-video-wrap .video-js {
    min-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.section-background-video .bg-video-wrap video {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
    background-color: transparent;
}

.section-map {
    display: block;
    position: relative;
    z-index: 500;
    overflow: hidden;
    height: auto;
    width: 100%;
    min-height: 800px;
}

.section-map .gmap {
    min-height: 800px;
    height: 800px;
}

.section-break {
    display: block;
    clear: both;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 185px;
    height: 1px;
    background-color: #72767c;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}