/*********************************************
	browsehappy
*********************************************/

.browsehappy {
    display: block;
    position: relative;
    z-index: 999999;
    width: 100%;
    height: 200px;
    background-color: #f2dede;
    margin: 0px;
    padding: 20px;
    font-size: 22px;
    line-height: 1.4em;
    color: #333;
    vertical-align: middle;
    text-align: center;
}

.browsehappy p {}